import React,{useEffect, useState, Component, useRef} from 'react';
import AdsData from '../../../data/mobile/ads.json'
import '../../BodyStyle/styles.scss'

import Filter from '../Filter'
import SidePanel from '../SidePanel'
import Item from '../Item'
import Footer from '../Footer'
import {useNavigate} from "react-router-dom";

function Ad() {

  const navigate = useNavigate();

useEffect(()=>{
  

},[])



//onClick={window.history.back()}

window.addEventListener('scroll', function(){
 
})

  return (
    <div className="MobileMainBody">
     

    <div className='ComponentsDiv'> 

   <div id="backbtn" onClick={()=>navigate(-1)}>
    <span>Zurück</span>
    </div>
    



    <div className='Audi2-Main-Container'>
    
     
      
    </div>

    

   
 
<div className='Components componentsAudi3'>



<div id="articleBlockAudi3" >
<div className='Audi3MobileAd-container'>
<img className='Audi3MobileAd-Img' src={AdsData.posts[2].mobil}/>
     </div>
    </div>

<div className='Panel-Filter-Item-Audi2'>
<SidePanel/>

   <div className='Items itemsAudi3'>
     <Filter/>
     {/*mobile Ad*/}
     <div className='Audi3MobileAd-container-mobile'>
<img className='Audi3MobileAd-Img-mobile' src={AdsData.posts[2].mobil}/>
     </div>
      <Item/> 

     </div>
     </div>

     </div>

   

    
     </div>
    
     <Footer/>
    </div>

  );
}


  


export default Ad;
