import React,{useEffect, useState, Component} from 'react';
import { Outlet, Link } from 'react-router-dom';
import './styles.scss'
import Logo from '../../assets/advr_tofu.png'


function HeadSmile() {


  useEffect(()=>{


  
},[])




  return (
    <>
  <div className="AdcheckHead-landingSmile">
     
   <div className='HeadLogo-landingSmile'>
    <div>SmileCraft</div>
   </div>
    
    <div className="AdcheckHead-landingSmile-ctabtn">
    <div className='landingSmile-ctabtn'>Jetzt kaufen</div>
    </div>
   
  
</div>
<Outlet/>
</>
  );
}


  




export default HeadSmile;
