import React,{useEffect, useState, Component, useRef, Fragment} from 'react';
import { useParams} from "react-router";
import test from '../../../assets/body/testad/1.png'
import test2 from '../../../assets/body/testad/1.jpg'
import Data from '../../../../data/autoscout/items.json'



function Items() {


    const {ads}= useParams()
 
  
  



  useEffect(()=>{


    
  
},[])


return Data.ads.map((item,index)=>{


  return (
    <Fragment key={index}>
  <div className="subpage-autoscout-item">
    <div>
    <div className='subpage-autoscout-item_name'>{item.name}</div>
    <div className='subpage-autoscout-item_name_info'>{item.descr}</div>
    </div>

    
    <div className='subpage-autoscout-item_img-container'><img src={item.img}/></div>
    <div className='subpage-autoscout-item_descr'>
        <div className='subpage-autoscout-item_descr_price'>{item.price}</div>
        <div className='subpage-autoscout-item_descr_finance'>ab € 25,-mtl <span> Finanzierung berechnen</span></div>
        <div className='subpage-autoscout-item_descr_finance line493'>Kfz-Versicherung<span> ab € 16,21 mtl. vergleichen</span></div>
        <div className='subpage-autoscout-item_descr_detail'>
            <div className='subpage-autoscout-item_descr_detail-container'><div className='subpage-autoscout-item_descr_detail_icon-container'><svg viewBox="0 0 24 24" id="mileage_road"><path fill="currentColor" fillRule="evenodd" d="m19 4.8 3 14c.1.5-.2 1.1-.8 1.2H21c-.5 0-.9-.3-1-.8l-3-14c-.1-.5.2-1.1.8-1.2.5-.1 1.1.2 1.2.8zM6.2 4c.6.1.9.7.8 1.2l-3 14c-.1.5-.5.8-1 .8h-.2c-.5-.1-.9-.6-.8-1.2l3-14c.1-.5.7-.9 1.2-.8zM12 16c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1s-1-.4-1-1v-2c0-.6.4-1 1-1zm0-6c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1s-1-.4-1-1v-2c0-.6.4-1 1-1zm0-6c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1s-1-.4-1-1V5c0-.6.4-1 1-1z"></path></svg></div>{item.road}</div>
            <div className='subpage-autoscout-item_descr_detail-container'><div className='subpage-autoscout-item_descr_detail_icon-container'><svg fill="none" viewBox="0 0 18 18" id="transmission" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10.065 10.253h3.58c.626 0 1.094-.514 1.094-1.095V4.48c0-.576-.453-1.106-1.095-1.106-.626 0-1.095.514-1.095 1.095v3.581h-2.484v-3.57c0-.625-.512-1.095-1.094-1.095-.627 0-1.095.514-1.095 1.095v3.582H5.564V4.48c0-.625-.512-1.095-1.094-1.095-.6 0-1.095.498-1.095 1.095v8.847c0 .625.512 1.095 1.095 1.095.598 0 1.094-.498 1.094-1.095v-3.075h2.312v3.064a1.096 1.096 0 0 0 2.19 0v-3.064Zm-.75-.75h4.33a.346.346 0 0 0 .344-.345V4.47a.348.348 0 0 0-.345-.345.346.346 0 0 0-.345.345v4.331H9.316v-4.32a.346.346 0 0 0-.344-.345.346.346 0 0 0-.345.345v4.332H4.814V4.48a.346.346 0 0 0-.344-.345.353.353 0 0 0-.345.345v8.847c0 .196.16.345.345.345a.353.353 0 0 0 .344-.345V9.503h3.812v3.814c0 .195.16.345.345.345a.346.346 0 0 0 .344-.345V9.503Z" fill="currentColor"></path></svg></div>{item.getr}</div>
            <div className='subpage-autoscout-item_descr_detail-container'><div className='subpage-autoscout-item_descr_detail_icon-container'><svg viewBox="0 0 24 24" id="calendar"><path fill="currentColor" fillRule="evenodd" d="M18 4h-1V3a1 1 0 1 0-2 0v1H9V3a1 1 0 1 0-2 0v1H6C3.794 4 2 5.794 2 8v10c0 2.206 1.794 4 4 4h12c2.206 0 4-1.794 4-4V8c0-2.206-1.794-4-4-4zM6 6h1v1a1 1 0 1 0 2 0V6h6v1a1 1 0 1 0 2 0V6h1c1.103 0 2 .897 2 2v2H4V8c0-1.103.897-2 2-2zm12 14H6c-1.103 0-2-.897-2-2v-6h16v6c0 1.103-.897 2-2 2z"></path></svg></div>{item.ez}</div>
            <div className='subpage-autoscout-item_descr_detail-container'><div className='subpage-autoscout-item_descr_detail_icon-container'><svg viewBox="0 0 24 24" id="gas_pump"><path fill="currentColor" fillRule="evenodd" d="M17 3c1.103 0 2 .897 2 2v9.722c.595-.347 1-.985 1-1.722v-3a1 1 0 1 1 2 0v3c0 1.859-1.28 3.411-3 3.858V20h1a1 1 0 1 1 0 2H4a1 1 0 1 1 0-2h1V5c0-1.103.897-2 2-2zm0 2H7v15h10.001L17 5zm-2 1a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1zm-1 2h-4v3h4V8zm7-4a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0V5a1 1 0 0 1 1-1z"></path></svg></div>{item.gas}</div>
            <div className='subpage-autoscout-item_descr_detail-container'><div className='subpage-autoscout-item_descr_detail_icon-container'><svg viewBox="0 0 24 24" id="speedometer"><path fill="currentColor" fillRule="evenodd" d="M12 4c5.5 0 10 4.5 10 10 0 1.4-.4 3-1.1 4.4-.2.4-.5.6-.9.6-.2 0-.3 0-.5-.1-.4-.2-.6-.8-.4-1.3.6-1.2.9-2.4.9-3.6 0-4-3.1-7.4-7-7.9V8c0 .6-.4 1-1 1s-1-.4-1-1V6.1c-1.5.2-2.8.7-3.9 1.6l1.6 1.6c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.2L5.7 9.2c-.8 1.1-1.4 2.4-1.6 3.9H6c.6 0 1 .4 1 1s-.4 1-1 1H4c.1.8.4 1.7.8 2.5.3.4.1 1.1-.4 1.3-.5.3-1.1.1-1.3-.4C2.4 17 2 15.5 2 14 2 8.5 6.5 4 12 4zm2.3 6.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-3 3c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4z"></path></svg></div>{item.speed}</div>
            <div className='subpage-autoscout-item_descr_detail-container'><div className='subpage-autoscout-item_descr_detail_icon-container'><svg viewBox="0 0 18 18" id="water_drop" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M14.92 9.613a6.607 6.607 0 0 0-.2-.835c-1.09-3.517-5.063-6.953-5.232-7.097a.748.748 0 0 0-.976 0c-.195.167-4.784 4.138-5.433 7.931-.053.302-.079.6-.079.888a6.007 6.007 0 0 0 7.76 5.737 5.969 5.969 0 0 0 4.238-5.737 5.27 5.27 0 0 0-.078-.887Zm-4.6 5.19A4.505 4.505 0 0 1 4.5 10.5c0-.202.019-.416.056-.634.446-2.6 3.287-5.508 4.444-6.6 1.053.993 3.503 3.494 4.273 5.913.004.015.007.029.013.044.066.214.119.429.155.644.038.218.057.431.057.634 0 1.99-1.277 3.72-3.179 4.302ZM6 9.75a.75.75 0 1 1 1.5 0v1.5a.75.75 0 1 1-1.5 0v-1.5Z"></path></svg></div>{item.verbr}</div>
            <div className='subpage-autoscout-item_descr_detail-container'><div className='subpage-autoscout-item_descr_detail_icon-container'><svg viewBox="0 0 24 24" id="leaf"><path fill="currentColor" fillRule="evenodd" d="M18 3h-.2C12.7 3 9.2 4.5 7.2 7.4c-1.6 2.4-2.2 6-.5 8.5l-3.4 3.4c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3l3.4-3.4c1 .7 2.3 1 3.5 1 1.8 0 3.6-.6 5-1.5C19.5 14.9 21 11 21 6c0-1.7-1.3-3-3-3zm-7.1 3.6c1.6-1 3.9-1.6 6.7-1.6l-5.3 5.3-1.3 1.3V7c0-.1 0-.3-.1-.4zm8.1-.2c0 1-.1 2.3-.4 3.6h-3.2L19 6.4zM8.8 8.6c.1-.1.1-.2.2-.3v5.2l-.9.9c-.9-1.7-.4-4.2.7-5.8zm6.6 6.6c-1.7 1.1-4.2 1.6-5.9.7l1.2-1.2 2.7-2.7H18c-.6 1.2-1.4 2.4-2.6 3.2z"></path></svg></div>{item.emm}</div>
         
        </div>

        

    </div>
    <div className='subpage-autoscout-item_foot'>
        <div className='subpage-autoscout-item_foot_container'>
        <div className='subpage-autoscout-item_foot_img-container'><img src={test2}/></div>
        <div className='subpage-autoscout-item_foot_text-container'>aaf.de GmbH <span>Torben Hoyer DE-22848 Norderstedt</span></div>
        </div>
    </div>

    <div className='subpage-autoscout-item_foot_2'>
        <div className='subpage-autoscout-item_foot_2-text'>+ Weitere Fahrzeuge anzeigen</div>
    </div>
  
</div>

</Fragment>
  );

})
 
}


  




export default Items;
