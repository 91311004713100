import React,{useEffect, useState, Component} from 'react';

import './BodyStyle/styles.scss'


function Category() {

  return (

<div id="categories">


<div className="Categories">
       <div className="Category">
           <div id="kategorie">Kategorien</div>
           <ul>
               <li>Drogerie & Kosmetik</li>
               <li>Papier- & Hygieneartikel</li>
               <li>Fitnessnahrung</li>
               <li>Gesundheit</li>
               <li>Haarpflege & Beauty</li>
               <li>Körperpflege & -reinigung</li>
               <li>Make-Up</li>
               <li>Parfüm</li>
               <li>Rasur & Haarentfernung</li>
               <li>Zahn- & Mundpflege</li>
           </ul>
           <div className="CategoryBtn"> Zurück zur Übersicht</div>
       </div>

       <div className="Category">
       <div id="kategorie">Eigenschaften</div>
       <ul id="checkboxlist">
               <li><input className='Checkbox' type="checkbox"/><span>Angebote</span></li>
               <li><input className='Checkbox' type="checkbox"/><span>Bio</span></li>
               <li><input className='Checkbox' type="checkbox"/><span>Glutenfrei</span></li>
               <li><input className='Checkbox' type="checkbox"/><span>Laktosefrei</span></li>
               <li><input className='Checkbox' type="checkbox"/><span>Neu</span></li>
               <li><input className='Checkbox' type="checkbox"/><span>Vegan</span></li>
               <li><input className='Checkbox' type="checkbox"/><span>Vegetarisch</span></li>
               
           </ul>
       </div>

       <div className="Category">
       <div id="kategorie">Marken</div>
       <ul id="checkboxlist">
               <li><input className='Checkbox' type="checkbox"/><span>8x4</span></li>
               <li><input className='Checkbox' type="checkbox"/><span>Adidas</span></li>
               <li><input className='Checkbox' type="checkbox"/><span>Rexona</span></li>
               <li><input className='Checkbox' type="checkbox"/><span>Axe</span></li>
               <li><input className='Checkbox' type="checkbox"/><span>Dove</span></li>
               <li><input className='Checkbox' type="checkbox"/><span>Fa</span></li>
               <li><input className='Checkbox' type="checkbox"/><span>Nivea</span></li>
               
               
           </ul>

       </div>
    </div>
    

</div>


  );
}

export default Category;
