import React,{useEffect, useState, Component, useRef} from 'react';
import '../styles.scss'
import Text from '../../../../data/reweka/text.json'
import Textshort from '../../../../data/reweka/text_short.json'
import TextChapter from '../../../../data/reweka/text_chapter.json'
import BannerLongDetails from './BannerLongDetails';



//Banner Details

import TextDetails from '../../../../data/reweka/text_details.json'
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';


function BannerShort({closeBanner}) {

const [details, setDetails]=useState(true)
let detailsHeight=false



const BannerDetails=useRef(null)
let openDetails=false

useEffect(()=>{

  
},[])

const handleCloseOpenDetails=()=>{
  openDetails=!openDetails
  if(openDetails){
    BannerDetails.current.style.display="block"
  }
  if(!openDetails){
    BannerDetails.current.style.display="none"
  }
}

const handleCloseOpenDetailtext=(e)=>{
  detailsHeight=!detailsHeight

  if(detailsHeight &&  e.target.className=="reweka-banner-short-head"){
    e.target.style.height="auto"
  }
  else if(!detailsHeight &&  e.target.className=="reweka-banner-short-head" && window.innerWidth>1600){
    e.target.style.height="20px"
  }

  else if(!detailsHeight &&  e.target.className=="reweka-banner-short-head" && window.innerWidth<1600){
    e.target.style.height="20px"
    
  }
}

/*
const handleCloseOpenDetailtext=(e)=>{
  let detailsHeight=false

  const test=()=>{
    
    detailsHeight=!detailsHeight
   console.log(detailsHeight)
    if(detailsHeight){
      e.target.style.height="5vw"
    }
    else{
      e.target.style.height="1vw"
    }
    
  }

  
  return test
 
}


const clickHandler=handleCloseOpenDetailtext
*/

  return (
    <>
 
    <div className='reweka-Banner-short'>  
      <div className='reweka-Banner-long-headText'>Optionale Cookies und Technologien erlauben?</div>
      <div className='reweka-Banner-short-text_btns'>
        <div className='reweka-Banner-long-text'>
          <TextShort/>

         <Textchapter/><br/>

         <div className='reweka-banner-short-head-text'>
         Weitere Erklärungen sowie einen Link zu den Datenschutz-Einstellungen finden Sie in unserer Datenschutzerklärung. Hier erfahren Sie auch mehr über unsere Cookie-Policy. 
         </div>
        </div>
        <div className='reweka-Banner-short-btns'>
          <div className='ad_klicks reweka-btn-options' data-click-tracking="reweka_short_ohneBtn_optionen" onClick={handleCloseOpenDetails}>Mehr Optionen</div>
          <div className='ad_klicks reweka-btn' data-click-tracking="reweka_short_ohneBtn_akzeptieren" onClick={closeBanner}>Alle erlauben</div>
        </div>
      </div>
    </div>
   


 {/*Banner Details */}
  

 <div className='reweka-Banner-long BannerDetails' ref={BannerDetails}>  
    <div className='reweka-details-close' onClick={handleCloseOpenDetails}>X</div>
      <div className='reweka-Banner-long-headText'>Optionale Cookies und Technologien erlauben?</div>
      
      <div className='reweka-Banner-long-text_btns'>
        <div className='reweka-Banner-long-text'>
          {TextDetails.content[0].text}
        </div>

      <div className='reweka-details-switchers'>
     <div className='ad_klicks reweka-details-switcher'> <FormControlLabel  control={<Switch defaultChecked disabled={true} />} label={<div className='reweka-switch-label'>Zwingend notwendig</div>}/></div>    
     <div className='ad_klicks reweka-details-switcher' data-click-tracking="reweka_short_ohneBtn_optionen_komfort_cookie"> <FormControlLabel  control={<Switch />} label={<div className='reweka-switch-label'>Komfort Cookies</div>}/></div>
     <div className='ad_klicks reweka-details-switcher' data-click-tracking="reweka_short_ohneBtn_optionen_marketing"> <FormControlLabel  control={<Switch  />} label={<div className='reweka-switch-label'>Marketing Cookies </div>}/></div>
      </div>

        <div className='reweka-Banner-long-details-btns'>
          <div className='ad_klicks reweka-btn' data-click-tracking="reweka_short_ohneBtn_optionen_auswahl_akzeptieren" onClick={closeBanner}>Auswahl erlauben</div>
          <div className='ad_klicks reweka-btn' data-click-tracking="reweka_short_ohneBtn_optionen_ablehnen" onClick={closeBanner}>Alle ablehnen</div>
          <div className='ad_klicks reweka-btn reweka-btnout' data-click-tracking="reweka_short_ohneBtn_optionen_alle_akzeptieren" onClick={closeBanner}>Alle erlauben</div>
        </div>
      </div>
    </div>


    </>
  );


  function TextShort(){
    return Textshort.content.map((item, index)=>{
    
      return(
       <div key={index}>
      <br/><div>{item.text}</div>
      </div>
      )
    })
  }

  function Textchapter(){
    return TextChapter.content.map((item, index)=>{
    
      return(
        
     <div key={index} className='reweka-banner-short-head' onClick={handleCloseOpenDetailtext}>
        {item.heading}
        <div className='reweka-banner-short-head-text'>
          {item.text.split('\n').map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
      </div>
      
      )
    })
  }

}






export default BannerShort;
