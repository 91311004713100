import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams} from "react-router";
import bgImg from '../../../../assets/ads/hpto_audi_etron/2210_Audi_Q6_E-tron_3067501_1000x250/img.jpg'
import img1text1 from '../../../../assets/ads/hpto_audi_etron/2210_Audi_Q6_E-tron_3067501_1000x250/c1.png'
import img1text2 from '../../../../assets/ads/hpto_audi_etron/2210_Audi_Q6_E-tron_3067501_1000x250/c2.png'
import img1text3 from '../../../../assets/ads/hpto_audi_etron/2210_Audi_Q6_E-tron_3067501_1000x250/c3.png'
import Logo from '../../../../assets/ads/top/c1.png'
import img1texthead from '../../../../assets/ads/top/c2.png'


import cta from '../../../../assets/ads/top/cta_text.png'
import { Opacity } from '@mui/icons-material';




function AdTopAd3() {


    const {ads}= useParams()

    const  adTopad_img1= useState()

    const adTopad_cta= useState()

    const adTopad_textt1= useState()
    const adTopad_textt2= useState()
    const adTopad_textt3= useState()
 
  
  const AnimStart=()=>{
    setTimeout(() => {
      adTopad_textt1.current.style.opacity=1
      adTopad_textt2.current.style.opacity=1
      adTopad_cta.current.style.opacity=1
      adTopad_cta.current.style.transform="scale(1)"
    
    }, 1000);


  }



  useEffect(()=>{


    AnimStart()
  
},[])




  return (
    <>
  <div className="hpto_ad-top-autoscout">
    <div className='hpto_ad-top-autoscout_img-container_adTopad'>
     <div className='hpto_ad-top-autoscout_logo-container_adTopad'><img src={Logo}/></div> 
      <div className='hpto_ad-top-autoscout_img-adTopad' ref={adTopad_img1}><img src={bgImg}/>
      <div className='hpto_ad-top-autoscout_img-adTopad_img1_text'style={{opacity:0}} ref={adTopad_textt1}><img src={img1text1}/></div>
      <div className='hpto_ad-top-autoscout_img-adTopad_img1_text'style={{opacity:0}} ref={adTopad_textt2}><img src={img1text2}/></div>
      <div className='hpto_ad-top-autoscout_img-adTopad_img1_text'style={{opacity:0}} ref={adTopad_textt3}><img src={img1text3}/></div>
      </div>

      <div className='hpto_ad2-top-autoscout-adTopad-cta' ref={adTopad_cta}>Mehr erfahren</div>
      {/* 
      <div className='hpto_ad-top-autoscout_img-adTopad' ref={adTopad_img2}><img src={bgImg2}/></div>
      <div className='hpto_ad-top-autoscout_img-adTopad' ref={adTopad_img3}><img src={bgImg3}/></div>*/}
      
    </div>
    
    
   
  
</div>

</>
  );


 
}


  




export default AdTopAd3;
