import React,{useEffect, useState, Component, useRef} from 'react';
import '../../styles/style.scss'
import { register } from 'swiper/element/bundle';
import swipeImg1 from '../../assets/body/miniimg/1.webp'
import swipeImg2 from '../../assets/body/miniimg/2.webp'
import swipeImg3 from '../../assets/body/miniimg/3.webp'
import swipeImg4 from '../../assets/body/miniimg/4.webp'
import swipeImg5 from '../../assets/body/miniimg/5.webp'
import swipeImg6 from '../../assets/body/miniimg/6.webp'
import swipeImg7 from '../../assets/body/miniimg/7.webp'


register();
function Cards() {



useEffect(()=>{

  
},[])




  return (

<>
<div className='cards-autoscout-container'>

<div className='cards-autoscout-element'>
    <div className='cards-autoscout-element-card'>
        <div className='cards-autoscout-card-icon_container'><div className='cards-autoscout-card-icon'><svg xmlns="http://www.w3.org/2000/svg" width="39" height="39"><path d="M23 0a16 16 0 1 1-10.57 28l-9.75 9.72a1 1 0 1 1-1.41-1.41L11 26.57A16 16 0 0 1 23 0zm0 2C15.268 2 9 8.268 9 16s6.268 14 14 14a14 14 0 0 0 14-14c0-7.732-6.268-14-14-14zm1.44 6a2 2 0 0 1 1.71 1l2.42 4H30a4 4 0 0 1 4 4v3a2 2 0 0 1-2 2h-1.19a3 3 0 0 1-5.63 0h-4.37a3 3 0 0 1-5.63 0H14a2 2 0 0 1-2-2v-5.73a2 2 0 0 1 .29-1L14.86 9a2 2 0 0 1 1.71-1zM18 20a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-3.56-10h-7.87l-1.8 3H17a1 1 0 0 1 0 2h-3v5h1.19a3 3 0 0 1 5.63 0h4.37a3 3 0 0 1 5.63 0H32v-3a2 2 0 0 0-2-2h-2a1 1 0 0 1-.85-.49L24.44 10zM23 12a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 1-1z" fill="#333"></path></svg></div><span>Deine letzte Suche</span></div>
       
    </div>
    <div className='cards-autoscout-element-card-btn_conainer'>
        <div className='cards-autoscout-element-card-cta_btn'><div className='card-cta_btn-icon'><svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M11 0c5 0 9 4 9 9s-4 9-9 9c-2.1 0-4.1-.8-5.6-2l-3.7 3.7c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4L4 14.6C2.7 13.1 2 11.1 2 9c0-5 4-9 9-9Zm0 2C7.1 2 4 5.1 4 9s3.1 7 7 7 7-3.1 7-7-3.1-7-7-7Zm-.2 3.2c0-.2.3-.2.4 0L12 8h2.8c.2 0 .3.3.1.4L13 10l.9 2.6c.1.2-.1.4-.3.3L11 11l-2.6 2c-.2.1-.5-.1-.4-.3L9 10 7 8.4c-.1-.1 0-.4.2-.4H10Z" fill="#333" fillRule="nonzero"></path></svg></div><span>Suche speichern</span></div>
    </div>
</div>
<div className='cards-autoscout-element' style={{overflow:'hidden', justifyContent:'center'}}>
    
<div style={{marginLeft:'15px', fontSize:'14px', fontWeight:600}}>Aktuell gefragt</div>

<div style={{marginTop:'10px'}}>
<swiper-container direction="horizontal" className="autoscout-swiper" slides-per-view="5" navigation="true">
 
 <swiper-slide>
    <div className='autoscout-swiper-item'><div className='autoscout-swiper-item_img'><img src={swipeImg1}/></div><span>Elektroautos</span></div>
 </swiper-slide>
 <swiper-slide>
    <div className='autoscout-swiper-item'><div className='autoscout-swiper-item_img'><img src={swipeImg2}/></div><span>Leasing</span></div>
 </swiper-slide>
 <swiper-slide>
    <div className='autoscout-swiper-item'><div className='autoscout-swiper-item_img'><img src={swipeImg3}/></div><span>smyle</span></div>
 </swiper-slide>
 <swiper-slide>
    <div className='autoscout-swiper-item'><div className='autoscout-swiper-item_img'><img src={swipeImg4}/></div><span>Neuwagen</span></div>
 </swiper-slide>
 <swiper-slide>
    <div className='autoscout-swiper-item'><div className='autoscout-swiper-item_img'><img src={swipeImg5}/></div><span>Stadtflitzer</span></div>
 </swiper-slide>
 <swiper-slide>
    <div className='autoscout-swiper-item'><div className='autoscout-swiper-item_img'><img src={swipeImg6}/></div><span>Fanilienauto</span></div>
 </swiper-slide>
 <swiper-slide>
    <div className='autoscout-swiper-item'><div className='autoscout-swiper-item_img'><img src={swipeImg7}/></div><span>Erstes Auto</span></div>
 </swiper-slide>

    
    </swiper-container>

</div>

</div>



</div>

<div className='cards-autoscout_ol453'>Verkaufe dein Auto schnell, einfach und kostenfrei <span>Jetzt Auto verkaufen </span><div id="autoscout_arrow">→</div></div>
</>

  );


}



export default Cards;
