import React,{useEffect, useState, Component, useRef} from 'react';
import '../../styles/style.scss'
import { register } from 'swiper/element/bundle';
import swipeImg1 from '../../assets/body/miniimg/1.webp'
import swipeImg2 from '../../assets/body/miniimg/2.webp'
import swipeImg3 from '../../assets/body/miniimg/3.webp'
import swipeImg4 from '../../assets/body/miniimg/4.webp'
import swipeImg5 from '../../assets/body/miniimg/5.webp'
import swipeImg6 from '../../assets/body/miniimg/6.webp'
import swipeImg7 from '../../assets/body/miniimg/7.webp'


register();


function CardsVehicle({data, text1, text2}) {



useEffect(()=>{

  
},[])




  return (

<>
<div className='autoscout-cards_Vehicle-HeadText'>{text1}</div>
<div className='autoscout-cards_Vehicle-container'>

<div className='autoscout-cards_Vehicle-item_container'>
   <div className='autoscout-cards_Vehicle-item_imgContainer'><img src={data.data[0].img}/></div>
   <div className='autoscout-cards_Vehicle-item_text-container'>
   <div className='autoscout-cards_Vehicle-item_marke'>{data.data[0].marke}</div>
   <div className='autoscout-cards_Vehicle-item_price'>{data.data[0].preis}</div>
   <div className='autoscout-cards_Vehicle-item_descr'>{data.data[0].descr}</div>
   <div className='autoscout-cards_Vehicle-item_location'>{data.data[0].location}</div>
   </div>
</div>
<div className='autoscout-cards_Vehicle-item_container'>
   <div className='autoscout-cards_Vehicle-item_imgContainer'><img src={data.data[1].img}/></div>
   <div className='autoscout-cards_Vehicle-item_text-container'>
   <div className='autoscout-cards_Vehicle-item_marke'>{data.data[1].marke}</div>
   <div className='autoscout-cards_Vehicle-item_price'>{data.data[1].preis}</div>
   <div className='autoscout-cards_Vehicle-item_descr'>{data.data[1].descr}</div>
   <div className='autoscout-cards_Vehicle-item_location'>{data.data[1].location}</div>
   </div>
</div>
<div className='autoscout-cards_Vehicle-item_container'>
   <div className='autoscout-cards_Vehicle-item_imgContainer'><img src={data.data[2].img}/></div>
   <div className='autoscout-cards_Vehicle-item_text-container'>
   <div className='autoscout-cards_Vehicle-item_marke'>{data.data[2].marke}</div>
   <div className='autoscout-cards_Vehicle-item_price'>{data.data[2].preis}</div>
   <div className='autoscout-cards_Vehicle-item_descr'>{data.data[2].descr}</div>
   <div className='autoscout-cards_Vehicle-item_location'>{data.data[2].location}</div>
   </div>
</div>
<div className='autoscout-cards_Vehicle-item_container'>
   <div className='autoscout-cards_Vehicle-item_imgContainer'><img src={data.data[3].img}/></div>
   <div className='autoscout-cards_Vehicle-item_text-container'>
   <div className='autoscout-cards_Vehicle-item_marke'>{data.data[3].marke}</div>
   <div className='autoscout-cards_Vehicle-item_price'>{data.data[3].preis}</div>
   <div className='autoscout-cards_Vehicle-item_descr'>{data.data[3].descr}</div>
   <div className='autoscout-cards_Vehicle-item_location'>{data.data[3].location}</div>
   </div>
</div>




</div>

<div className='cards-autoscout_ol453 ol454'><span>{text2}</span><div id="autoscout_arrow">→</div></div>
</>

  );


}



export default CardsVehicle;
