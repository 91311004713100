import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams} from "react-router-dom";
import bgImg from '../../../assets/ads/fireplace/right/bg.jpg'
import text from '../../../assets/ads/fireplace/right/badge.png'


function AdSide_R() {


    const {ads}= useParams()

  const texxt=useRef(null)
  const imgg=useRef(null)
  const imgContainer=useRef(null)


  const checkScroll=()=>{
    const scrollPosition=window.scrollY

    if(scrollPosition>0){
      imgContainer.current.style.position="sticky"
      imgContainer.current.style.top=0
      imgContainer.current.style.height="100vh"
    }
    if(scrollPosition==0){
      imgContainer.current.style.position="relative"
      imgContainer.current.style.top=0
      imgContainer.current.style.height="93vh"
    }

  }

  window.addEventListener("scroll",checkScroll)

  useEffect(()=>{
    
    setTimeout(() => {
      imgg.current.style.transform="scale(1.1)"
    }, 100);

   
   setTimeout(() => {
    texxt.current.style.opacity=1
   }, 1000);
    
  
},[])




  return (
    <>
  <div className="hpto_ad-side-autoscout" ref={imgContainer}>
  <div className='hpto_ad-side-autoscout-background'></div>
  <div className='hpto_ad-side-autoscout_img-container' ><img ref={imgg} id="hpto_ad-side-autoscout_fireplace-right-img" src={bgImg}/></div>
   <div className='hpto_ad-side-autoscout_ad-text' ref={texxt}><img src={text}/></div>
  
</div>

</>
  );


 
}


  




export default AdSide_R;
