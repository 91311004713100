import React,{useEffect, useState, Component} from 'react';
import { Outlet, Link } from 'react-router-dom';
import './styles.scss'
import Logo from '../../assets/advr_tofu.png'


function HeadGrill() {


  useEffect(()=>{


  
},[])




  return (
    <>
  <div className="AdcheckHead-landingGrill">
     
   <div className='HeadLogo-landingGrill'>
    <img src={Logo}/>
   </div>
    
    <div className="AdcheckHead-landingGrill-ctabtn">
    <div className='landingGrill-ctabtn'>Jetzt kaufen</div>
    </div>
   
  
</div>
<Outlet/>
</>
  );
}


  




export default HeadGrill;
