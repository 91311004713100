import {React, useEffect, useState, Component, useRef} from "react";
import './headstyle.scss'
import Logo from './assets/logo.svg'
import Search from './assets/lupe.svg'
import Message from './assets/message.svg'
import Add from './assets/add.svg'
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import { flexbox } from "@mui/system";
import img1 from '../Components/assets/1.jpg'
import img2 from '../Components/assets/2.jpg'
import img3 from '../Components/assets/3.jpg'
import img4 from '../Components/assets/4.jpg'

function HeadFaceB(){

   



    return(
        <div className="facebook_head"> 
        <div className="facebook_head_container">
        
        <div className="facebook-head-logo_container">
        <div className="facebook-head-img"><img src={Logo}/></div>
        
        <div className="facebook-head-btns">
            <div className="facebook-head-btn-item_container"><img src={Add}/></div>
            <div className="facebook-head-btn-item_container"><img id="facebook-head-btn-search" src={Search}/></div>
            <div className="facebook-head-btn-item_container"><img src={Message}/></div>
        </div>
        </div>

        <div className="facebook-head-menu_container">
        <div className="facebook-head-menu-item" id="facebook-head-menu-item_home"><svg viewBox="0 0 24 24" width="24" height="24" fill="#0866ff"><path d="M9.464 1.286C10.294.803 11.092.5 12 .5c.908 0 1.707.303 2.537.786.795.462 1.7 1.142 2.815 1.977l2.232 1.675c1.391 1.042 2.359 1.766 2.888 2.826.53 1.059.53 2.268.528 4.006v4.3c0 1.355 0 2.471-.119 3.355-.124.928-.396 1.747-1.052 2.403-.657.657-1.476.928-2.404 1.053-.884.119-2 .119-3.354.119H7.93c-1.354 0-2.471 0-3.355-.119-.928-.125-1.747-.396-2.403-1.053-.656-.656-.928-1.475-1.053-2.403C1 18.541 1 17.425 1 16.07v-4.3c0-1.738-.002-2.947.528-4.006.53-1.06 1.497-1.784 2.888-2.826L6.65 3.263c1.114-.835 2.02-1.515 2.815-1.977zM10.5 13A1.5 1.5 0 0 0 9 14.5V21h6v-6.5a1.5 1.5 0 0 0-1.5-1.5h-3z"></path></svg></div>
        <div className="facebook-head-menu-item"><svg viewBox="0 0 24 24" width="24" height="24"  fill="currentColor"><path d="M10.996 8.132A1 1 0 0 0 9.5 9v4a1 1 0 0 0 1.496.868l3.5-2a1 1 0 0 0 0-1.736l-3.5-2z"></path><path d="M14.573 2H9.427c-1.824 0-3.293 0-4.45.155-1.2.162-2.21.507-3.013 1.31C1.162 4.266.817 5.277.655 6.477.5 7.634.5 9.103.5 10.927v.146c0 1.824 0 3.293.155 4.45.162 1.2.507 2.21 1.31 3.012.802.803 1.813 1.148 3.013 1.31C6.134 20 7.603 20 9.427 20h5.146c1.824 0 3.293 0 4.45-.155 1.2-.162 2.21-.507 3.012-1.31.803-.802 1.148-1.813 1.31-3.013.155-1.156.155-2.625.155-4.449v-.146c0-1.824 0-3.293-.155-4.45-.162-1.2-.507-2.21-1.31-3.013-.802-.802-1.813-1.147-3.013-1.309C17.866 2 16.397 2 14.573 2zM3.38 4.879c.369-.37.887-.61 1.865-.741C6.251 4.002 7.586 4 9.5 4h5c1.914 0 3.249.002 4.256.138.978.131 1.496.372 1.865.74.37.37.61.888.742 1.866.135 1.007.137 2.342.137 4.256 0 1.914-.002 3.249-.137 4.256-.132.978-.373 1.496-.742 1.865-.369.37-.887.61-1.865.742-1.007.135-2.342.137-4.256.137h-5c-1.914 0-3.249-.002-4.256-.137-.978-.132-1.496-.373-1.865-.742-.37-.369-.61-.887-.741-1.865C2.502 14.249 2.5 12.914 2.5 11c0-1.914.002-3.249.138-4.256.131-.978.372-1.496.74-1.865zM8 21.5a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H8z"></path></svg></div>
        <div className="facebook-head-menu-item"><svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor"><path d="M12.496 5a4 4 0 1 1 8 0 4 4 0 0 1-8 0zm4-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-9 2.5a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm-2 4a2 2 0 1 1 4 0 2 2 0 0 1-4 0zM5.5 15a5 5 0 0 0-5 5 3 3 0 0 0 3 3h8.006a3 3 0 0 0 3-3 5 5 0 0 0-5-5H5.5zm-3 5a3 3 0 0 1 3-3h4.006a3 3 0 0 1 3 3 1 1 0 0 1-1 1H3.5a1 1 0 0 1-1-1zm12-9.5a5.04 5.04 0 0 0-.37.014 1 1 0 0 0 .146 1.994c.074-.005.149-.008.224-.008h4.006a3 3 0 0 1 3 3 1 1 0 0 1-1 1h-3.398a1 1 0 1 0 0 2h3.398a3 3 0 0 0 3-3 5 5 0 0 0-5-5H14.5z"></path></svg></div>
        <div className="facebook-head-menu-item"><svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor"><path d="M1.588 3.227A3.125 3.125 0 0 1 4.58 1h14.84c1.38 0 2.597.905 2.993 2.227l.816 2.719a6.47 6.47 0 0 1 .272 1.854A5.183 5.183 0 0 1 22 11.455v4.615c0 1.355 0 2.471-.119 3.355-.125.928-.396 1.747-1.053 2.403-.656.657-1.475.928-2.403 1.053-.884.12-2 .119-3.354.119H8.929c-1.354 0-2.47 0-3.354-.119-.928-.125-1.747-.396-2.403-1.053-.657-.656-.929-1.475-1.053-2.403-.12-.884-.119-2-.119-3.354V11.5l.001-.045A5.184 5.184 0 0 1 .5 7.8c0-.628.092-1.252.272-1.854l.816-2.719zM10 21h4v-3.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5V21zm6-.002c.918-.005 1.608-.025 2.159-.099.706-.095 1.033-.262 1.255-.485.223-.222.39-.55.485-1.255.099-.735.101-1.716.101-3.159v-3.284a5.195 5.195 0 0 1-1.7.284 5.18 5.18 0 0 1-3.15-1.062A5.18 5.18 0 0 1 12 13a5.18 5.18 0 0 1-3.15-1.062A5.18 5.18 0 0 1 5.7 13a5.2 5.2 0 0 1-1.7-.284V16c0 1.442.002 2.424.1 3.159.096.706.263 1.033.486 1.255.222.223.55.39 1.255.485.551.074 1.24.094 2.159.1V17.5a2.5 2.5 0 0 1 2.5-2.5h3a2.5 2.5 0 0 1 2.5 2.5v3.498zM4.581 3c-.497 0-.935.326-1.078.802l-.815 2.72A4.45 4.45 0 0 0 2.5 7.8a3.2 3.2 0 0 0 5.6 2.117 1 1 0 0 1 1.5 0A3.19 3.19 0 0 0 12 11a3.19 3.19 0 0 0 2.4-1.083 1 1 0 0 1 1.5 0A3.2 3.2 0 0 0 21.5 7.8c0-.434-.063-.865-.188-1.28l-.816-2.72A1.125 1.125 0 0 0 19.42 3H4.58z"></path></svg></div>
        <div className="facebook-head-menu-item"><svg viewBox="0 0 24 24" width="20" height="20" stroke="black" strokeWidth="2" fill="white" ><path d="M3 9.5a9 9 0 1 1 18 0v2.927c0 1.69.475 3.345 1.37 4.778a1.5 1.5 0 0 1-1.272 2.295h-4.625a4.5 4.5 0 0 1-8.946 0H2.902a1.5 1.5 0 0 1-1.272-2.295A9.01 9.01 0 0 0 3 12.43V9.5zm6.55 10a2.5 2.5 0 0 0 4.9 0h-4.9z"></path></svg></div>
        <div className="facebook-head-menu-item"><MenuIcon/></div>
        </div>

        <div className="facebook-head-add-post_container">
        <div id="facebook-head-add-post_item-acc"><PersonIcon sx={{color:'#6F7376', fontSize:'36px'}}/></div>
        <div id="facebook-head-add-post_item-textfield"><div>Was machst du gerade?</div></div>
        <div id="facebook-head-add-post_item-add_img"><PhotoAlbumIcon sx={{color:'#40BB46'}}/></div>
        </div>


        <div className="facebook-head-app-carousel_container" >
            <div className="facebook-head-app-carousel-items">
        <swiper-container free-mode="true" slides-per-view="3" space-between="5" style={{height:'180px'}}>
            
        <swiper-slide>
            <div className="facebook-head-app-carousel-item">
                <img src={img1}/>
            </div>
        </swiper-slide>

        <swiper-slide>
            <div className="facebook-head-app-carousel-item">
            <img src={img2}/>
            </div>
        </swiper-slide>

        <swiper-slide>
            <div className="facebook-head-app-carousel-item">
            <img src={img3}/>
            </div>
        </swiper-slide>

        <swiper-slide>
            <div className="facebook-head-app-carousel-item">
            <img src={img4}/>
            </div>
        </swiper-slide>

       
        
    </swiper-container>
    </div>
        </div>

        </div>
     
       
        </div>
    )
}

export default HeadFaceB;