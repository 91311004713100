import React,{useEffect, useState, Component} from 'react';
import Head from '../Head/Head'
import Close from '../../Images/img/close.svg'
import Audi from '../../Images/img/audiad.jpg'
import Dealer from '../../Images/img/audi.jpg'
import '../BodyStyle/styles.scss'
import Items from '../../data/mobile/items.json'

function Item() {


  useEffect(()=>{

  
  
},[])



return Items.ads.map((item, index)=>{

  return(
    <div className="MobileCarItem">
      <div className='ImgDivItem'>
      <div className='ImgAd'>
        <img src={item.img}/>
        </div>
      </div>
      
      <div className='ItemInfo'>

      <div className='Info'>
      <div className='ItemHeadDiv'>
      <div className='ItemHead'>{item.descr}</div>
      <div className='ItemText'>{item.details}</div>

      
      </div>
      
      <div className='Price'>
        <div>{item.price}</div>
      </div>
        
      </div>

      <div className={item.other.length>0 ? 'Details' : 'DispayNoneClass' }>
      <div className='KR24'><svg className='Check' width="16" height="16" viewBox="0 0 24 24" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M9 15.879l-5-5L1.879 13 9 20.121 23.121 6 21 3.879l-12 12z" fill="currentColor"></path></svg>{item.other[0]}</div>
      <div className={item.other.length===1 ? 'DispayNoneClass' : 'XK6GY' }><svg className='Check' width="16" height="16" viewBox="0 0 24 24" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M9 15.879l-5-5L1.879 13 9 20.121 23.121 6 21 3.879l-12 12z" fill="currentColor"></path></svg>{item.other[1]}</div>
   
      
      </div>

        <div className='Info Second'>

        <div className='DealerDiv'>
          
        <div className='DealerImg'> <img src={item.dealerimg}/></div>
        <div className='DealerTexts'>
        <div className='DealerTextsHead'>{item.dealerinfo}</div>
        <div className='DealerTextsXW'>{item.dealeradr}</div>
        </div>
        </div>

        <div className='InfoBtns'>
        <div className='InfoBtnKontakt InfoBtn'><svg style={{marginRight:"10px"}}  width="16" height="16" viewBox="0 0 24 24" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M2 8l10 6 10-6v10a1 1 0 01-1 1H3a1 1 0 01-1-1V8zm0-3a1 1 0 011-1h18a1 1 0 011 1v1l-10 6L2 6V5z" fill="currentColor"></path></svg>Kontakt</div>
        <div className='InfoBtnPark InfoBtn'><svg style={{marginRight:"10px"}}  width="16" height="16" viewBox="0 0 24 24" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5 1h14a1 1 0 011 1v20.191a.5.5 0 01-.724.447L12 19l-7.276 3.638A.5.5 0 014 22.191V2a1 1 0 011-1zm4 4v10h2v-3h2c3 0 3-2 3-3.5S16 5 13 5H9zm2 2h2c1 0 1 1 1 1.5s0 1.5-1 1.5h-2V7z" fill="#094559"></path></svg>Parken</div>
        </div>
        </div>
      </div>


      </div>
  )
})

 
}


  


export default Item;
