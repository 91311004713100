import React,{useEffect, useState, Component} from 'react';
import Head from '../Head/Head'
import Filter from './Filter'
import Kauf from '../../Images/img/ObsBanner.png'
import Leas from '../../Images/img/LeasingBanner.png'
import '../BodyStyle/styles.scss'

function Options() {


  useEffect(()=>{

  
  
},[])




  return (
    <div className="MobileOptions">
     
    <div className='Option'>
        <div className='OptionBlock'>
            <img className='OptionImg' src={Kauf}/>
        </div>
        <div className='OptionText'><span>Geklickt. Gekauft. Geliefert.</span><div className='OptionText2'>Autos online kaufen und liefern lassen - mit Online-Kauf von mobile.de</div><div className='OptionBtn'>7658 Online-Kauf-Angebote</div></div>
    </div>
    <div className='Option'>

    <div className='OptionBlock'>
            <img className='OptionImg' src={Leas}/>
        </div>
        <div className='OptionText'><span>Leasing-Schnäppchen</span><div className='OptionText2'>Exklusive Angebote für privat und gewerblich.</div><div className='OptionBtn'>Leasing-deals zeigen</div></div>

    </div>

     </div>

  );
}


  


export default Options;
