import React,{useEffect, useState, Component, useRef} from 'react';
import './styles.scss'
import { useAsyncError, useParams } from "react-router";
import Pb from './Images/pb.png'
import Container2 from './Container2';
import Container3 from './Container3';
import Container4 from './Container4';
import Footer from './Footer';
import BannerLong from './Banner/BannerLong';
import BannerLong2 from './Banner/BannerLong2';
import BannerShort from './Banner/BannerShort';
import BannerShort2 from './Banner/BannerShort2';




function BodyStart() {

  let urlParam=document.location.search;
  let searchParams=new URLSearchParams(urlParam)
  let banner=searchParams.get("b")

 

 const rewekaMain=useRef(null)
 //const [showBanner, setShowBanner]=useState(сесиионсторадж.getItem('bannerState')=='true')
 const [showBanner, setShowBanner]=useState(true)
 //const [count, setCount]=useState(0)
 const [interval, setIntervalforTimer]=useState(null)

 const count=useRef(0)
 

useEffect(()=>{
 
    
  setTimeout(() => {

    if(!showBanner){
    document.body.style.overflowY="hidden"
    document.getElementById("reweka-head").style.opacity=0.4
    rewekaMain.current.style.opacity=0.4
    
      setShowBanner(true)
      //сесиионсторадж.setItem('bannerState', true)
    }

    if(showBanner==true){
    document.body.style.overflowY="hidden"
    document.getElementById("reweka-head").style.opacity=0.4
    rewekaMain.current.style.opacity=0.4
    
      setShowBanner(true)
      //сесиионсторадж.setItem('bannerState', true)
    }
 
    BannerTimer(true)
   
  }, 0);
 
 
},[])



function BannerTimer(timer) {


    if (timer==true) {
  
      const interval = setInterval(() => {
      
        count.current+=1
       
   
       }, 1000);
       setIntervalforTimer(interval)
     }
   else{
   
     
     clearInterval(interval);
     //setIntervalforTimer(null)
    //eslint-disable-next-line
      sendResultFieldData({1:String(count.current)})
     
     
   }
  
  
 
 
}




const handleCloseBanner=(evt)=>{
  
  document.body.style.overflowY="auto"
  document.getElementById("reweka-head").style.opacity=1
  rewekaMain.current.style.opacity=1
  BannerTimer(false)
  setShowBanner(false)
  //сесиионсторадж.setItem('bannerState', false)
  
}

  return (
    <>
     {showBanner && <SelectBanner type={banner}/>} 
    <div className="Reweka-Main" id="reweka-main" ref={rewekaMain}>
      
     <div className="reweka-firstContainer">
      <div className='reweka-greeting-container'>
      <div className='reweka-greeting'>Willkommen bei REWEKA</div>
      <div className='reweka-icons'>
        <div className='RewekaIcon'><img className='reweka-icons-item' src="https://eu-images.contentstack.com/v3/assets/blt76860e5619b27a3a/blt3117fc1fbffe39d0/601160032206ca0c0330f952/colored-icons-icon-person-48.svg"/><span>Mein Konto</span></div>
        <div className='RewekaIcon'><img className='reweka-icons-item' src="https://eu-images.contentstack.com/v3/assets/blt76860e5619b27a3a/bltda6264063de5b2c5/64f6e19c42c20677dff8ea78/icon-smartphone-48_(1).svg"/><span>Zur REWEKA App</span></div>
        <div className='RewekaIcon'><img className='reweka-icons-item' src="https://eu-images.contentstack.com/v3/assets/blt76860e5619b27a3a/bltc388d3a4b2f91205/60115ff6a194410c684304cf/colored-icons-icon-bag-48.svg"/><span>Meine Einkäufe</span></div>
        <div className='RewekaIcon'><img className='reweka-icons-item' src="https://eu-images.contentstack.com/v3/assets/blt76860e5619b27a3a/bltc1d2f9e56d8425cf/64357a2ad3445310e121575a/PAYBACK_Logo_Domino_RGB_2022_(3).svg"/><span>PAYBACK</span></div>
      
      </div>

      <div className='reweka-dropdown'><img src='https://eu-images.contentstack.com/v3/assets/blt76860e5619b27a3a/bltc0398e4cdb4674d9/60115fd52118d00c7fcc7f74/outlined-icons-notification-validation-indication-icon-warning-circle-24.svg'/> <span id="reweka-dropdown-text">Produktrückrufe</span><span id="v">∨</span></div>
      </div>
     </div>

    
     <Container2/>
     <Container3/>
     <Container4/>
     <Footer/>
   
</div>
</>
  );



  function SelectBanner(props){
  const type=props.type
    switch(type){
      case "1":
      return<BannerLong closeBanner={handleCloseBanner}/>
     
      case "2":
        return<BannerLong2 closeBanner={handleCloseBanner}/>

      case "3":
        return<BannerShort closeBanner={handleCloseBanner}/>

      case "4":
        return<BannerShort2 closeBanner={handleCloseBanner}/>
     
    }
  }


}

  




export default BodyStart;
