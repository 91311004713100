import React,{useEffect, useState, Component, useRef} from 'react';
import Category from './Category';
import './BodyStyle/styles.scss'
import data from '../../../../data/reweka/produkte.json'
import Herz from '../../../../Images/img/herz.png'
import Wagen from '../../../../Images/img/einkaufswagen.png'




function Products() {

  let prods=data.products;

  let urlParam=document.location.search;
  let searchParams=new URLSearchParams(urlParam)
  let show=searchParams.get("show")

 const addBtn=useRef(null)


useEffect(()=>{



},[])

const addProduct=(e)=>{
  e.currentTarget.style.background="blue"
  e.currentTarget.firstChild.style.display="none"
  e.currentTarget.style.width="40px"
 
  

}
  
  
  return prods.map((item,index)=>{
    return (

      
    
      <div className="reweka-Product" key={index} id={"prod"+index}>
    <div className="reweka-Sponsor" id={index}>Gesponsert</div> 
        <div className="reweka-ProductImg"><img src={item.url}/></div>
        <div className="reweka-ProductName">{item.text}</div>
       
        <div className="reweka-ProductFoot">
        <div className='reweka-product-price'>{item.price}</div>
        <div className="reweka-Favorite">
            <img src={Herz}/>
        </div>
        <div className="reweka-Buy" onClick={addProduct}>
        <img src={Wagen}/>
        <span style={{fontSize:'30px'}}>+</span>
        </div>
    
         </div>


    </div>

      

    
   
   );
  })

   


 
 
}

export default Products;
