import React,{useEffect, useState, Component} from 'react';
import Head from '../Head/Head'
import Filter from './Filter'
import Options from './Options'
import Magazin from './Magazin'
import OptionsSecond from './OptionsSecond'
import Dealer from './Dealer'
import Block from './Block'
import Marken from './Marken'
import Footer from './Footer'
import '../BodyStyle/styles.scss'
import Ad from '../../ads/bg.jpg'


function Body() {


  useEffect(()=>{

  
  
},[])




  return (
    <div className="MobileMainBody">

     <div id="articleBody">
    <div id="articleImgBlock">
    <img className='Audi3MobileAd-Img' src={Ad}/>
    </div>
  

     </div>
      <Filter/>
      <Options/>
      <Magazin/>
      <OptionsSecond/>
      <Dealer/>
      <Block/>
      <Marken/>
      <Footer/>
     </div>

  );
}


  


export default Body;
