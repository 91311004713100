import React,{useEffect, useState, Component,useRef} from 'react';
import '../styles.scss'
import Text from '../../../../data/reweka/text.json'
import BannerLongDetails from './BannerLongDetails';


//Banner Details

import TextDetails from '../../../../data/reweka/text_details.json'
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';


function BannerLong({closeBanner}) {

const [details, setDetails]=useState(true)


const BannerDetails=useRef(null)
let openDetails=false

useEffect(()=>{
    
  
},[])

const handleCloseOpenDetails=()=>{
  openDetails=!openDetails
  if(openDetails){
    BannerDetails.current.style.display="block"
  }
  if(!openDetails){
    BannerDetails.current.style.display="none"
  }
}




  return (
    <>
    
    <div className='reweka-Banner-long'>  
      <div className='reweka-Banner-long-headText'>Optionale Cookies und Technologien erlauben?</div>
      <div className='reweka-Banner-long-text_btns'>
        <div className='reweka-Banner-long-text'>
          <TextLong/>
        </div>
        <div className='reweka-Banner-long-btns'>
          <div className='ad_klicks reweka-btn-options' data-click-tracking="reweka_long_mitBtn_optionen" onClick={handleCloseOpenDetails}>Mehr Optionen</div>
          <div className='ad_klicks reweka-btn' data-click-tracking="reweka_long_mitBtn_ablehnen" onClick={closeBanner}>Alle ablehnen</div>
          <div className='ad_klicks reweka-btn' data-click-tracking="reweka_long_mitBtn_akzeptieren" onClick={closeBanner}>Alle erlauben</div>
        </div>
      </div>
    </div>
   

{/*Banner Details */}
  

<div className='reweka-Banner-long BannerDetails' ref={BannerDetails}>  
    <div className='reweka-details-close' onClick={handleCloseOpenDetails}>X</div>
      <div className='reweka-Banner-long-headText'>Optionale Cookies und Technologien erlauben?</div>
      
      <div className='reweka-Banner-long-text_btns'>
        <div className='reweka-Banner-long-text'>
          {TextDetails.content[0].text}
        </div>

      <div className='reweka-details-switchers'>
     <div className='ad_klicks reweka-details-switcher'> <FormControlLabel  control={<Switch defaultChecked disabled={true} />} label={<div className='reweka-switch-label'>Zwingend notwendig</div>}/></div>    
     <div className='ad_klicks reweka-details-switcher' data-click-tracking="reweka_long_mitBtn_optionen_komfort_cookie"> <FormControlLabel  control={<Switch />} label={<div className='reweka-switch-label'>Komfort Cookies</div>}/></div>
     <div className='ad_klicks reweka-details-switcher' data-click-tracking="reweka_long_mitBtn_optionen_marketing"> <FormControlLabel  control={<Switch  />} label={<div className='reweka-switch-label'>Marketing Cookies </div>}/></div>
      </div>

        <div className='reweka-Banner-long-details-btns'>
          <div className='ad_klicks reweka-btn' data-click-tracking="reweka_long_mitBtn_optionen_auswahl_akzeptieren" onClick={closeBanner}>Auswahl erlauben</div>
          <div className='ad_klicks reweka-btn' data-click-tracking="reweka_long_mitBtn_optionen_ablehnen" onClick={closeBanner}>Alle ablehnen</div>
          <div className='ad_klicks reweka-btn reweka-btnout' data-click-tracking="reweka_long_mitBtn_optionen_alle_akzeptieren" onClick={closeBanner}>Alle erlauben</div>
        </div>
      </div>
    </div>

    </>
  );


  function TextLong(){
  
    return Text.content.map((item,index)=>{


      return <div key={index}>
      {item.text.split('\n').map((line, index) => {
     
        if(line=="Datenschutz-Einstellungen" || line=="Notwendig" || line=="Komfort" || line=="Marketing"){
      
         return <p style={{fontWeight:'bold'}} key={index}>{line}</p>
        }
        else{
          return <p style={{fontWeight:'normal'}} key={index}>{line}</p>
        }
            
          })}
      </div>
      
    })
  }

}






export default BannerLong;
