import React,{useEffect, useState, Component} from 'react';
import '../BodyStyle/style.scss';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

//drop down menu
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

//Btn

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



const objective = [
  'Suitability',
  'Involvement',
  'Brand Safety',
];

const objectiveEmotion = [
    'Аufdringlich',
    'Anspruchsvoll',
    'Aufgeschlossen',
    'Begeisternd',
    'Ehrlich',
    'Emotional',
    'Etwas, das ich vermissen würde',
    'Fantasievoll',
    'Glaubwürdig',
    'Humorvoll',
    'Intellektuell',
    'Kultiviert',
    'Seriös',
    'Spannend',
    'Unterhaltsam',
    'Unverzichtbar',
    'Vielseitig',
    'Vorhersehbar',
    'Weltoffen'
];

const brandSafety = [
    
    'Keine Hassbotschaften',
    'Nicht vulgär',
    'Kein Sex',
    'Kein Crime',
    'Nicht politisch',
    'Nicht sexistisch',
    'Gleichberechtigung',
    
];
const medien = [
    'Lineares TV',
    'Streaming (Mediatheken, VoD, Netflix, Amazon Prime)',
    'Social Media',
    "Display",
    'OOH',
    'Print',
    "Podcast",
    "Funk",
    
];
const targetaudience = [
  'F18-29',
  'F30-49',
  'F50-65',
  'M18-29',
  'M30-49',
  'M50-65',
    
];
const names = [
  'F18-29',
  'F30-49',
  'F50-65',
  'M18-29',
  'M30-49',
  'M50-65',
    
];



function Index(){


    const [objectv, setObjectv] = React.useState([]);
    const [subobjectv, setSubobjectv] = React.useState([]);
    const [brandsafety, setBrandsafety] = React.useState([]);
    const [media, setMedia] = React.useState([]);
    const [target, setTarget] = React.useState([]);



    const [client, setClient]=React.useState("")
    const [targetaud, setTargetaud]=React.useState([])

    const [selectAll, setSelectAll] = useState(false);


    const CalculateBtn = styled(Button)({
      fontWeight:'bold',
      position:'absolute',

    })

  

    const handleChangeObjective = (e) => {
      const {
        target: { value },
      } = e;
      setObjectv(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };
    const handleChangeSubObjective = (e) => {
      const {
        target: { value },
      } = e;
      setSubobjectv(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };
    const handleChangeBrandSafety = (e) => {
      const {
        target: { value },
      } = e;
      setBrandsafety(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const handleSelectAll = () => {
      if (selectAll) {
        setBrandsafety([]);
      } else {
        setBrandsafety(brandSafety);
        handleChangeBrandSafety()
      }
      setSelectAll(!selectAll);
    };

    const handleChangeMedia = (e) => {
      const {
        target: { value },
      } = e;
      setMedia(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };
    const handleChangeTarget = (e) => {
      const {
        target: { value },
      } = e;
      setTarget(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
      setTargetaud(value)
    };

useEffect(()=>{

})

const handleSetClient=(e)=>{
setClient(e.target.value)

}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

}

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'contextscore') {
    return b.contextscore - a.contextscore;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

    return(
       <div className='ai-body-index'>
        <div className='testbg'>
        
        <div className='ai-body-container'>
        <div className='ai-body-ToolName'><div>Context Quality Optimizer</div></div>
        <div className='ai-body-text'><div>definiere die Anforderungen an deine Umfelder</div></div>
        <div className='ai-body-client'>
        <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '300px' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField onChange={handleSetClient} sx={{background:'white', borderRadius:'5px'}} className='ai-formControl' id="outlined-basic" label="Kunde" variant="outlined" />
      
    </Box>
    </div>
        <div className='ai-body-dropdown'>
       
        <FormControl className='ai-formControl' sx={{ m: 1, width: 300 }}>
        <InputLabel  sx={{color:'black'}} id="demo-multiple-checkbox-label">Zielsetzung</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={objectv}
          onChange={handleChangeObjective}
          input={<OutlinedInput label="Zielsetzung" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          sx={{background:'white'}}
        >
          {objective.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={objectv.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </div>

        <div className='ai-body-dropdown'>
       
        <FormControl className='ai-formControl' sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Sub-Ziel-Suitability</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={subobjectv}
          onChange={handleChangeSubObjective}
          input={<OutlinedInput label="Sub-Ziel-Emotion" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          sx={{background:'white'}}
        >
          {objectiveEmotion.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={subobjectv.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </div>
        <div className='ai-body-dropdown'>
       
        <FormControl className='ai-formControl' sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Sub-Ziel-Brand-Safety</InputLabel>
        <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={brandsafety}
        onChange={handleChangeBrandSafety}
        input={<OutlinedInput label="Sub-Ziel-Brand-Safety" />}
        renderValue={(selected) => selected.join(', ')}
        sx={{ background: 'white' }}
      >
        <MenuItem key="select-all" onClick={handleSelectAll}>
          <Checkbox checked={selectAll} />
          <ListItemText primary="alle auswählen" />
        </MenuItem>
          {brandSafety.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={brandsafety.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </div>
        <div className='ai-body-dropdown'>
       
        <FormControl className='ai-formControl' sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Mediengattung</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={media}
          onChange={handleChangeMedia}
          input={<OutlinedInput label="Mediengattung" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          sx={{background:'white'}}
        >
          {medien.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={media.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </div>
        <div className='ai-body-dropdown'>
       
        <FormControl className='ai-formControl' sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Zielgruppe</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={target}
          onChange={handleChangeTarget}
          input={<OutlinedInput label="Zielgruppe" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          sx={{background:'white'}}
        >
          {targetaudience.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={target.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </div>
    {/*<div className='ai-calculate-btn'>Calculate</div>*/}
    

     
      <Link to="subpage" state={{client:client, targetaud:targetaud}}><CalculateBtn className='ai-calculate-btn' variant="contained">Calculate</CalculateBtn></Link>
     


        </div>
        </div>
       </div>
    )
}

export default Index