import React,{useEffect, useState, Component} from 'react';
import './styles.scss'
import { useParams } from "react-router";
import Pic from './Images/3.png'



function Container4() {




useEffect(()=>{

  
},[])




  return (
    <div className='reweka-fourthContainer'>

    <div className='reweka-fourthContainer-img'><img src={Pic}/></div>
      

    </div>
  );
}

  




export default Container4;
