import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useEffect } from 'react';
import TextField from '@mui/material/TextField';

import Logo from '../pict/logo.svg'

//apexchart
import Chart from "react-apexcharts";

import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom'

//Data

import Datas from '../data.json'

//Graph

import { useDrawingArea } from '@mui/x-charts/hooks';

import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

import { GaugeContainer, GaugeValueArc, GaugeReferenceArc, useGaugeState } from '@mui/x-charts/Gauge';

//loading screen

import Loading from './Loading';


function GaugePointer() {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();


  if (valueAngle === null) {

    return null;
  }

  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  };
  return (
    <g>
      <circle cx={cx} cy={cy} r={5} fill="red" />
      <path
        d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
        stroke="red"
        strokeWidth={3}
      />
    </g>
  );
}


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  if (orderBy === 'contextscore' || orderBy === 'qualityXattention') {
    return (a, b) => {
      const contextScoreA = orderBy === 'contextscore' ? (a.humorous + a.inspiring) / 2 : ((a.humorous + a.inspiring) / 2 + a.attention) / 2;
      const contextScoreB = orderBy === 'contextscore' ? (b.humorous + b.inspiring) / 2 : ((b.humorous + b.inspiring) / 2 + b.attention) / 2;
      return order === 'desc' ? contextScoreB - contextScoreA : contextScoreA - contextScoreB;
    };
  } else {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  }
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'context',
    numeric: false,
    disablePadding: true,
    label: 'Context',
  },
  {
    id: 'sender',
    numeric: true,
    disablePadding: false,
    label: 'Sender/VoD Plattform',
  },
  {
    id: 'begeisternd',
    numeric: true,
    disablePadding: false,
    label: 'Begeisternd',
  },
  
  {
    id: 'humorvoll',
    numeric: true,
    disablePadding: false,
    label: 'Humorvoll',
  },


  {
    id: 'contextscore',
    numeric: true,
    disablePadding: false,
    label: 'Context Quality Score',
  },
  {
    id: 'safety',
    numeric: true,
    disablePadding: false,
    label: 'Brand Safety',
  },

  {
    id: 'involvement',
    numeric: true,
    disablePadding: false,
    label: 'Involvement',
  },
  {
    id: 'qualityXattention',
    numeric: true,
    disablePadding: false,
    label: 'Context Quality x Involvement',
  },
 
 
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
         
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
           sx={{width:'230px'}}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};





export default function Subpage() {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('contextscore');
  const [selected, setSelected] = React.useState([]);
  

  const [loadingscreen, setLoadingscreen]=React.useState(true)

 


  useEffect(()=>{
   setTimeout(() => {
   setLoadingscreen(false)
   }, 3000);
  },[])

  const location = useLocation()
  const client= location.state
  const targetaud= location.state

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = Datas.dataset.map((n) => n.id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };


  const isSelected = (id) => selected.indexOf(id) !== -1;

  const options = {
    labels: ["Progress"], //label of this diagram
  };
  const series = [70];

  



  return (

  <div className='ai-subpage-container'>

{loadingscreen && <Loading/>}
<div className='context-subpage-logo'><img src={Logo}/></div>
      <div className='context-subpage-head'>Empfohlene Umfelder</div>

    

      <div className='ai-subpage-subcontainer'>

        <div className='ai-subpage-container-info'>

          <div className='ai-subpage-marke'>
            <div className='ai-subpage-head'>Marke</div>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { width: '220px' },
              }}
              noValidate
              autoComplete="off">

              <TextField inputProps={{ style: { fontWeight: '600', height:'5px' } }} variant="outlined" value={client.client} />
            </Box>
          </div>
          <div className='ai-subpage-targetaudience'>
            <div className='ai-subpage-head'>Zielgruppe</div>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { width: 'fit-content' },
              }}
              noValidate
              autoComplete="off">

              <TextField inputProps={{ style: { fontWeight: '600',height:'5px' } }} variant="outlined" value={targetaud.targetaud.join('; ')} />
            </Box>

          </div>

          <div className='ai-subpage-exportBtns'>

                <div className='ai-subpage-exportBtn'>Export ASAP</div>
                <div className='ai-subpage-exportBtn'>Export Excel</div>
              
              </div>

        </div>

        <div className='ai-subpage-container-ranking'>
       
        <TableContainer sx={{ minWidth: 650, maxHeight: '100%' }} component={Paper}>
          <Table stickyHeader>
            
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={Datas.dataset.length}
              
            />
            <TableBody>
             
             <GenerateData/>
              
            </TableBody>
          </Table>
        </TableContainer>
        </div>
        <TablePagination
        className='ai-subpage-pages'
          rowsPerPageOptions={[30,60]}
          component="div"
          count={60}
          rowsPerPage={30}
          page={0}
          labelRowsPerPage="Anzahl"
        />
</div>

</div>

     
  );

  
  function GenerateData() {
  

   
  

    const sortedData = stableSort(
      Datas.dataset,
      getComparator(order, orderBy)
    );

    return sortedData.map((item, index) => {

   
      const optiIns = {
          
        series: [item.inspiring],
        options: {
          
          chart: {
            type: 'radialBar',
            offsetY: -20,
           
            
            sparkline: {
              enabled: true,
              
            },

            animations: {
              enabled: true,
              easing: 'easeinout',
              speed: 2000,
              animateGradually: {
                  enabled: true,
                  delay: 150
              },
              dynamicAnimation: {
                  enabled: true,
                  speed: 350
              }
          }

          },
          plotOptions: {
            radialBar: {
              hollow:{
                size:'60%'
              },
             
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: '100%',
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: false,
                  top: 2,
                  left: 0,
                  color: '#999',
                  opacity: 1,
                  blur: 2
                }
              },
              
              dataLabels: {
               
                name: {
                  show: false
                },
                value: {
                  offsetY: -2,
                  fontSize: '20px',
                  formatter:function(val){return val.toFixed(0)},
                  fontWeight:'bold',
                  
                }
              }
            }
          },
          grid: {
            padding: {
              top: -10,
            },
           
          },
          fill: {
            
            colors:['#1976D2'],
           
           
          },
          labels: ['Average Results'],
          
        },
      
      
      };

      const optiHum = {
            
        series: [item.humorous],
        
        options: {
          
          chart: {
            type: 'radialBar',
            offsetY: -20,
           
            
            sparkline: {
              enabled: true,
              
            },

            animations: {
              enabled: true,
              easing: 'easeinout',
              speed: 2000,
              animateGradually: {
                  enabled: true,
                  delay: 150
              },
              dynamicAnimation: {
                  enabled: true,
                  speed: 350
              }
          }

          },
          plotOptions: {
            radialBar: {
              hollow:{
                size:'60%'
              },
             
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: '100%',
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: false,
                  top: 2,
                  left: 0,
                  color: '#999',
                  opacity: 1,
                  blur: 2
                }
              },
              
              dataLabels: {
               
                name: {
                  show: false
                },
                value: {
                  offsetY: -2,
                  fontSize: '20px',
                  formatter:function(val){return val.toFixed(0)},
                  fontWeight:'bold',
                }
              }
            }
          },
          grid: {
            padding: {
              top: -10,
            },
           
          },
          fill: {
            
            colors:['#1976D2'],
           
           
          },
          labels: ['Average Results'],
          
        },
      
      
      };


     const averageOpt = {
          
        series: [Math.round((Number(item.humorous) + Number(item.inspiring)) / 2)],
        options: {
          chart: {
           
            type: 'radialBar',


            animations: {
              enabled: true,
              easing: 'easeinout',
              speed: 2000,
              animateGradually: {
                  enabled: true,
                  delay: 150
              },
              dynamicAnimation: {
                  enabled: true,
                  speed: 350
              }
          }
          },
          plotOptions: {
            
            radialBar: {
              
              hollow: {
                size: '40%',
               
              },
           
              track: {
                show: true,
                startAngle: undefined,
                endAngle: undefined,
                background: '#f2f2f2',
                strokeWidth: '100%',
                opacity: 1,
                margin: 5, 
               
            },

            dataLabels: {
              show: true,
              
              name: {
                offsetY: -10,
                show: false,
                color: '#888',
                fontSize: '14px',
               
              },
              value: {
                formatter: function(val) {
                  return parseInt(val);
                },
                offsetY: 10,
                color: '#111',
                fontSize: '21px',
                fontWeight:'bold',
                show: true,
              }
            }
          },
          
           
          },
          fill: {
            
            colors:['#15C300'],
            
           
          },

          stroke: {
            show: true,
            
            
            width: 0,
            dashArray: 0, 
        },
        
          labels: ['Score'],
          
        },
      
      
      };
     const averageOpt2 = {
          
        series: [Math.round((((Number(item.humorous) + Number(item.inspiring)) / 2 ) + Number(item.attention)) / 2)],
        options: {
          chart: {
            height:100,
            type: 'radialBar',


            animations: {
              enabled: true,
              easing: 'easeinout',
              speed: 2000,
              animateGradually: {
                  enabled: true,
                  delay: 150
              },
              dynamicAnimation: {
                  enabled: true,
                  speed: 350
              }
          }
          },
          plotOptions: {
            
            radialBar: {
              
              hollow: {
                size: '40%',
               
              },
           
              track: {
                show: true,
                startAngle: undefined,
                endAngle: undefined,
                background: '#f2f2f2',
                strokeWidth: '100%',
                opacity: 1,
                margin: 5, 
               
            },

            dataLabels: {
              show: true,
              
              name: {
                offsetY: -10,
                show: false,
                color: '#888',
                fontSize: '14px',
               
              },
              value: {
                formatter: function(val) {
                  return parseInt(val);
                },
                offsetY: 10,
                color: '#111',
                fontSize: '21px',
                fontWeight:'bold',
                show: true,
              }
            }
          },
          
           
          },
          fill: {
            
            colors:['#15C300'],
            
           
          },

          stroke: {
            show: true,
            
            
            width: 0,
            dashArray: 0, 
        },
        
          labels: ['Score'],
          
        },
      
      
      };
    
 

        return (
          <>

            <TableRow>
              <TableCell component="th" scope="row" >
                <FormControlLabel control={<Checkbox 
                 color="primary"
                 checked={isSelected(item.id)}
                 onChange={(event) => handleClick(event, item.id)}
                />}/>
              </TableCell>
              <TableCell sx={{ fontWeight:'bold', width:'230px'}} align='center'>
                <label >{item.context}</label>
              </TableCell>
              <TableCell align='center' sx={{fontWeight:'bold', width:'230px'}}><label>{item.plattform}</label></TableCell>

              <TableCell  sx={{ width:'230px'}}>
                <div className='ai-subpage-piechart-container'>
                <Chart type="radialBar" series={optiIns.series} options={optiIns.options}/>
                </div>
              </TableCell>


              <TableCell sx={{width:'230px'}}>
                <div className='ai-subpage-piechart-container'>
                 <Chart type="radialBar" series={optiHum.series} options={optiHum.options}/>
                </div>
              </TableCell>
              
             
              <TableCell sx={{ display:'flex', width:'230px', justifyContent:'center', alignItems:'center',  fontWeight:'bold'}} align='center'>
                <div className='ai-subpage-piechart-container'>
                
                <Chart type="radialBar" series={averageOpt.series} options={averageOpt.options}/>
                </div>
              </TableCell>
              <TableCell align='center' sx={{fontWeight:'bold', width:'230px'}}>
                <label >{item.safety}</label>
              </TableCell>
            
              <TableCell align='center' sx={{width:'230px'}}>
                <div className='ai-subpage-piechart-container'>
                  <GaugeContainer
                    startAngle={-110}
                    endAngle={110}
                    value={Number(item.attention)}
                  >
                    <GaugeReferenceArc />
                    <GaugeValueArc />
                    <GaugePointer />
                  </GaugeContainer>
                </div>
              </TableCell>
              <TableCell align='center' sx={{display:'flex', width:'230px', justifyContent:'center', alignItems:'center',  fontWeight:'bold'}}>
                <div className='ai-subpage-piechart-container'>
                <Chart type="radialBar" series={averageOpt2.series} options={averageOpt2.options}/>

                </div>
              </TableCell>

          
            </TableRow>

          </>
        )
    })

  }


}
