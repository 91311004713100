import React,{useEffect, useState, Component} from 'react';
import MainPic from '../assets/Produktfoto1_LP_SmileCraft.webp'
import MainPic2 from '../assets/Produktfoto2_LP_SmileCraft.webp'
import DataText from '../../../data/adcheck_article/text_smile_land.json'

import { register } from 'swiper/element/bundle';
import Footer from '../Footer/FooterLandSmile';

import HeadSmile from '../Header/landingpage_header/HeadSmile';

register();
function BodySmileLand() {

  let urlParam=document.location.search;
  let searchParams=new URLSearchParams(urlParam)
  //let adtype=searchParams.get("adt")
  //let mod=searchParams.get("mod")
 


  useEffect(()=>{

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });

    const swiperEl = document.querySelector('swiper-container');


    swiperEl.swiper.on('reachEnd',()=>{
      swiperEl.swiper.allowSlideNext=false
    })

    swiperEl.swiper.on('fromEdge', () => {
     
      swiperEl.swiper.allowSlideNext = true; 
    });
  
},[])




  return (
    <>
    <HeadSmile/>
  <div className="AdcheckBody-smile-landing">

<div className='AdcheckBodySmile-landing-MainPic-container'>

<swiper-container direction="vertical" className="AdcheckBody-landing-swip-container" style={{height:'440px'}} slides-per-view="1" pagination="true" autoplay-delay="5500">
 
<swiper-slide>
  <div className='AdcheckBodySmile-landing-MainPic-smile'>
    <img id="AdcheckBodySmile-landing-MainPic-first" src={MainPic} />
  </div>
  </swiper-slide>
  
  <swiper-slide>
  <div className='AdcheckBodySmile-landing-MainPic-smile'>
    <img src={MainPic2}/>
  </div>
  </swiper-slide>

  </swiper-container>
  </div>

  <div className='AdcheckBodySmile-landing-Descr'>
    <div className='landing-Descr-container'>
<Text/>


    </div>
  </div>
   
  
</div>
<Footer/>
</>
  );


function Text(){
  return DataText.content.map((item,index)=>{

    if(index==1){
      return (

        <div key={index}>
        <ul className='Descr-container-list'>
        <li>High-Tech Zahnbürste für eine spürbar bessere Zahnpflege </li>
        <li>Neu und ab sofort online erhältlich</li>
        <li>Von Zahnärzt*innen und Zahntechniker*innen entwickelt</li>
        <li>Vereint neueste Technologien und steigert durch personalisierte Putzanalysen die dentale Gesundheit</li>
       
      
      </ul>

     

      <div className='adcheck-textcontainer-textitem' key={index}>
      <div className='adcheck-textitem-head Headsmilelanding'>{item.head}</div>
      <div className='adcheck-textitem-text Textgrilllanding'>
            
      {item.text.split('\n').map((line,index)=>{
      
      return(
      <p key={index}>{line}</p>
      )
      })}
        
      </div> 
      </div>
      
      </div>

      )
    }
    if(index==2){
      return (

        <div key={index}>
       
        <ul className='Descr-container-list'>
        <li>Handstück mit Magnet-Technologie, künstlicher Intelligenz und Display</li>
        <li>Magnetisches Ladegerät mit 2-poligem EU-Stecker</li>
        <li>Lade-Reise-Etui</li>
        <li>Intelligente Druckkontrolle für ein optimales Putzerlebnis</li>
      
      </ul>
     

      <div className='adcheck-textcontainer-textitem' key={index}>
      <div className='adcheck-textitem-head Headsmilelanding'>{item.head}</div>
      <div className='adcheck-textitem-text Textgrilllanding'>
            
      {item.text.split('\n').map((line,index)=>{
      
      return(
      <p key={index}>{line}</p>
      )
      })}
        
      </div> 
      </div>
      
      </div>

      )
    }
else{
    return (

      <div className='adcheck-textcontainer-textitem' key={index}>
        <div className='adcheck-textitem-head Headsmilelanding'>{item.head}</div>
        <div className='adcheck-textitem-text Textgrilllanding'>
       
        {item.text.split('\n').map((line,index)=>{
        
       return(
        <p key={index}>{line}</p>
       )
        })}
          
        </div> 
     
      </div>
    )
  }
  })
}

}


  




export default BodySmileLand;
