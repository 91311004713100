import React,{useEffect, useState, Component, useRef} from 'react';
import '../styles/style.scss' 
import Logo from '../assets/head/logo.svg'
import Favorite from '../assets/head/favorite.svg'
import Bell from '../assets/head/bell.svg'
import Flag from '../assets/head/flag.svg'
import MenuIcon from '@mui/icons-material/Menu';
import { Outlet } from 'react-router-dom';

function Head() {



useEffect(()=>{
    
  
},[])




  return (
    <>
<div className='head-autoscout'>

    <div className='head-autoscout-container'>
    <div className='head-autoscout-hamburger-menu'><MenuIcon sx={{color:'white'}}/></div>
        <div className='head-autoscout-logo-container'>
           
            <img src={Logo}/>
        </div>


        <div className='head-autoscout-menu-container'>

            <div className='head-autoscout-menu-items'>
           <div className='head-autoscout-menu-item'>Kaufen </div>
           <div className='head-autoscout-menu-item'>Verkaufen</div>
           <div className='head-autoscout-menu-item'>Flexible Kaufmodelle</div>
           <div className='head-autoscout-menu-item'>Entscheidungshilfe</div>

           </div>

           <div className='head-autoscout-menu-interact'>
            <div className='head-autoscout-menu-icon fav'><img src={Favorite}/></div>
            <div className='head-autoscout-menu-icon bell'><img src={Bell}/></div>

            <div className='head-autoscout-menu-verticalLine'></div>

            <div className='head-autoscout-menu-loginbtn'>Anmelden</div>

            <div className='head-autoscout-menu-language'><img src={Flag}/></div>

            

           </div>

           <div className='head-autoscout-menu-selling-cta'>Mein Auto verkaufen</div>   


        </div>



    </div>


</div>
<Outlet/>
</>


  );


}


  




export default Head;
