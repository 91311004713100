import React,{useEffect, useState, Component, useRef} from 'react';
import '../styles/style.scss'
import Img from '../assets/body/1.webp'
import Filter from './components/Filter'
import Cards from './components/Cards'
import CardsVehicle from './components/CardsVehicle'
import CardsInfo from './components/CardsInfo'
import Icons from './components/Icons'
import WhiteBlock from './components/WhiteBlock'
import Footer from './components/Footer'
import Data1 from '../../data/autoscout/data1.json'
import Data2 from '../../data/autoscout/data2.json'



function BodyAutoScout() {



useEffect(()=>{

  
},[])




  return (

<div className='body-autoscout'>

<div className='body-autoscout-start-img'>
    <img src={Img}/>
</div>

<div className='container-filter-autoscout'>
<Filter/>
</div>

<div className='body-autoscout-container'>


<Cards/>
<CardsVehicle data={Data1} text1="Beste Ergebnisse zu deiner letzten Suche" text2="Mehr Fahrzeuge zur letzten Suche"/>
<CardsInfo/>
<CardsVehicle data={Data2} text1="Dies könnte dich auch interessieren" text2="+ Weitere Fahrzeuge"/>
<Icons/>
<WhiteBlock/>
<Footer/>
</div>


</div>

  );


}



export default BodyAutoScout;
