import {React, useEffect, useState, Component, useRef, Fragment} from "react";
import '../styles.scss'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import Dummy from '../assets/dummy.png'
import RecommendIcon from '@mui/icons-material/Recommend';
import Data from '../../../../data/facebook/data.json'
import {useParams, useSearchParams  } from 'react-router-dom';
import DataAds from '../../../../data/facebook/ads.json'

function FaceBookInStream(){

   
    const {fbad}=useParams()

    const [searchParams] = useSearchParams();
    const fbad2 = searchParams.get('ad');
  
    const videoCont=useRef(null)
    const videoAd=useRef(null)
    const videoAdTimeline=useRef(null)
    const videoAdTime=useRef(null)

    let videoDuration=0
    let time=0


    function PlayVideos(){
        const content=document.querySelectorAll('.facebook_content_infeed-videocont')
        let prevVideoContent=null

        videoAd.current.play()
        videoAd.current.pause()

        const callback=function(entries, observer){ 
                    
            entries.forEach(entry=>{
             
              let {target}=entry
        
             
              prevVideoContent=target
             
              if(prevVideoContent!=null){
                
                prevVideoContent.pause()
              }
              
              if(entry.isIntersecting && entry.intersectionRatio>=0.5){
               
                
                target.play()
                //contentTimeUpdate(target)
         
               // target.currentTime=0
              
      
              
            
                
              }
              
            })
           
          }

          
        const options={

            threshold: 1,
           
            }

        const observer=new IntersectionObserver(callback, options)
        
        content.forEach((item)=>{
            //item.muted=true
            observer.observe(item)
       
            item.addEventListener('timeupdate',timeAd)
           
        })
    } 
    function PlayPauseAd(){
        const content=document.querySelectorAll('.facebook_content_infeed-videocont')
       
        const callback=function(entries, observer){ 
                    
            entries.forEach(entry=>{
             
              let {target}=entry
        
             
        
              if(entry.isIntersecting && entry.intersectionRatio>=0.5){
               
                
                target.play()
             
 
              }
              else{
                target.pause()
              }
              
            })
           
          }

          
        const options={

            threshold: 1,
           
            }

        const observer2=new IntersectionObserver(callback, options)
        observer2.observe( videoAd.current)
       
        
           
      
    } 
    
    const timeAd=()=>{
       
       if(videoCont.current.currentTime>=3){
       

        videoCont.current.pause()
        videoCont.current.muted=true
        videoAd.current.style.display="block"
    
        videoAd.current.play()
        videoAd.current.addEventListener('timeupdate',adTimeUpdate)
        PlayPauseAd()
        videoAdTime.current.style.opacity=1

       }
    }

    const adPlayPause=(e)=>{
        if(e.target.paused){
            e.target.play()
        }
        else{
            e.target.pause()
        }
    }


    const adTimeUpdate=(e)=>{


        videoDuration=Math.round(e.target.duration)
         time=Math.round(e.target.currentTime)
        
        
            videoAdTimeline.current.style.width=(time*100)/videoDuration + "%"
          
            if(time>=videoDuration){
             time=0
             videoDuration=0
             videoAd.current.removeEventListener('timeupdate',adTimeUpdate)
        }
        }

    const adEnd=()=>{
        videoCont.current.removeEventListener('timeupdate',timeAd)
        console.log("END")
        videoAd.current.pause()
        videoAd.current.style.display="none"
        videoCont.current.play()
        videoCont.current.muted=false
        videoAdTimeline.current.style.display="none"
        videoAdTime.current.style.opacity=0
    }

useEffect(()=>{
    document.body.style.overflow = 'hidden';
},[])

const launch=()=>{
      
    document.getElementById("tiktok-playContent-Dialog").style.display="none"
  document.body.style.overflow = 'auto';

  PlayVideos()
}


    return(
        <div className="facebook_content">
  <div className="tiktok-playContent-dialog" id="tiktok-playContent-Dialog">
      <div className="tiktok-playContent_btn-text">Klicken Sie auf "Weiter"</div>
      <div className="tiktok-playContent_btn-container"><div className="tiktok-playContent_btn" onClick={launch}>Weiter</div></div>
    </div>
        <Content/>
     
        </div>
    )


    function Content(){
        return Data.data.map((item, index)=>{

            if(index==2){
                return(
                    <div className="facebook_content_container" key={index}>

<div className="facebook_content_head">
        <div className="facebook-content_head-post_img"><img src={DataAds.ads[0].icons}/></div>
        <div className="facebook-content_head-post-name_container">
        <div className="facebook-content_head-post-name">{DataAds.ads[fbad].acc_name}</div>
        <div className="facebook-content_head-post-publ">{DataAds.ads[fbad].date} <SettingsIcon sx={{fontSize:'13px', marginLeft:'10%'}}/></div>
        </div>
        <div className="facebook-content_head-post-func_container">
        <div className="facebook-content_head-post-more"><MoreHorizIcon sx={{color:'#65686D'}}/></div>
        <div className="facebook-content_head-post-close"><CloseIcon  sx={{color:'#65686D'}}/></div>
        </div>
        </div>

        <div className="facebook_content_text_container">
            <div className="facebook_content_text_vert-line"></div>
            <div className="facebook_content_text">{DataAds.ads[fbad].text}</div>
        </div>
        <div className="facebook_content_video">
        
        <video ref={videoAd} className="facebook_content_infeed-videoad_instream" src={DataAds.ads[fbad2].ad_url} playsInline onEnded={adEnd} onClick={adPlayPause}/>
        <video controls ref={videoCont} className="facebook_content_infeed-videocont"  src={Data.contentvideo[fbad].ad_url} playsInline/>

        <div ref={videoAdTime} class="facebook_time_container">
         <div ref={videoAdTimeline} className="facebook_videoAd-timeline"></div>
        </div> 

        </div>

        <div className="facebook_content_foot_container">
        <div className="facebook_content_foot_info">
            <div className="facebook_content_foot_info_likes"><RecommendIcon sx={{color:'#1963F6', fontSize:'19px'}}/>{DataAds.ads[fbad].likes}</div>
            <div className="facebook_content_foot_info_rep-com">
                <div className="facebook_content_foot_info_com">{DataAds.ads[fbad].com} Kommentar</div>
                <div className="facebook_content_foot_info_rep">Reposts: {DataAds.ads[fbad].rep}</div>
            </div>
        </div>

        <div className="facebook_content_foot_btns">
        <div className="facebook_content_foot_btn" style={{marginLeft:'1%'}}><div className="facebook_content_foot_btn-like"></div><div>Gefällt mir</div></div>
        <div className="facebook_content_foot_btn"><div className="facebook_content_foot_btn-com"></div><div>Kommentieren</div></div>
        <div className="facebook_content_foot_btn"><div className="facebook_content_foot_btn-send"></div><div>Senden</div></div>
        <div className="facebook_content_foot_btn" style={{marginRight:'3%'}}><div className="facebook_content_foot_btn-share"></div><div>Teilen</div></div>
     
        </div>

        </div>

                    </div>
                )
            }
else{
            return(
                <div className="facebook_content_container" key={index}>

        <div className="facebook_content_head">
        <div className="facebook-content_head-post_img"><img src={item.icons}/></div>
        <div className="facebook-content_head-post-name_container">
        <div className="facebook-content_head-post-name">{item.acc_name}</div>
        <div className="facebook-content_head-post-publ">{item.date} <SettingsIcon sx={{fontSize:'13px', marginLeft:'10%'}}/></div>
        </div>
        <div className="facebook-content_head-post-func_container">
        <div className="facebook-content_head-post-more"><MoreHorizIcon sx={{color:'#65686D'}}/></div>
        <div className="facebook-content_head-post-close"><CloseIcon  sx={{color:'#65686D'}}/></div>
        </div>
        </div>

        <div className="facebook_content_text_container">
            <div className="facebook_content_text_vert-line"></div>
            <div className="facebook_content_text">{item.text}</div>
        </div>
        <div className="facebook_content_img">
        <img src={item.post_img_url}/>
        </div>

        <div className="facebook_content_foot_container">
        <div className="facebook_content_foot_info">
            <div className="facebook_content_foot_info_likes"><RecommendIcon sx={{color:'#1963F6', fontSize:'19px'}}/>{item.likes}</div>
            <div className="facebook_content_foot_info_rep-com">
                <div className="facebook_content_foot_info_com">{item.com} Kommentar</div>
                <div className="facebook_content_foot_info_rep">Reposts: {item.rep}</div>
            </div>
        </div>

        <div className="facebook_content_foot_btns">
        <div className="facebook_content_foot_btn" style={{marginLeft:'1%'}}><div className="facebook_content_foot_btn-like"></div><div>Gefällt mir</div></div>
        <div className="facebook_content_foot_btn"><div className="facebook_content_foot_btn-com"></div><div>Kommentieren</div></div>
        <div className="facebook_content_foot_btn"><div className="facebook_content_foot_btn-send"></div><div>Senden</div></div>
        <div className="facebook_content_foot_btn" style={{marginRight:'3%'}}><div className="facebook_content_foot_btn-share"></div><div>Teilen</div></div>
     
        </div>

        </div>

        </div>
            )
        }
        })
    }

}

export default FaceBookInStream;