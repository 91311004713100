import React,{useEffect, useState, Component} from 'react';
import Head from '../Head/Head'
import Motors from '../../Images/img/motors.jpg'
import Ford from '../../Images/img/ford.jpg'
import Audi from '../../Images/img/audi.jpg'
import Krenz from '../../Images/img/krenz.jpg'
import Prima from '../../Images/img/prima.jpg'
import Verkauf from '../../Images/img/verkauf.jpg'
import '../BodyStyle/styles.scss'

function Dealer() {


  useEffect(()=>{

  
  
},[])




  return (
    <div className="MobileDealer">
      <div className='MagazinTitle Dealer'>mobile.de Magazin</div>



  <div className='MobileDealerBlock'>
      <div className='DealerBlock'>
        <div className='DealerBlockImg'>
          <img src={Motors}/>
        </div>
        <div className='DealerBlockText'>
          <div className='DealerBlockHead'>Sclusive Motors</div>
          <div className='DealerText'>33104 Paderborn</div>
        </div>
      </div>

      <div className='DealerBlock'>
        <div className='DealerBlockImg'>
          <img src={Krenz}/>
        </div>
        <div className='DealerBlockText'>
          <div className='DealerBlockHead'>Krenz Autoservice GmbH</div>
          <div className='DealerText'>33100 Paderborn</div>
        </div>
      </div>


      <div className='DealerBlock'>
        <div className='DealerBlockImg'>
          <img src={Audi}/>
        </div>
        <div className='DealerBlockText'>
          <div className='DealerBlockHead'>Autohaus Schröder Senne GmbH & Co. Team KG</div>
          <div className='DealerText'>33689 Bielefeld-Sennestadt</div>
        </div>
      </div>


      <div className='DealerBlock'>
        <div className='DealerBlockImg'>
          <img src={Ford}/>
        </div>
        <div className='DealerBlockText'>
          <div className='DealerBlockHead'>Ford Store Fiekens - Auto Fiekens GmbH</div>
          <div className='DealerText'>33758 Schloß Holte Stukenbrock</div>
        </div>
      </div>


      <div className='DealerBlock'>
        <div className='DealerBlockImg'>
          <img src={Prima}/>
        </div>
        <div className='DealerBlockText'>
          <div className='DealerBlockHead'>PrimeAuto GmbH</div>
          <div className='DealerText'>33154 Salzkotten</div>
        </div>
      </div>


      <div className='DealerBlock'>
        <div className='DealerBlockImg'>
          <img src={Verkauf}/>
        </div>
        <div className='DealerBlockText'>
          <div className='DealerBlockHead'>D.B. Automobile Inh. Daniel Böhmer</div>
          <div className='DealerText'>33154 Salzkotten</div>
        </div>
      </div>

      
      </div>
     </div>

  );
}


  


export default Dealer;
