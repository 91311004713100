import React,{useEffect, useState, Component, useRef} from 'react';
import AdAudi1 from './ads/AdAudi1' 
import AdAudi2 from './ads/AdAudi2' 
import AdAudi3 from './ads/AdAudi3' 
import AdAudi4 from './ads/AdAudi4' 
import AdAudi5 from './ads/AdAudi5' 
import '../BodyStyle/styles.scss'

function Body() {

  var params=document.location.search
  var searchParams= new URLSearchParams(params)
  var type=searchParams.get("hpto")

  return (
 
    <div>
     <AdType/>
    </div>

  );


  function AdType(){
    
    if(type==="1"){
      return(
       <>
        <AdAudi1/>
       </>
      )
    }
    if(type==="2"){
      return(
       <>
       <AdAudi2/>
       </>
      )
    }
    if(type==="3"){
      return(
       <>
       <AdAudi3/>
       </>
      )
    }
    if(type==="4"){
      return(
       <>
       <AdAudi4/>
       </>
      )
    }
    if(type==="5"){
      return(
       <>
       <AdAudi5/>
       </>
      )
    }
    
  }
}


  


export default Body;
