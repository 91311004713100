import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams} from "react-router";
import bgImg from '../../../assets/ads/fireplace/top/bg.jpg'
import textImg from '../../../assets/ads/fireplace/top/c1.png'



function AdTop() {


    const {ads}= useParams()
 
  
  



  useEffect(()=>{


    
  
},[])




  return (
    <>
  <div className="hpto_ad-top-autoscout">
    <div className='hpto_ad-top-autoscout_img-container'>
      <div><img src={bgImg}/></div>
      <div className='hpto_ad-top-autoscout_img_fireplace_top'><img src={textImg}/></div>
    </div>
    
    
   
  
</div>

</>
  );


 
}


  




export default AdTop;
