import React,{useEffect, useState, Component} from 'react';
import Head from '../Head/Head'
import Filter from './Filter'
import Financing from '../../Images/img/BannerFinancing.png'
import Selling from '../../Images/img/SellingBanner.png'
import '../BodyStyle/styles.scss'

function OptionsSecond() {


  useEffect(()=>{

  
  
},[])




  return (
    <div className="MobileOptions">
     
    <div className='Option'>
        <div className='OptionBlock'>
            <img className='OptionImg' src={Financing}/>
        </div>
        <div className='OptionText'><span>Finanzierung leicht gemacht</span><div className='OptionText2'>In wenigen Minuten zur Online-Finanzierung.</div><div className='OptionBtn'>Mehr entdecken</div></div>
    </div>
    <div className='Option'>

    <div className='OptionBlock'>
            <img className='OptionImg' src={Selling}/>
        </div>
        <div className='OptionText'><span>Verkaufe Dein Fahrzeug</span><div className='OptionText2'>An geprüfte Händler in Deiner Nähe oder an über 14 Mio Interessenten.</div><div className='OptionBtn'>Verkaufswert anzeigen</div></div>

    </div>

     </div>

  );
}


  


export default OptionsSecond;
