import React,{useEffect, useState, Component} from 'react';
import MainPic from '../assets/MainPic.png'
import DataText from '../../../data/adcheck_article/text.json'
import Ads from '../../../data/adcheck_article/ads.json'
import { useParams } from "react-router";
import Logo from '../assets/advr_tofu.png'

function FooterLandSmile() {



  useEffect(()=>{


  
},[])




  return (
  <div className='adcheck-footer-landSmile'>

      <div className='adcheck-footertext-block-landSmile'></div>
    <div className='adcheck-footertext-container-landSmile'>
    <div>Shop</div>
    <div>Kontakt</div>
    <div>Impressum</div>
    </div>

  </div>
  );



}


  




export default FooterLandSmile;
