import React,{useEffect, useState, Component} from 'react';
import '../styles.scss'
import Text from '../../../../data/reweka/text_details.json'
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from '@mui/material';


function BannerLongDetails({closeDetails,closeDetailBanner}) {



useEffect(()=>{


},[])




  return (
    <div className='reweka-Banner-long'>  
    <div className='reweka-details-close' onClick={closeDetails}>X</div>
      <div className='reweka-Banner-long-headText'>Optionale Cookies und Technologien erlauben?</div>
      
      <div className='reweka-Banner-long-text_btns'>
        <div className='reweka-Banner-long-text'>
          {Text.content[0].text}
        </div>

      <div className='reweka-details-switchers'>
     <div className='ad_klicks reweka-details-switcher'> <FormControlLabel  control={<Switch defaultChecked disabled={true} />} label={<div className='reweka-switch-label'>Zwingend notwendig</div>}/></div>    
     <div className='ad_klicks reweka-details-switcher' data-click-tracking="reweka_details_marketing_cookie"> <FormControlLabel  control={<Switch />} label={<div className='reweka-switch-label'>Komfort Cookies</div>}/></div>
     <div className='ad_klicks reweka-details-switcher' data-click-tracking="reweka_details_statistik"> <FormControlLabel  control={<Switch  />} label={<div className='reweka-switch-label'>Marketing Cookies </div>}/></div>
      </div>

        <div className='reweka-Banner-long-details-btns'>
          <div className='ad_klicks reweka-btn' data-click-tracking="reweka_details_auswahl_akzeptieren" onClick={closeDetailBanner}>Auswahl erlauben</div>
          <div className='ad_klicks reweka-btn' data-click-tracking="reweka_details_ablehnen" onClick={closeDetailBanner}>Alle ablehnen</div>
          <div className='ad_klicks reweka-btn reweka-btnout' data-click-tracking="reweka_details_alle_akzeptieren" onClick={closeDetailBanner}>Alle erlauben</div>
        </div>
      </div>
    </div>
  );




}






export default BannerLongDetails;
