import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams} from "react-router";
import bgImg from '../../../../assets/ads/hpto/hpto_a5_l/bg.jpg'
import bgCta from '../../../../assets/ads/hpto/hpto_a5_l/cta.png'
import bgLogo from '../../../../assets/ads/hpto/hpto_a5_l/legal.png'


function AdSide_L() {


    const {ads}= useParams()
 
  
  
    const textblock=useRef(null)
    const imgg=useRef(null)
    const ctaBtn=useRef(null)

    const text1=useRef(null)
    const text2=useRef(null)

   
    const imgContainer=useRef(null)


  useEffect(()=>{

    setTimeout(() => {
      
    }, 100);

    setTimeout(() => {
      textblock.current.style.opacity=1  
    }, 1000);

    setTimeout(() => {
      ctaBtn.current.style.opacity=1  
      ctaBtn.current.style.transform="scale(1)"  
    }, 1500);

    
    setTimeout(() => {
      text2.current.style.opacity=0  
      text1.current.style.opacity=1  
      ctaBtn.current.style.transform="scale(1)"  
    }, 4000);
    
  
},[])




  return (
    <>
  <div className="hpto_ad_a5-side-autoscout A534583" ref={imgContainer}>
  <div>
    <div className='hpto_ad_a5-side-autoscout-logo-container'><img src={bgLogo}/> </div>
    
   
    
  </div>
  <div className='hpto_ad_a5-side-autoscout_img-container'><img ref={imgg} src={bgImg}/></div>

  <div className='hpto_ad_a5-side-autoscout_text_btn-container a5_54985' id="hpto_ad-side-autoscout_fireplace-text_btn-container" ref={textblock}>
    <div className='hpto_ad_a5-side-autoscout_text' id="pto_ad-side-autoscout_text-fireplace-left" ref={text1}>Der Audi A4 Avant ist der neue Audi A5,<span id="hpto_ad-side-autoscout_text1"></span><span id="hpto_ad-side-autoscout_text2">#ThisIsAudi</span></div>
    <div className='hpto_ad-side-autoscout_text' ref={text2} id="pto_ad-side-autoscout_text-fireplace-special">Zeit, auf 5 hochzuschalten</div>

    <div className='hpto_ad_a5-side-autoscout_cta' id="hpto_ad-side-autoscout_cta-audi-fireplace" ref={ctaBtn}><img src={bgCta}/></div>

    <div className='hpto_ad_a5-side-autoscout_foot-text'><span>Audi</span> Vorsprung durch Technik</div>
    
    </div>
  
</div>

</>
  );


 
}


  




export default AdSide_L;
