import {useEffect, useState, Component, useRef} from 'react';
import {Link} from "react-router-dom";
import Head from '../Head/Head'
import '../BodyStyle/styles.scss'
import axios from 'axios';



function Filter() {

  const [ad, setAd]=useState("")
  const [btndis, setBtndis]=useState(true)
  const [count, setCount]=useState(0)
  const refAd = useRef(null);

  const [marke, setMarke]=useState("")
  const [modell, setModell]=useState("")
  const [listNum, setListNum]=useState(0)

  const categories=[
    {
      marke:'Audi',
      modell:['Beliebig','100','200','80','90','A1','A2','A3','A4','Coupé']
    },
    {
      marke:'BMW',
      modell:['Beliebig','114','116','118','120','123','125','128','130',]
    },
    {
      marke:'Mercedes-Benz',
      modell:['Beliebig','190','200','220','230','240','250','260','270',]
    },
    {
      marke:'Volkswagen',
      modell:['Beliebig','181','Amarok','Arteon','Beetle','Bora','Buggy','Caddy','CC',]
    },

    
  ]
  const categories2=[
  
    {
      marke:'Nissan',
      modell:['Beliebig','Almera','Armada','Cargo','Cube','Bluebird','370Z','Cabstar','100 NX',]
    },
    {
      marke:'Toyota',
      modell:['Beliebig','4-Runner','Alphard','Auris','Avalon','Camry','Carina','Corolla','Crown',]
    },
    {
      marke:'Suzuki',
      modell:['Beliebig','Across','Alto','Baleno','Carry','Grand Vitara','Ignis','Jimmy','SJ Samurai',]
    },
    {
      marke:'Ford',
      modell:['Beliebig','Aerostar','B-Max','Bronco','Capri','C-Max','Edge','Escort','Explorer',]
    },
    
  ]


  useEffect(()=>{
  
    if(ad!=""){
      setBtndis(false)
      refAd.current.style.backgroundColor="#ff6600"
      refAd.current.style.border="1px solid #f60"
     const Number=Math.round(generateNumber(200-(listNum*10),3000-(listNum*100)))
   console.log(Number)
   console.log(listNum)
        const IntervalCount=setInterval(() => {
         
          if(Number<count){
            setCount(prevCount => {
              const newCount = prevCount - 14;
             
              if(newCount<=Number){
               clearInterval(IntervalCount)
              }
             return newCount;
           });
          }
       
          else{
            setCount(prevCount => {
              const newCount = prevCount + 14;
             
              if(newCount>=Number){
               clearInterval(IntervalCount)
              }
             return newCount;
           });
          }
       
      }, 1);
      }
    
      
  
},[ad,listNum])


function generateNumber(min, max){
  return Math.random()*(max-min)+min
}


const handleSelectMarke = (e) =>{
  const selectedMarke=e.target.value
  setAd(e.target.value)
  setMarke(selectedMarke)
  setModell('')

  
}

const handleSelectModel = (e) =>{
  const selectedModel=e.target.value
  setModell(selectedModel)
  setListNum(listNum+1)

}

const handleSelectYear = (e) =>{
  
  
  setListNum(listNum+1)

}

const handleSelectKm = (e) =>{
 
  
  setListNum(listNum+1)

}

const handleSelectPrice = (e) =>{
  
  
  setListNum(listNum+1)

}


  return (
    <div className="MobileFilter">
    <div className='MobileCategory'>
      <div className='CategoryIcon' id="CategoryIconCar"><div data-testid="qs-segment-Car"><svg  width="40" height="20" viewBox="0 0 96 48" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"><path id="carIcon" fillRule="evenodd" clipRule="evenodd" d="M54 20c-.036.044 1-9 1-9h14c2 0 8 9 8 9H54zm-26 0l13.357-7.646S44 11 46 11h7s-.968 9.046-1 9H28zm58.5 1.5c-2-2-9.5-8.5-13.134-11.067C72.14 9.568 71 9 69 9H46c-3 0-8 3-8 3l-14 8s-6.5 1.5-10.5 2.5C11.42 23.02 9 25.131 9 27c0 1-.25 1.5-.5 2S8 30 8 31v2c0 2 1 3 3 3h2c0-4.973 4.029-9 9-9a9 9 0 019 9h32c0-4.973 4.029-9 9-9a9 9 0 019 9h5c2 0 3-1 3-3v-2c0-1-.25-1.5-.5-2s-.5-1-.5-2c0-2-.44-4.44-1.5-5.5zM65 36a7 7 0 1114 0 7 7 0 01-14 0zm3 .002A4 4 0 1075.998 36a4 4 0 00-7.998.002zM22 29a7.001 7.001 0 10.002 13.998A7.001 7.001 0 0022 29zm-.001 11a4 4 0 110-7.998A4 4 0 0122 40z" fill="currentColor"></path></svg></div></div>
      <div className='CategoryIcon' id="CategoryIconMoto"><div data-testid="qs-segment-Motorbike" ><svg  width="40" height="20" viewBox="0 0 96 48" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"><path id="motoIcon" fillRule="evenodd" clipRule="evenodd" d="M74 14l-16.458 2.003L55.26 19h-5.184s-.576-3-3.576-4c-2.867-.956-5-1-5-1l-.5-3-3-3s-10.5 3-10.5 8c0 1.5 4.5 3 4.5 3l.51 3.572c.233.078.461.164.687.257l-1.02 1.748A8.978 8.978 0 0029 24a9.001 9.001 0 000 18c4.968 0 9-4.032 9-9a8.98 8.98 0 00-3.233-6.91l1.02-1.747A10.98 10.98 0 0140 33c0 .901-.108 1.777-.313 2.615C41.979 36.373 44.833 37 48 37c3.167 0 6.021-.627 8.313-1.385A11.025 11.025 0 0156 33c0-1.01.136-1.987.39-2.915l1.86.797A9.001 9.001 0 0067 42a9 9 0 10-7.567-13.875l-1.86-.797A10.993 10.993 0 0166.82 22L74.5 17.5 74 14zM62.025 32.5l4.384 1.879a1.5 1.5 0 001.182-2.758l-4.384-1.878A4.986 4.986 0 0166.998 28C69.758 28 72 30.24 72 33.002A5.002 5.002 0 0166.998 38a4.999 4.999 0 01-4.973-5.5zM28.998 28c.382 0 .753.043 1.11.124l-2.404 4.12a1.5 1.5 0 002.592 1.512l2.402-4.119A4.984 4.984 0 0134 33.002 5.002 5.002 0 0128.998 38 4.999 4.999 0 0124 33.002 5.001 5.001 0 0128.998 28z" fill="currentColor"></path></svg></div></div>
      <div className='CategoryIcon' id="CategoryIconBus"><div data-testid="qs-segment-Motorhome" ><svg  width="40" height="20" viewBox="0 0 96 48" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"><path id="busIcon" fillRule="evenodd" clipRule="evenodd" d="M43 37h10V21H43v16zm35-17h4v-8h-4v8zm-16 0h12v-8H62v8zM42 38h12V12H42v26zM32 22l-13 2 5-12h8v10zM16 4s-1 2-1 4 1 4 1 4h5l-5 12s-3 1.5-4 3c-.666 1-1 1.904-1 4 0 1-.25 1.5-.5 2s-.5 1-.5 2v2c0 2 2.552 3 3 3h3a8 8 0 1116 0h28a8 8 0 1116 0h5.62L84 36.598V8c0-4.5-5-4-5-4H16zm14 36a6 6 0 11-12 0 6 6 0 0112 0zm-9 0a3 3 0 106 0 3 3 0 00-6 0zm47 6a6 6 0 100-12 6 6 0 000 12zm0-3a3 3 0 110-6 3 3 0 010 6z" fill="currentColor"></path></svg></div></div>
      <div className='CategoryIcon' id="CategoryIconTruck"><div data-testid="qs-segment-Truck" ><svg  width="40" height="20" viewBox="0 0 96 48" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"><path id="truckIcon" fillRule="evenodd" clipRule="evenodd" d="M88 30V6H26.139v24H88zM11.544 12.91L11 14h13v-4l-10.995 1.833a2 2 0 00-1.46 1.078zM88 32H24V15.147H10.472L6 27.739V39.16L9 41h6.016a7.5 7.5 0 1114.967 0h37.033a7.5 7.5 0 0114.119-4H88v-5zM9.197 26.463L21 24.192l.027-7.121c-.002.032-8.996-.035-8.996-.035l-2.834 9.427zM80 40.5a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0zm-8.5 0a3 3 0 106 0 3 3 0 00-6 0zm-49 5.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11zm0-2.5a3 3 0 110-6 3 3 0 010 6z" fill="currentColor"></path></svg></div></div>
    </div>

    <div className='MobileFilterMenu'>
      <div className='Menus'>
        <div className='SelectorDiv'>Marke<select className='Selector' onChange={handleSelectMarke}>
          <option value="Beliebig">Beliebig</option>
          <optgroup label="Top-Marken">
      {categories.map((item)=>(
        <option key={item.marke} value={item.marke}>
          {item.marke}
        </option>
      ))}
    
    </optgroup>

    <optgroup label="Alle Marken">
    {categories2.map((item)=>(
        <option key={item.marke} value={item.marke}>
          {item.marke}
        </option>
      ))}
    </optgroup>
          </select></div>
        <div className='SelectorDiv'>Modell<select className='Selector' onChange={handleSelectModel}>
       
        {categories.find(item=>item.marke===marke)
     ?.modell.map((item)=>(
      <option key={item} value={item}>
        {item}
      </option>
     ))}
        {categories2.find(item=>item.marke===marke)
     ?.modell.map((item)=>(
      <option key={item} value={item}>
        {item}
      </option>
     ))}
        
          </select></div>
        <div className='SelectorDiv'>Erstzulassung ab<select className='Selector' onChange={handleSelectYear}>
          <option>Beliebig</option>
          <option>2023</option>
          <option>2022</option>
          <option>2021</option>
          <option>2020</option>
          <option>2019</option>
          <option>2018</option>
          <option>2017</option>
          <option>2016</option>
          </select></div>
        <div className='SelectorDiv'>Kilometer bis<select className='Selector' onChange={handleSelectKm}>
        <option>Beliebig</option>
        <option>5.000 km</option>
        <option>10.000 km</option>
        <option>15.000 km</option>
        <option>20.000 km</option>
        <option>25.000 km</option>
        <option>30.000 km</option>
        <option>35.000 km</option>
        <option>40.000 km</option>
          </select></div>
        <div className='SelectorDiv'>Zahlunsart <div className='Selector Special'><div className='BuyLeasing' id="buy">Kaufen</div><div className='BuyLeasing' id="lease">Leasing</div></div></div>
        <div className='SelectorDiv'>Preis bis<select className='Selector' onChange={handleSelectPrice}>
        <option>Beliebig</option>
        <option>500 €</option>
        <option>1.000 €</option>
        <option>1.500 €</option>
        <option>2.000 €</option>
        <option>2.500 €</option>
        <option>3.000 €</option>
        <option>3.500 €</option>
        <option>4.000 €</option>
          </select></div>
        <div className='SelectorDiv'> Ort oder PLZ<select className='Selector'></select></div>
      <Link className='Link' to="cars" style={{textDecoration:'none'}}><button className='SelectorDiv SelectorBtn' disabled={btndis} ref={refAd} ><svg id="lupe" width="16" height="16" viewBox="0 0 24 24" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M16.215 18.336a9 9 0 112.121-2.121L23.121 21 21 23.121l-4.785-4.785zM11 17.25a6.25 6.25 0 100-12.5 6.25 6.25 0 000 12.5z" fill="currentColor"></path></svg>{count} Angebote</button></Link>

      </div>
      <div className='MoreFilter'>
        <div>
       <label ><input type='checkbox' className='CheckBox'/><span className='CheckBoxLabel'>Online-Kauf</span></label>
       <label><input type='checkbox' className='CheckBox'/><span className='CheckBoxLabel'>Nur Elektroautos</span></label>
       </div>
       <div className='Filter'><svg  width="16" height="16" viewBox="0 0 24 24" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M21 6h-8.337a3.5 3.5 0 00-6.326 0H3v3h3.337a3.5 3.5 0 006.326 0H21V6zM9.5 9a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM21 15h-3.337a3.5 3.5 0 00-6.326 0H3v3h8.337a3.5 3.5 0 006.326 0H21v-3zm-6.5 3a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" fill="currentColor"></path></svg> Weitere Filter</div>
      </div>
    </div>
      
     </div>

  );
}


  


export default Filter;
