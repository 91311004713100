import React,{useEffect, useState, Component, useRef} from 'react';
import '../../styles/style.scss'
import { register } from 'swiper/element/bundle';

import m_icon1 from '../../assets/body/icons/1.png'
import m_icon2 from '../../assets/body/icons/2.png'
import m_icon3 from '../../assets/body/icons/3.png'
import m_icon4 from '../../assets/body/icons/4.png'
import m_icon5 from '../../assets/body/icons/5.png'
import m_icon6 from '../../assets/body/icons/6.png'

import icon1 from '../../assets/body/icons/1/audi.png'
import icon2 from '../../assets/body/icons/1/bmw.png'
import icon3 from '../../assets/body/icons/1/vw.png'
import icon4 from '../../assets/body/icons/1/mercedes.png'
import icon5 from '../../assets/body/icons/1/ford.png'
import icon6 from '../../assets/body/icons/1/hyuindai.png'
import icon7 from '../../assets/body/icons/1/opel.png'
import icon8 from '../../assets/body/icons/1/peugeot.png'
import icon9 from '../../assets/body/icons/1/skoda.png'
import icon10 from '../../assets/body/icons/1/1.png'

register();
function Icons() {



useEffect(()=>{

  
},[])




  return (

<>
<div className='icons-autoscout-container'>

<div className='icons-autoscout_headText'>Entdecke hier das Angebot von AutoScout24</div>

<div style={{marginTop:20,marginBottom:20, fontWeight:600, fontSize:20}}>Aufbautypen</div>

<div className='icons-autoscout_typeIcons'>
   <div className='icons-autoscout_typeIcons_item'><div className='icons-autoscout_typeIcons_img-container'><img src={m_icon1}/></div><div>Kleinwagen</div></div>
   <div className='icons-autoscout_typeIcons_item'><div className='icons-autoscout_typeIcons_img-container'><img src={m_icon2}/></div><div>Limousine</div></div>
   <div className='icons-autoscout_typeIcons_item'><div className='icons-autoscout_typeIcons_img-container'><img src={m_icon3}/></div><div>Pick-up</div></div>
   <div className='icons-autoscout_typeIcons_item'><div className='icons-autoscout_typeIcons_img-container'><img style={{width:'100%'}} src={m_icon4}/></div><div>SUV</div></div>
   <div className='icons-autoscout_typeIcons_item'><div className='icons-autoscout_typeIcons_img-container'><img src={m_icon5}/></div><div>Transporter</div></div>
   <div className='icons-autoscout_typeIcons_item'><div className='icons-autoscout_typeIcons_img-container'><img style={{width:'30%'}} src={m_icon6}/></div><div>Mehr Aufbautypen</div></div>
</div>

<div style={{marginTop:55,marginBottom:20, fontWeight:600, fontSize:20}}>Beliebte Marken</div>
<div className='icons-autoscout_marken'>

<div className='icons-autoscout_marken_item-container'><div className='icons-autoscout_marken_item_img-container'><img src={icon1}/></div><div>Audi</div></div>
<div className='icons-autoscout_marken_item-container'><div className='icons-autoscout_marken_item_img-container'><img src={icon2}/></div><div>BMW</div></div>
<div className='icons-autoscout_marken_item-container'><div className='icons-autoscout_marken_item_img-container'><img src={icon3}/></div><div>VW</div></div>
<div className='icons-autoscout_marken_item-container'><div className='icons-autoscout_marken_item_img-container'><img src={icon4}/></div><div>Mercedes</div></div>
<div className='icons-autoscout_marken_item-container'><div className='icons-autoscout_marken_item_img-container'><img src={icon5}/></div><div>Ford</div></div>
<div className='icons-autoscout_marken_item-container'><div className='icons-autoscout_marken_item_img-container'><img src={icon6}/></div><div>Hyundai</div></div>
<div className='icons-autoscout_marken_item-container'><div className='icons-autoscout_marken_item_img-container'><img src={icon7}/></div><div>Opel</div></div>
<div className='icons-autoscout_marken_item-container'><div className='icons-autoscout_marken_item_img-container'><img src={icon8}/></div><div>Peugeot</div></div>
<div className='icons-autoscout_marken_item-container'><div className='icons-autoscout_marken_item_img-container'><img src={icon9}/></div><div>Skoda</div></div>
<div className='icons-autoscout_marken_item-container'><div className='icons-autoscout_marken_item_img-container'><img src={icon10}/></div><div>Weitere Marken</div></div>



</div>


<div style={{marginTop:90,marginBottom:20, fontWeight:600, fontSize:20}}>Beliebte Modelen</div>
<div className='icons-autoscout_model'>

<div className='icons-autoscout_model_column'>
  <div className='icons-autoscout_model_column_head'>Audi</div>
  <div className='t463'>
  <div className='icons-autoscout_model_column_item'>Audi A1</div>
  <div className='icons-autoscout_model_column_item'>Audi A3</div>
  <div className='icons-autoscout_model_column_item'>Audi A4</div>
  <div className='icons-autoscout_model_column_item'>Audi A6</div>
  <div className='icons-autoscout_model_column_item'>Audi Q3</div>
  </div>

</div>
<div className='icons-autoscout_model_column'>
  <div className='icons-autoscout_model_column_head'>BMW</div>
  <div className='t463'>
  <div className='icons-autoscout_model_column_item'>BMW 1er</div>
  <div className='icons-autoscout_model_column_item'>BMW 3er</div>
  <div className='icons-autoscout_model_column_item'>BMW 5er</div>
  <div className='icons-autoscout_model_column_item'>BMW X1</div>
  <div className='icons-autoscout_model_column_item'>BMW X3</div>
  </div>

</div>
<div className='icons-autoscout_model_column'>
  <div className='icons-autoscout_model_column_head'>VW</div>
  <div className='t463'>
  <div className='icons-autoscout_model_column_item'>VW Caddy</div>
  <div className='icons-autoscout_model_column_item'>VW Golf</div>
  <div className='icons-autoscout_model_column_item'>VW Polo</div>
  <div className='icons-autoscout_model_column_item'>VW Tiguan</div>
  <div className='icons-autoscout_model_column_item'>VW Touran</div>
  </div>

</div>
<div className='icons-autoscout_model_column'>
  <div className='icons-autoscout_model_column_head'>Mercedes</div>
  <div className='t463'>
  <div className='icons-autoscout_model_column_item'>Mercedes A-Klasse</div>
  <div className='icons-autoscout_model_column_item'>Mercedes B-Klasse</div>
  <div className='icons-autoscout_model_column_item'>Mercedes C-Klassse</div>
  <div className='icons-autoscout_model_column_item'>Mercedes E-Klasse</div>
  <div className='icons-autoscout_model_column_item'>Mercedes S-Klasse</div>
  </div>


</div>
<div className='icons-autoscout_model_column'>
  <div className='icons-autoscout_model_column_head'>Audi</div>
  <div className='t463'>
  <div className='icons-autoscout_model_column_item'>Audi A1</div>
  <div className='icons-autoscout_model_column_item'>Audi A3</div>
  <div className='icons-autoscout_model_column_item'>Audi A4</div>
  <div className='icons-autoscout_model_column_item'>Audi A6</div>
  <div className='icons-autoscout_model_column_item'>Audi Q3</div>
  </div>

</div>
<div className='icons-autoscout_model_column'>
  <div className='icons-autoscout_model_column_head'>Beliebte Elektroautos</div>
  <div className='t463'>
  <div className='icons-autoscout_model_column_item'>Audi e-tron</div>
  <div className='icons-autoscout_model_column_item'>BMW i3</div>
  <div className='icons-autoscout_model_column_item'>Renault Twingo Z.E</div>
  <div className='icons-autoscout_model_column_item'>Tesla Model 3</div>
  <div className='icons-autoscout_model_column_item'>Tesla Model S</div>
  </div>

</div>
<div className='icons-autoscout_model_column'>
  <div className='icons-autoscout_model_column_head'>Aktuelle Elektroautos</div>
  <div className='t463'>
  <div className='icons-autoscout_model_column_item'>BMW i4</div>
  <div className='icons-autoscout_model_column_item'>Dacia Spring</div>
  <div className='icons-autoscout_model_column_item'>Porsche Taycan</div>
  <div className='icons-autoscout_model_column_item'>VW ID.5</div>
  <div className='icons-autoscout_model_column_item'>Elektroauto-Berater</div>
  </div>

</div>



</div>


</div>


</>

  );


}



export default Icons;
