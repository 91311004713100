import React,{useEffect, useState, Component} from 'react';
import Logo from '../Images/logo.png'
import Pay from '../Images/payback.png'
import './styles.scss';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Lupe from '../Images/img/lupe.png'
import User from '../Images/img/user.png'
import Menu from '../Images/img/menu.png'
import Pb from '../Images/pb.png'
import Einkaufswagen from '../Images/img/einkaufswagen.png'
import {Outlet} from "react-router-dom";

function Head() {


  useEffect(()=>{
    let searchField=document.getElementById("searchfield")
    let firstlayer=document.getElementById("firstlayer")
    let content=document.getElementById("content")

    if(window.screen.width<=480){
     // firstlayer.append(searchField)
    }

  },[])

  return (
    <>
    <div className="reweka-Head" id="reweka-head">
      <div id="reweka-firstlayer" className='reweka-FirstLayer'>

<div id="reweka-content" className='reweka-Content'>
<img id="reweka-menu" src={Menu}/>

        <div className='reweka-LogoImg' id="logoimg">
        <img id="reweka-user" src={User}/>

            <img id="reweka-logo" src={Logo}/>

            <div id="reweka-mobileLogos">
            <img id="reweka-wagen" src={Einkaufswagen}/>
            
            </div>
            
        </div>

            <div className='reweka-HeadButtons' id="reweka-headbuttons">
               
                    <div className='reweka-Button'>Märkte & Angebote</div>
                    <div className='reweka-Button'>Lieferservice</div>
                    <div className='reweka-Button'>Rezepte & Ernährung</div>
                
            </div>

            <div className='reweka-Panel' id="reweka-panel">
                <div className='reweka-PanelItem'><HomeOutlinedIcon sx={{fontSize:'25px'}}/><span>|</span>reweka.de</div>
                <div className='reweka-PanelItem'><PersonOutlineOutlinedIcon sx={{fontSize:'25px'}}/><span>|</span>Anmelden</div>
                <div className='reweka-PanelItem'><LocationOnOutlinedIcon sx={{fontSize:'25px'}}/><span>|</span>Markt wählen</div>
                
                <div className='reweka-PanelItem'><img className='reweka-PayBackImg' src={Pay}/></div>
            </div>

            </div>

 

           

       
        
      </div>

 
      <div className="reweka-Secondlayer">
   
        <div id="reweka-searchfield" className="reweka-SearchField"><span>Suche</span><img src={Lupe}/></div>
       
        
       
      </div>

      <div className="reweka-Thirdlayer">
        <div className="reweka-Content3">
            <div id="rewekaLeftbutton" >Aktionen</div>
            <div className="reweka-FourButtons" style={{textTransform:'uppercase'}}>Payback</div>
            <div className="reweka-FourButtons">Marken</div>
            <div className="reweka-FourButtons">Service</div>
            <div className="reweka-FourButtons">Nachhaltigkeit</div>
            </div>
      </div>

    </div>
    <Outlet/>
    </>
  );
}

export default Head;
