import React,{useEffect, useState, Component} from 'react';
import './styles.scss'
import { useParams } from "react-router";
import Pb from './Images/pb.png'
import Worker from './Images/1_worker.png'
import Veg from './Images/2_veg.png'
import Box from './Images/2_pack.png'
import { Link } from 'react-router-dom';



function Container2() {




useEffect(()=>{

  
},[])




  return (
    <div className='reweka-secondContainer'>

      <div className='reweka-category-secondContainer'>
    


      <div className='reweka-secondContainer-category'>
        <div className='reweka-category-img Veg'><img id="reweka-box-img" src={Box}/><img id="reweka-veg-img" src={Veg}/></div>
        <div className='reweka-category-container'>
        <div className='reweka-category-headText'>Reweka Onlineshop</div>
        <div className='reweka-category-text'>Frische Lebensmittel und Produkte für Haushalt und Küche. Lass dir deinen Einkauf bis zur Wohnungstür liefern oder hol ihn in deiner Nähe ab. </div>
        <div className='reweka-secondContainer-category-btns rewekaBtnsContainer2'>
        <Link to="rewekashop" className='reweka-secondContainer-category-btn rewekaBtnFill' id="rewekaBtnFill2"><div>Zum Shop</div></Link>
        </div>
        </div>
      </div>
      
      
      </div>
    </div>
  );
}

  




export default Container2;
