import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams} from "react-router";
//import AdTop from './AdTop';
import AdHptoA5_L2 from './AdHptoA5_L2';
import AdHptoA5_R2 from './AdHptoA5_R2';
import AdHptoA5_R from './AdHptoA5_R';
import Items from '../../subpage/Items';
import Filter from '../../subpage/Filter';



function AdHpto2() {



 
  
  



  useEffect(()=>{


    
  
},[])




  return (
    <div className='subpage-autoscout_ad_content-container'>
    <AdHptoA5_L2/>
    <div className="subpage-autoscout_content-container">
    {/*<AdTop/>*/}
    <div className='subpage-autoscout_container'>
     <div className='subpage-autoscout_filter_btn_container'> 
      <div className='subpage-autoscout_filter-btn_container'><div className='subpage-autoscout_filter-btn'>Suche speichern</div></div>
    <Filter/>
    </div>
  
    <div>
    <Items/>
    </div>
    </div>
    </div>
  
    
    <AdHptoA5_R2/>
      </div>
  );


 
}


  




export default AdHpto2;
