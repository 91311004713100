import React,{useEffect, useState, Component} from 'react';
import Head from '../Head/Head'
import Close from '../../Images/img/close.svg'
import '../BodyStyle/styles.scss'

function Filter() {


  const username = '{username}';
  const password = '{password}';
  const api_base = 'https://services.mobile.de/1.0.0/';

 


  useEffect(() => {

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
   
  }, []);




  return (
    <div className="MobileCarFilter">
      <div className='MobileCarFilterHead'>1.394.796 Angebote entsprechen Deinen Suchkriterien</div>

      <div className='MobileCarFilterPanel'>
        <div className='Panel1'>Beschädigte Fahrzeuge: Nicht anzeigen <img id="closeicon" src={Close}/></div>

      <div className='Sort'>
        <label>Sortieren nach</label>
       <select className='MobileCarFilterSelector'>
        <option>Standard-Sortierung</option>
       </select>
       </div>
        <div className='MobileCarFilterPanelBtn'><svg style={{paddingRight: '10px'}} width="16" height="16" viewBox="0 0 24 24" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M9.787 18.916l-4.84 2.792 1.689-7.965L.587 8.292l8.098-.855L12 0l3.315 7.437 8.098.855-3.724 3.356a6.5 6.5 0 00-9.902 7.269zm10.386.841l2.4 2.4-1.415 1.414-2.399-2.4a5 5 0 111.414-1.414zM16.002 20a3 3 0 100-6 3 3 0 000 6z" fill="currentColor"></path></svg>Suche speichern</div>
      </div>

     </div>

  );
}


  


export default Filter;
