import {createSlice} from '@reduxjs/toolkit'

export const checkSlice=createSlice({
    name:'checks',
    initialState:{
        value: true,
    },
    reducers:{
        checkAd:(state,action)=>{
            console.log('Dispatching checkAd with payload:', action.payload);
            state.value = action.payload
        },
        
    },


})

export const {checkAd}=checkSlice.actions

export default checkSlice.reducer