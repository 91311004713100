import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams} from "react-router";



function Filter() {


    const {ads}= useParams()
 
  
  



  useEffect(()=>{


    
  
},[])




  return (
    <>
  
  <div className="subpage-autoscout-filter">
    
   <div className='subpage-autoscout-filter_block1'>
    <div className='subpage-autoscout-filter_block1_text1'>Meine Suche (1) <span><div className='subpage-autoscout-filter_block1_svg-container'><svg viewBox="0 0 24 24" id="delete_trash" xmlns="http://www.w3.org/2000/svg"><path fill="#1166a8" fillRule="evenodd" d="M14 2c1.1 0 2 .9 2 2v2h5c.6 0 1 .4 1 1s-.4 1-1 1h-2v12c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2V8H3l-.125-.006C2.345 7.942 2 7.557 2 7c0-.6.4-1 1-1h5V4c0-1.1.9-2 2-2zm3 6H7v12h10V8zm-3 2 .125.006c.53.052.875.437.875.994v6c0 .6-.4 1-1 1s-1-.4-1-1v-6c0-.6.4-1 1-1zm-4 0 .125.006c.53.052.875.437.875.994v6c0 .6-.4 1-1 1s-1-.4-1-1v-6c0-.6.4-1 1-1zm4-6h-4v2h4V4z"></path></svg><div>Alle Filter entfernen</div></div></span></div>
    <div className='subpage-autoscout-filter_block1_btn'>Deutschland</div>
    </div>


    <div className='subpage-autoscout-filter_block2'>
    <div className='subpage-autoscout-filter_block1_text1'>Basisdaten & Standort</div>

    <div className='subpage-autoscout-filter_block2_checkboxes'>
    <label><input type='checkbox' className='CheckBox'/><span className='CheckBoxLabel'>Inzahlungnahme</span></label>
    <label><input type='checkbox' className='CheckBox'/><span className='CheckBoxLabel'>Onlinekauf & Lieferung<ul style={{marginLeft:20}}><li>Geprüfte Qualität</li><li>Lieferung deutschlandsweit</li><li>14 Tage Widerrufsrecht</li></ul></span></label>

    </div>

    <div className='subpage-autoscout-filter_block2_container'>
    <div className='subpage-autoscout-filter_block2_container_r643'>
    <div className='subpage-autoscout-filter_block2_standard-text'>Marke</div>
    <select className='subpage-autoscout-filter_block2_drop-down'><option>Alle</option></select>
    <div className='subpage-autoscout-filter_block2_standard-text'>Modell</div>
    <select className='subpage-autoscout-filter_block2_drop-down'><option>Alle</option></select>
    <div className='subpage-autoscout-filter_block2_standard-text'>Variante</div>
    <select className='subpage-autoscout-filter_block2_drop-down'><option>z.B. Plus, GTI, usw.</option></select>
    <div className='subpage-autoscout-filter_block2_standard-text'>Karosserieform</div>
    <select className='subpage-autoscout-filter_block2_drop-down'><option>Alle</option></select>
    <div className='subpage-autoscout-filter_block2_standard-text'>Kraftstoff</div>
    <select className='subpage-autoscout-filter_block2_drop-down'><option>Alle</option></select>
    <div className='subpage-autoscout-filter_block2_standard-text'>Erstzulassung</div>
    <select className='subpage-autoscout-filter_block2_drop-down ez32'><option>von</option></select>
    <select className='subpage-autoscout-filter_block2_drop-down ez32'><option>bis</option></select>
    <div className='subpage-autoscout-filter_block2_standard-text ra32'><div className='subpage-autoscout-filter_block2_radio'><input className='subpage-autoscout-filter_block2_radio_input' type='radio' /><div className='subpage-autoscout-filter_block2_radio_label'>Kaufen</div></div><div className='subpage-autoscout-filter_block2_radio'><input className='subpage-autoscout-filter_block2_radio_input' type='radio'  /><div className='subpage-autoscout-filter_block2_radio_label'>Finanzieren</div></div></div>
    <select className='subpage-autoscout-filter_block2_drop-down ez32'><option>von</option></select>
    <select className='subpage-autoscout-filter_block2_drop-down ez32'><option>bis</option></select>
    </div>


    </div>

    <div className='subpage-autoscout-filter_block2_checkboxes cb32'>
      <div className='subpage-autoscout-filter_block2_checkboxes-container'><input type='checkbox' className='CheckBox2'/><div className='CheckBoxLabel trgr33'>Sehr guter Preis<div className='turf45'><div id='subpage-autoscout-filter_block2_checkboxes_price-line_5'></div></div></div></div>
      <div className='subpage-autoscout-filter_block2_checkboxes-container'><input type='checkbox' className='CheckBox2'/><div className='CheckBoxLabel trgr33'>Guter Preis<div className='turf45'><div id='subpage-autoscout-filter_block2_checkboxes_price-line_4'></div></div></div></div>
      <div className='subpage-autoscout-filter_block2_checkboxes-container'><input type='checkbox' className='CheckBox2'/><div className='CheckBoxLabel trgr33'>Fairer Preis<div className='turf45'><div id='subpage-autoscout-filter_block2_checkboxes_price-line_3'></div></div></div></div>
      <div className='subpage-autoscout-filter_block2_checkboxes-container'><input type='checkbox' className='CheckBox2'/><div className='CheckBoxLabel trgr33'>Erhöhter Preis<div className='turf45'><div id='subpage-autoscout-filter_block2_checkboxes_price-line_2'></div></div></div></div>
      <div className='subpage-autoscout-filter_block2_checkboxes-container'><input type='checkbox' className='CheckBox2'/><div className='CheckBoxLabel trgr33'>Hoher Preis<div className='turf45'><div id='subpage-autoscout-filter_block2_checkboxes_price-line_1'></div></div></div></div>

    </div>

    <div className='subpage-autoscout-filter_block2_container'>
    <div className='subpage-autoscout-filter_block2_container_r643'>
    <div className='subpage-autoscout-filter_block2_standard-text'>Land</div>
    <select className='subpage-autoscout-filter_block2_drop-down'><option>Deutschland</option></select>
    <div className='subpage-autoscout-filter_block2_standard-text'>Stadt/PLZ</div>
    <select className='subpage-autoscout-filter_block2_drop-down'><option>z.B. Berlin oder 10243</option></select>
    <div className='subpage-autoscout-filter_block2_standard-text'>Umkreis (km)</div>
    <select className='subpage-autoscout-filter_block2_drop-down'><option>Umkreis</option></select>

    <div className='subpage-autoscout-filter_block2_standard-text'>Kilometerstand</div>
    <select className='subpage-autoscout-filter_block2_drop-down ez32'><option>von</option></select>
    <select className='subpage-autoscout-filter_block2_drop-down ez32'><option>bis</option></select>

    <div className='subpage-autoscout-filter_block2_standard-text'>Leistung</div>
    <select className='subpage-autoscout-filter_block2_drop-down ez32'><option>von (KW)</option></select>
    <select className='subpage-autoscout-filter_block2_drop-down ez32'><option>bis (KW)</option></select>

    <div className='subpage-autoscout-filter_block2_standard-text'>Getriebe</div>
    <select className='subpage-autoscout-filter_block2_drop-down'><option>Alle</option></select>

    <div className='subpage-autoscout-filter_block2_standard-text'>Sitzplätze</div>
    <select className='subpage-autoscout-filter_block2_drop-down ez32'><option>von</option></select>
    <select className='subpage-autoscout-filter_block2_drop-down ez32'><option>bis</option></select>

    <div className='subpage-autoscout-filter_block2_standard-text' style={{marginTop:'20px'}}>Anzahl Türen</div>
    <div className='subpage-autoscout-filter_block2_ghgt56'><div style={{background:'#333333', color:'white'}}>Alle</div><div>2/3</div><div>4/5</div><div>6/7</div></div>

    <div className='subpage-autoscout-filter_block2_standard-text' style={{marginTop:'30px'}}>Verkäufer</div>
    <div className='subpage-autoscout-filter_block2_ghgt56'><div style={{background:'#333333', color:'white'}}>Alle</div><div>Händler</div><div>Privat</div></div>




    </div>
    </div>

  <div className='subpage-autoscout-filter_block2_checkboxes cb32'>
  <label><input type='checkbox' className='CheckBox'/><span className='CheckBoxLabel2'>Neu</span></label>
  <label><input type='checkbox' className='CheckBox'/><span className='CheckBoxLabel2'>Gebraucht</span></label>
  <label><input type='checkbox' className='CheckBox'/><span className='CheckBoxLabel2'>Jahreswagen</span></label>
  <label><input type='checkbox' className='CheckBox'/><span className='CheckBoxLabel2'>Oldtimer</span></label>
  <label><input type='checkbox' className='CheckBox'/><span className='CheckBoxLabel2'>Vorführfahrzeug</span></label>
  <label><input type='checkbox' className='CheckBox'/><span className='CheckBoxLabel2'>Tageszulassung</span></label>
    </div>

    <div className='subpage-autoscout-filter_block2_rgt44-drop-down' style={{marginTop:30}}><div className='rgt44_label'>Leasing</div><div className='rgt44_btn'>∨</div></div>
    <div className='subpage-autoscout-filter_block2_rgt44-drop-down'><div className='rgt44_label'>Ausstatung</div><div className='rgt44_btn'>∨</div></div>
    <div className='subpage-autoscout-filter_block2_rgt44-drop-down'><div className='rgt44_label'>Farbe</div><div className='rgt44_btn'>∨</div></div>
    <div className='subpage-autoscout-filter_block2_rgt44-drop-down'><div className='rgt44_label'>Polsterfarbe</div><div className='rgt44_btn'>∨</div></div>
    <div className='subpage-autoscout-filter_block2_rgt44-drop-down'><div className='rgt44_label'>Fahrzeugzustand</div><div className='rgt44_btn'>∨</div></div>
    <div className='subpage-autoscout-filter_block2_rgt44-drop-down'><div className='rgt44_label'>Umwelt</div><div className='rgt44_btn'>∨</div></div>
    <div className='subpage-autoscout-filter_block2_rgt44-drop-down'><div className='rgt44_label'>Angebotsdetails</div><div className='rgt44_btn'>∨</div></div>

    </div>
   
    </div>

</>
  );


 
}


  




export default Filter;
