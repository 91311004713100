import React,{useEffect, useState, Component} from 'react';
import MainPic from '../assets/landing_TofuFriend.png'
import MainPic2 from '../assets/landing_TofuFriend_2.png'
import DataText from '../../../data/adcheck_article/text_grill_land.json'

import { register } from 'swiper/element/bundle';
import Footer from '../Footer/FooterLandGrill';

import HeadGrill from '../Header/landingpage_header/HeadGrill';

register();
function BodyLand() {

  let urlParam=document.location.search;
  let searchParams=new URLSearchParams(urlParam)
  //let adtype=searchParams.get("adt")
  //let mod=searchParams.get("mod")
 


  useEffect(()=>{

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });

    const swiperEl = document.querySelector('swiper-container');

    swiperEl.swiper.on('reachEnd',()=>{
      swiperEl.swiper.allowSlideNext=false
    })

    swiperEl.swiper.on('fromEdge', () => {
   
      swiperEl.swiper.allowSlideNext = true; 
    });
  
},[])




  return (
    <>
    <HeadGrill/>
  <div className="AdcheckBody-grill-landing">

<div className='AdcheckBody-landing-MainPic-container'>

<swiper-container direction="vertical" className="AdcheckBody-landing-swip-container" style={{height:'440px'}} slides-per-view="1" pagination="true" autoplay-delay="5500">
 
<swiper-slide>
  <div className='AdcheckBody-landing-MainPic'>
    <img src={MainPic} />
  </div>
  </swiper-slide>
  
  <swiper-slide>
  <div className='AdcheckBody-landing-MainPic'>
    <img id="AdcheckBody-landing-second-MainPic" src={MainPic2}/>
  </div>
  </swiper-slide>

  </swiper-container>
  </div>

  <div className='AdcheckBody-landing-Descr'>
    <div className='landing-Descr-container'>
<Text/>


    </div>
  </div>
   
  
</div>
<Footer/>
</>
  );


function Text(){
  return DataText.content.map((item,index)=>{

    if(index==1){
      return (

        <div key={index}>
        <ul className='Descr-container-list'>
        <li>Tofu mit Geschmacksexplosion </li>
        <li>Neu und ab sofort im Kühlregal erhältlich</li>
        <li>Zu 100% natürliche Zutaten</li>
        <li>Von Spitzenköchen entwickelt</li>
        <li>Gewürzt mit einer fein abgestimmten Mischung aus: Sesam, Chiliflocken, Cumin, Fenchel, Knoblauch, Zimt, Sternanis, Nelken, Zwiebelpulver, Salz und Pfeffer.</li>
      
      </ul>
     


      <div className='adcheck-textcontainer-textitem' key={index}>
      <div className='adcheck-textitem-head Headgrilllanding'>{item.head}</div>
      <div className='adcheck-textitem-text Textgrilllanding'>
            
      {item.text.split('\n').map((line,index)=>{
      
      return(
      <p key={index}>{line}</p>
      )
      })}
        
      </div> 
      </div>
      
      </div>

      )
    }
    if(index==2){
      return (

        <div key={index}>
          <div className='adcheck-textitem-head Headgrilllanding'>Produkteigenschaften</div>
        <ul className='Descr-container-list'>
        <li>Reich an Protein </li>
        <li>Reich an ungesättigten Fettsäuren</li>
        <li>Ohne Zusatz von Geschmacksverstärkern</li>
        <li>Ohne Farbstoffe</li>
        <li>Ballaststoffquelle</li>
        <li>Zuckerarm</li>
      
      </ul>


      <div className='adcheck-textcontainer-textitem' key={index}>
      <div className='adcheck-textitem-head Headgrilllanding'>{item.head}</div>
      <div className='adcheck-textitem-text Textgrilllanding'>
            
      {item.text.split('\n').map((line,index)=>{
      
      return(
      <p key={index}>{line}</p>
      )
      })}
        
      </div> 
      </div>
      
      </div>

      )
    }
else{
    return (

      <div className='adcheck-textcontainer-textitem' key={index}>
        <div className='adcheck-textitem-head Headgrilllanding'>{item.head}</div>
        <div className='adcheck-textitem-text Textgrilllanding'>
       
        {item.text.split('\n').map((line,index)=>{
        
       return(
        <p key={index}>{line}</p>
       )
        })}
          
        </div> 
     
      </div>
    )
  }
  })
}

}


  




export default BodyLand;
