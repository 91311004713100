import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams} from "react-router";
import bgImg from '../../../assets/ads/fireplace/left/bg.jpg'
import bgCta from '../../../assets/ads/fireplace/left/cta.png'
import bgLogo from '../../../assets/ads/fireplace/left/legal.png'


function AdSide_L() {


    const {ads}= useParams()
 
  
  
    const textblock=useRef(null)
    const imgg=useRef(null)
    const ctaBtn=useRef(null)

    const text1=useRef(null)
    const text2=useRef(null)

    let animationInterval

    let animationSwitch=true

   
    const imgContainer=useRef(null)

    const checkScroll=()=>{
      const scrollPosition=window.scrollY

      if(scrollPosition>0){
        imgContainer.current.style.position="sticky"
        imgContainer.current.style.top=0
        imgContainer.current.style.height="100vh"
      }
      if(scrollPosition==0){
        imgContainer.current.style.position="relative"
        imgContainer.current.style.top=0
        imgContainer.current.style.height="93vh"
      }

    }


    const textAnimation=()=>{
      animationInterval=setInterval(()=>{
        if(animationSwitch){
         
          text2.current.style.opacity=1  
          text1.current.style.opacity=0
          animationSwitch=!animationSwitch
        }
        else{
      
          text2.current.style.opacity=0  
          text1.current.style.opacity=1
          animationSwitch=!animationSwitch
        }
      

      },4000)
    }

    window.addEventListener("scroll",checkScroll)

  useEffect(()=>{

    setTimeout(() => {
      imgg.current.style.transform="scale(1.1)"
    }, 100);

    setTimeout(() => {
      textblock.current.style.opacity=1  
    }, 1000);

    setTimeout(() => {
      ctaBtn.current.style.opacity=1  
      ctaBtn.current.style.transform="scale(1)"  
    }, 1500);

    
    setTimeout(() => {
      text2.current.style.opacity=0  
      text1.current.style.opacity=1  
      ctaBtn.current.style.transform="scale(1)"  
    }, 4000);

    setTimeout(() => {
      textAnimation()
    }, 5000);
    
  
},[])




  return (
    <>
  <div className="hpto_ad-side-autoscout" ref={imgContainer}>
  <div className='hpto_ad-side-autoscout-background'>
    <div className='hpto_ad-side-autoscout-logo-container'><img src={bgLogo}/>
   
    </div>
  </div>
  <div className='hpto_ad-side-autoscout_img-container'><img id="hpto_ad-side-autoscout_fireplace-left-img" ref={imgg} src={bgImg}/></div>

  <div className='hpto_ad-side-autoscout_text_btn-container' id="hpto_ad-side-autoscout_fireplace-text_btn-container" ref={textblock}>
    <div className='hpto_ad-side-autoscout_text' id="pto_ad-side-autoscout_text-fireplace-left" ref={text1}>Der Audi A4 Avant ist der neue <span id="hpto_ad-side-autoscout_text1">Audi A5 Avant*</span><span id="hpto_ad-side-autoscout_text2">#ThisIsAudi</span></div>
    <div className='hpto_ad-side-autoscout_text' ref={text2} id="pto_ad-side-autoscout_text-fireplace-special">Zeit, auf 5 hochzuschalten</div>

    <div className='hpto_ad-side-autoscout_cta' id="hpto_ad-side-autoscout_cta-audi-fireplace" ref={ctaBtn}><img src={bgCta}/></div>

    <div className='hpto_ad-side-autoscout_foot-text'>*Kraftstoffverbrauch (kombiniert): 7,9-4,8 l/100 km; CO₂-Emissionen (kombiniert): 180-127 g/km; CO₂-Klassen: G-D</div>
    
    </div>
  
</div>

</>
  );


 
}


  




export default AdSide_L;
