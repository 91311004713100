import React,{useEffect, useState, Component} from 'react';
import MainPic from '../assets/MainPic.png'
import DataText from '../../../data/adcheck_article/text.json'
import Ads from '../../../data/adcheck_article/ads.json'
import { useParams } from "react-router";

function Footer() {



  useEffect(()=>{


  
},[])




  return (
  <div className='adcheck-footer'>

    <div className='adcheck-footertext-container'>

        <div className='adcheck-footer-text'>
© 2024 annalect. A brand of Omnicom Media Group. All Rights reserved.
<span>Impressum</span>
        </div>

    </div>

  </div>
  );



}


  




export default Footer;
