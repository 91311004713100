import React,{useEffect, useState, Component, useRef} from 'react';
import '../../styles/style.scss'
import { register } from 'swiper/element/bundle';
import ios from '../../assets/body/icons/2/ios.svg'
import android from '../../assets/body/icons/2/android.svg'
import facebook from '../../assets/body/icons/2/facebook.svg'
import insta from '../../assets/body/icons/2/insta.svg'
import linkedin from '../../assets/body/icons/2/linkedin.svg'
import pinterest from '../../assets/body/icons/2/pinterest.svg'
import tiktok from '../../assets/body/icons/2/tiktok.svg'
import xing from '../../assets/body/icons/2/xing.svg'
import youtube from '../../assets/body/icons/2/youtube.svg'




register();


function Footer() {



useEffect(()=>{

  
},[])




  return (

<>
<div className='autoscout-footer'>
   <div className='autoscout-footer_head-text'>AutoScout24: Europaweit der größte Online-Automarkt. </div>

   <div className='autoscout-footer_item-container'>
      <div className='autoscout-footer_item'>
         <div className='autoscout-footer_item_text-head'>Unternehmen</div>
         <div className='autoscout-footer_item_text'>Über AutoScout24</div>
         <div className='autoscout-footer_item_text'>Presse</div>
         <div className='autoscout-footer_item_text'>Karriere</div>
         <div className='autoscout-footer_item_text'>Werbung</div>
         <div className='autoscout-footer_item_text'>AGB</div>
         <div className='autoscout-footer_item_text'>Datenschutz</div>
         <div className='autoscout-footer_item_text'>Impressum</div>
      </div>
      <div className='autoscout-footer_item'>
         <div className='autoscout-footer_item_text-head'>Service</div>
         <div className='autoscout-footer_item_text'>Hilfe</div>
         <div className='autoscout-footer_item_text'>Kodex</div>
         <div className='autoscout-footer_item_text'>Kontakt</div>
         <div className='autoscout-footer_item_text'>Autokatalog</div>
         <div className='autoscout-footer_item_text'>Regionalübersicht</div>
         <div className='autoscout-footer_item_text'>Service-Abo</div>
      </div>
      <div className='autoscout-footer_item'>
         <div className='autoscout-footer_item_text-head'>Händler</div>
         <div className='autoscout-footer_item_text'>Anmelden</div>
         <div className='autoscout-footer_item_text'>Registrieren</div>
         <div className='autoscout-footer_item_text'>Vorteile</div>
         <div className='autoscout-footer_item_text'>Partner-Infoprotal</div>
      </div>
      <div className='autoscout-footer_item_special'>
         <div className='autoscout-footer_item_text-head'>In Verbindung bleiben</div>
         <div className='autoscout-footer_item-container_sr4'><div className='autoscout-footer_img-container_sr4'><img src={ios}/></div><div className='autoscout-footer_text-container_sr4'>AutoScout24 für iOS </div></div>
         <div className='autoscout-footer_item-container_sr4'><div className='autoscout-footer_img-container_sr4'><img src={android}/></div><div className='autoscout-footer_text-container_sr4'>AutoScout24 für Android</div></div>
         <div className='autoscout-footer_item-container_sr4'><div className='autoscout-footer_img-container_sr5'>
         <div className='autoscout-footer_img-container_sr5_img-container'><img src={facebook}/></div>   
         <div className='autoscout-footer_img-container_sr5_img-container'><img src={youtube}/></div>   
         <div className='autoscout-footer_img-container_sr5_img-container'><img src={tiktok}/></div>   
         <div className='autoscout-footer_img-container_sr5_img-container' ><img id="inst453" src={insta}/></div>   
         <div className='autoscout-footer_img-container_sr5_img-container'><img src={linkedin}/></div>   
         <div className='autoscout-footer_img-container_sr5_img-container'><img src={xing}/></div>   
         <div className='autoscout-footer_img-container_sr5_img-container'><img src={pinterest}/></div>   
         </div>
         </div>
         
      </div>
   </div>

</div>


</>

  );


}



export default Footer;
