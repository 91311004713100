import React,{useEffect, useState, Component} from 'react';
import './styles.scss'
import { useParams } from "react-router";
import Pic from './Images/3.png'



function Footer() {




useEffect(()=>{

  
},[])




  return (
    <div className='reweka-Footer'>
      
      <div className='reweka-footer-container'>
      <div className='reweka-footer-column'>
        <div><span>Angebote</span></div>
        <div className='reweka-footer-text'>Angebote im Markt</div>
        <div className='reweka-footer-text'>Angebote im Lieferservice</div>
        <div className='reweka-footer-text'>Unsere Services</div>
        <div className='reweka-footer-text'>PAYBACK</div>
        <div className='reweka-footer-text'>Gewinnspiele</div>
        <div className='reweka-footer-text'>REWEKA Produkttests</div>
      </div>
      <div className='reweka-footer-column'>
        <div><span>Online Einkaufen</span></div>
        <div className='reweka-footer-text'>Lebensmittel online kaufen</div>
        <div className='reweka-footer-text'>So funktioniert der Lieferservice</div>
        <div className='reweka-footer-text'>Abholservice</div>
        <div className='reweka-footer-text'>Produkt-Übersicht</div>
        <div className='reweka-footer-text'>Für Geschäftskunden</div>
      </div>
      <div className='reweka-footer-column'>
        <div><span>Über Reweka</span></div>
        <div className='reweka-footer-text'>Nachhaltigkeit</div>
        <div className='reweka-footer-text'>Das Unternehmen</div>
        <div className='reweka-footer-text'>Karriere</div>
        <div className='reweka-footer-text'>Ausbildung</div>
        <div className='reweka-footer-text'>Presse</div>
        <div className='reweka-footer-text'>Kontakt</div>
      </div>
      <div className='reweka-footer-column'>
        <div><span>Hilfe & Sonstiges</span></div>
        <div className='reweka-footer-text'>Fragen und Antworten</div>
        <div className='reweka-footer-text'>Lebensmittel-Lexikon</div>
        <div className='reweka-footer-text'>Rezepte-Verzeichnis</div>
        <div className='reweka-footer-text'>Öffnungszeiten & Marktsuche</div>
        <div className='reweka-footer-text'>Was koche ich heute</div>
        <div className='reweka-footer-text'>Nutri-Score</div>
      </div>
    
     
      </div>
    </div>
  );
}

  




export default Footer;
