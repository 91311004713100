import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams} from "react-router";
import AdTop from '../ads/AdTop';
import AdSide_L from '../ads/AdSide_L';
import AdSide_R from '../ads/AdSide_R';
import Items from './Items';
import Filter from './Filter';
import AdFireplace from '../ads/AdFireplace';
import AdHpto from '../ads/hpto/AdHpto';
import AdHpto2 from '../ads/hpto/AdHpto2';
import AdTopIndex from '../ads/top/AdTopIndex';
import AdHptoQ from '../ads/hpto_q/AdHptoQ';
import AdHptoEtron from '../ads/hptoEtron/AdHptoEtron';
import AdHptoEtron2 from '../ads/hptoEtron2/AdHptoEtron2';
import AdTopIndex2 from '../ads/top2/AdTopIndex2';
import AdTopIndex3 from '../ads/top3/AdTopIndex3';
import AdTopIndex4 from '../ads/top4/AdTopIndex4';

function SubpageBody() {


    const {ads}= useParams()
 
  
  



  useEffect(()=>{


    
  
},[])




  return (
    <>
  <div className="subpage-autoscout">
    
    <AdType/>


 

</div>

</>
  );


 function AdType(){
    switch(ads){
      case "1":
        return<AdFireplace/>
    
     
        case "2": 
        return<AdHpto/>

        case "3": 
        return<AdHpto2/>

        case "4": 
        return<AdTopIndex/>

        case "5": 
        return<AdHptoQ/>

        case "6": 
        return<AdHptoEtron/>

        case "7": 
        return<AdHptoEtron2/>

        case "8": 
        return<AdTopIndex2/>

        case "9": 
        return<AdTopIndex3/>

        case "10": 
        return<AdTopIndex4/>
        
        
    }
 }

 
}


  




export default SubpageBody;
