import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams} from "react-router";
import bgImg from '../../../../assets/ads/hpto_audi_etron/2210_Audi_Q6_E-tron_3067501_320x320/img.jpg'
import bgCta from '../../../../assets/ads/hpto/hpto_a5_l/cta.png'
import bgLogo from '../../../../assets/ads/hpto/hpto_a5_l/legal.png'


function AdHptoEtron_L2() {


    const {ads}= useParams()
 
  
  
    const textblock=useRef(null)
    const imgg=useRef(null)
    const ctaBtn=useRef(null)

    const text1=useRef(null)
    const text2=useRef(null)

   
    const imgContainer=useRef(null)


  useEffect(()=>{


    setTimeout(() => {
      textblock.current.style.opacity=1  
      text1.current.style.opacity=1  
    }, 1000);

    setTimeout(() => {
      ctaBtn.current.style.opacity=1  
      ctaBtn.current.style.transform="scale(1)"  
    }, 1500);

    
    setTimeout(() => {
      
      text2.current.style.opacity=1 
     
      ctaBtn.current.style.transform="scale(1)"  
    }, 4000);
    
  
},[])




  return (
    <>
  <div className="hpto_ad_a5-side-autoscout" ref={imgContainer} style={{display:'flex', justifyContent:'end'}}>
  <div>
    <div className='hpto_ad_a5-side-autoscout-logo-container'><img src={bgLogo}/> </div>
    
   
    
  </div>
  <div className='hpto_ad_etron-side-autoscout_img-container E49783'><img ref={imgg} src={bgImg}/></div>

  <div className='hpto_ad_etron_l_hpto-side-autoscout_text_btn-container E7676' id="hpto_ad-side-autoscout_fireplace-text_btn-containerE3322" ref={textblock}>
    <div className='hpto_ad_etron-side-autoscout_text' id="pto_ad-side-autoscout_text-fireplace-left" ref={text1} style={{opacity:0}}>Fangen Sie an, Kraft zu tanken</div>
    <div className='hpto_ad_etron-side-autoscout_text3' ref={text2} style={{opacity:0}}>Der neue Audi Q6 e-tron mit bis zu 270 kW High-Power-Charging an Schnellladesäulen.</div>

    <div className='hpto_ad_a5-side-autoscout_cta' id="hpto_ad-side-autoscout_cta-audi-fireplace" ref={ctaBtn}><img src={bgCta}/></div>

   
    
    </div>
  
</div>

</>
  );


 
}


  




export default AdHptoEtron_L2;
