import {React, useEffect, useState, Component, useRef, Fragment} from "react";
import '../styles.scss'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import Dummy from '../assets/dummy.png'
import RecommendIcon from '@mui/icons-material/Recommend';
import Data from '../../../../data/facebook/data.json'
import {useParams, useSearchParams  } from 'react-router-dom';
import DataAds from '../../../../data/facebook/ads.json'

function FaceBookInFeed(){

   
    const {fbad}=useParams()

    const [searchParams] = useSearchParams();
    const fbad2 = searchParams.get('ad');

    



    function PlayVideos(){
        const content=document.querySelectorAll('.facebook_content_infeed-videoad')
        let prevVideoContent=null

        const callback=function(entries, observer){ 
                    
            entries.forEach(entry=>{
             
              let {target}=entry
        
             
              prevVideoContent=target
             
              if(prevVideoContent!=null){
                
                prevVideoContent.pause()
              }
              
              if(entry.isIntersecting && entry.intersectionRatio>=0.5){
               
                
                target.play()
                //contentTimeUpdate(target)
         
                //target.currentTime=0
              
      
              
            
                
              }
              
            })
           
          }

          
        const options={

            threshold: 0.6,
           
            }

        const observer=new IntersectionObserver(callback, options)
        
        content.forEach((item)=>{
            item.play()
            item.pause()
            observer.observe(item)
            //item.addEventListener('waiting',showLoader)
            //item.addEventListener('canplay',hideLoader)
        })
    } 

useEffect(()=>{
    document.body.style.overflow = 'hidden';
},[])

const launch=()=>{
      
    document.getElementById("tiktok-playContent-Dialog").style.display="none"
  document.body.style.overflow = 'auto';
  PlayVideos()
}


    return(
        <div className="facebook_content">
  <div className="tiktok-playContent-dialog" id="tiktok-playContent-Dialog">
      <div className="tiktok-playContent_btn-text">Klicken Sie auf "Weiter"</div>
      <div className="tiktok-playContent_btn-container"><div className="tiktok-playContent_btn" onClick={launch}>Weiter</div></div>
    </div>
        <Content/>
     
        </div>
    )


    function Content(){
        return Data.data.map((item, index)=>{

            if(index==2){
                return(
                    <div className="facebook_content_container" key={index}>

<div className="facebook_content_head">
        <div className="facebook-content_head-post_img"><img src={DataAds.ads[fbad].icons}/></div>
        <div className="facebook-content_head-post-name_container">
        <div className="facebook-content_head-post-name">{DataAds.ads[fbad].acc_name}</div>
        <div className="facebook-content_head-post-publ">{DataAds.ads[fbad].date} <SettingsIcon sx={{fontSize:'13px', marginLeft:'10%'}}/></div>
        </div>
        <div className="facebook-content_head-post-func_container">
        <div className="facebook-content_head-post-more"><MoreHorizIcon sx={{color:'#65686D'}}/></div>
        <div className="facebook-content_head-post-close"><CloseIcon  sx={{color:'#65686D'}}/></div>
        </div>
        </div>

        <div className="facebook_content_text_container">
            <div className="facebook_content_text_vert-line"></div>
            <div className="facebook_content_text">{DataAds.ads[fbad].text}</div>
        </div>
        <div className="facebook_content_img">
        <video className="facebook_content_infeed-videoad"  src={DataAds.ads[fbad].ad_url} playsInline loop/>
        </div>

        <div className="facebook_content_foot_container">
        <div className="facebook_content_foot_info">
            <div className="facebook_content_foot_info_likes"><RecommendIcon sx={{color:'#1963F6', fontSize:'19px'}}/>{DataAds.ads[fbad].likes}</div>
            <div className="facebook_content_foot_info_rep-com">
                <div className="facebook_content_foot_info_com">{DataAds.ads[fbad].com} Kommentar</div>
                <div className="facebook_content_foot_info_rep">Reposts: {DataAds.ads[fbad].rep}</div>
            </div>
        </div>

        <div className="facebook_content_foot_btns">
        <div className="facebook_content_foot_btn" style={{marginLeft:'1%'}}><div className="facebook_content_foot_btn-like"></div><div>Gefällt mir</div></div>
        <div className="facebook_content_foot_btn"><div className="facebook_content_foot_btn-com"></div><div>Kommentieren</div></div>
        <div className="facebook_content_foot_btn"><div className="facebook_content_foot_btn-send"></div><div>Senden</div></div>
        <div className="facebook_content_foot_btn" style={{marginRight:'3%'}}><div className="facebook_content_foot_btn-share"></div><div>Teilen</div></div>
     
        </div>

        </div>

                    </div>
                )
            }
            if(index==6){
                return(
                    <div className="facebook_content_container" key={index}>

<div className="facebook_content_head">
        <div className="facebook-content_head-post_img"><img src={DataAds.ads[fbad2].icons}/></div>
        <div className="facebook-content_head-post-name_container">
        <div className="facebook-content_head-post-name">{DataAds.ads[fbad2].acc_name}</div>
        <div className="facebook-content_head-post-publ">{DataAds.ads[fbad2].date} <SettingsIcon sx={{fontSize:'13px', marginLeft:'10%'}}/></div>
        </div>
        <div className="facebook-content_head-post-func_container">
        <div className="facebook-content_head-post-more"><MoreHorizIcon sx={{color:'#65686D'}}/></div>
        <div className="facebook-content_head-post-close"><CloseIcon  sx={{color:'#65686D'}}/></div>
        </div>
        </div>

        <div className="facebook_content_text_container">
            <div className="facebook_content_text_vert-line"></div>
            <div className="facebook_content_text">{DataAds.ads[fbad2].text}</div>
        </div>
        <div className="facebook_content_img">
        <video className="facebook_content_infeed-videoad"  src={DataAds.ads[fbad2].ad_url} playsInline loop/>
        </div>

        <div className="facebook_content_foot_container">
        <div className="facebook_content_foot_info">
            <div className="facebook_content_foot_info_likes"><RecommendIcon sx={{color:'#1963F6', fontSize:'19px'}}/>{DataAds.ads[fbad2].likes}</div>
            <div className="facebook_content_foot_info_rep-com">
                <div className="facebook_content_foot_info_com">{DataAds.ads[fbad2].com} Kommentar</div>
                <div className="facebook_content_foot_info_rep">Reposts: {DataAds.ads[fbad2].rep}</div>
            </div>
        </div>

        <div className="facebook_content_foot_btns">
        <div className="facebook_content_foot_btn" style={{marginLeft:'1%'}}><div className="facebook_content_foot_btn-like"></div><div>Gefällt mir</div></div>
        <div className="facebook_content_foot_btn"><div className="facebook_content_foot_btn-com"></div><div>Kommentieren</div></div>
        <div className="facebook_content_foot_btn"><div className="facebook_content_foot_btn-send"></div><div>Senden</div></div>
        <div className="facebook_content_foot_btn" style={{marginRight:'3%'}}><div className="facebook_content_foot_btn-share"></div><div>Teilen</div></div>
     
        </div>

        </div>

                    </div>
                )
            }
else{
            return(
                <div className="facebook_content_container" key={index}>

        <div className="facebook_content_head">
        <div className="facebook-content_head-post_img"><img src={item.icons}/></div>
        <div className="facebook-content_head-post-name_container">
        <div className="facebook-content_head-post-name">{item.acc_name}</div>
        <div className="facebook-content_head-post-publ">{item.date} <SettingsIcon sx={{fontSize:'13px', marginLeft:'10%'}}/></div>
        </div>
        <div className="facebook-content_head-post-func_container">
        <div className="facebook-content_head-post-more"><MoreHorizIcon sx={{color:'#65686D'}}/></div>
        <div className="facebook-content_head-post-close"><CloseIcon  sx={{color:'#65686D'}}/></div>
        </div>
        </div>

        <div className="facebook_content_text_container">
            <div className="facebook_content_text_vert-line"></div>
            <div className="facebook_content_text">{item.text}</div>
        </div>
        <div className="facebook_content_img">
        <img src={item.post_img_url}/>
        </div>

        <div className="facebook_content_foot_container">
        <div className="facebook_content_foot_info">
            <div className="facebook_content_foot_info_likes"><RecommendIcon sx={{color:'#1963F6', fontSize:'19px'}}/>{item.likes}</div>
            <div className="facebook_content_foot_info_rep-com">
                <div className="facebook_content_foot_info_com">{item.com} Kommentar</div>
                <div className="facebook_content_foot_info_rep">Reposts: {item.rep}</div>
            </div>
        </div>

        <div className="facebook_content_foot_btns">
        <div className="facebook_content_foot_btn" style={{marginLeft:'1%'}}><div className="facebook_content_foot_btn-like"></div><div>Gefällt mir</div></div>
        <div className="facebook_content_foot_btn"><div className="facebook_content_foot_btn-com"></div><div>Kommentieren</div></div>
        <div className="facebook_content_foot_btn"><div className="facebook_content_foot_btn-send"></div><div>Senden</div></div>
        <div className="facebook_content_foot_btn" style={{marginRight:'3%'}}><div className="facebook_content_foot_btn-share"></div><div>Teilen</div></div>
     
        </div>

        </div>

        </div>
            )
        }
        })
    }

}

export default FaceBookInFeed;