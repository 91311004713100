import React,{useEffect, useState, Component} from 'react';
import Category from './Category';
import Products from './Products';
import Sortiment from './Sortiment';
import './BodyStyle/styles.scss'
import { PropaneSharp } from '@mui/icons-material';
import Dialog from './Dialog'



function Body1() {

  let urlParam=document.location.search;
  let searchParams=new URLSearchParams(urlParam)
  let spon=searchParams.get("spon")
  let mod=searchParams.get("mod")
  let ontouch=false
  let ontouchmove=false
  let prods

  useEffect(()=>{

    let hasTouch='ontouchstart' in window

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
    
    function createSponsor(){
      document.getElementById('1').style.display="block"
      document.getElementById('prod1').style.border="2px solid black"
      document.getElementById('2').style.display="block"
      document.getElementById('prod2').style.border="2px solid black"
      document.getElementById('4').style.display="block"
      document.getElementById('prod4').style.border="2px solid black"
      document.getElementById('5').style.display="block"
      document.getElementById('prod5').style.border="2px solid black"
      document.getElementById('6').style.display="block"
      document.getElementById('prod6').style.border="2px solid black"
      document.getElementById('8').style.display="block"  
      document.getElementById('prod8').style.border="2px solid black"
    }

    if(spon==1){
      createSponsor()
    }
    else{

    }

   prods=document.querySelectorAll('.Buy')

if(hasTouch==true){
   window.addEventListener('touchstart', function() {
      
    prods.forEach(item=>{
      item.style.pointerEvents="none"
    item.setAttribute("data-proceed-questionnaire","false")
   
     
  
    })
    
    
  });
}

  /* 
  window.addEventListener('wheel', function() {
    
    prods.forEach(item=>{
      item.style.pointerEvents="all"
    item.setAttribute("data-proceed-questionnaire","true")
     
  
    })
    
  });
  */

  if(hasTouch==false){

  window.addEventListener('mousemove', function() {
    
    prods.forEach(item=>{
      item.style.pointerEvents="all"
    item.setAttribute("data-proceed-questionnaire","true")
     
  
    })

  
});


window.addEventListener('mousedown', function() {
    
  prods.forEach(item=>{
    item.style.pointerEvents="all"
  item.setAttribute("data-proceed-questionnaire","true")
   

  })


});

  }




   if(document.documentElement.clientWidth>480 && hasTouch==true){

    prods.forEach(item=>{
      item.style.pointerEvents="none"
      item.setAttribute("data-proceed-questionnaire","false")
      
  
    })
   }
   else if(document.documentElement.clientWidth>480 && hasTouch==false){
    prods.forEach(item=>{
    //  item.style.pointerEvents="all"
     // item.setAttribute("data-proceed-questionnaire","true")
     
  
    })
   }
  

    window.addEventListener('scroll',function(){
     rejectForward()
      prods.forEach(item=>{
        item.setAttribute("data-proceed-questionnaire","false")
        item.style.pointerEvents="none"

      })
  
    })

    scrollEnd(() => {
      
      prods.forEach(item=>{

        if(document.documentElement.clientWidth>480 && hasTouch==true){
          item.style.pointerEvents="none"
          item.setAttribute("data-proceed-questionnaire","false")
         
        }
        else if(document.documentElement.clientWidth>480 && hasTouch==false){
          //item.style.pointerEvents="all"
         // item.setAttribute("data-proceed-questionnaire","true")
       
        }
        else{
      // item.style.pointerEvents="none"
       // item.setAttribute("data-proceed-questionnaire","false")
        }

       
      })
      
    }, 300);

    
   




  
},[])





function scrollEnd(callback, timeout) {
  let timer = null;
  window.addEventListener('scroll', function() {
    if(timer) {
     
      clearTimeout(timer);
    }

 
    timer = setTimeout(function() {
     
      callback();
      timer = null;
    
      if(ontouch){
        ontouchmove=true
      }
      else{
        ontouchmove=false
      }

    }, timeout);
  });
}



function On(event){
  ontouch=true
  prods.forEach(item=>{
    item.style.pointerEvents="none"
    item.setAttribute("data-proceed-questionnaire","false")
  })

}

function Move(){
  ontouchmove=true
 
  prods.forEach(item=>{
    item.style.pointerEvents="none"
    item.setAttribute("data-proceed-questionnaire","false")
  })
}

function End(event){
 
  if(!ontouchmove){
 
    prods.forEach(item=>{
      item.style.pointerEvents="all"
      item.setAttribute("data-proceed-questionnaire","true")
     
      setTimeout(()=>{
        item.style.pointerEvents="none"
        item.setAttribute("data-proceed-questionnaire","false")
      },500)
    })
   
  }

  ontouchmove=false
  ontouch=false
  
}

function rejectForward(){
  if(ontouch && ontouchmove){
    

  }
}


  return (
    <div className="Main" id="main">
     
   
    
    
    <div className="MainBody">
      <Sortiment/>
      <Dialog/>
     <div className='ProductsandCategory' id="prodandcat" >
     <Category/>
     <div className="Products" id="products" onTouchStart={On} onTouchMove={Move} onTouchEnd={End}>
       
     <Products/>
      
      </div>
      
     </div>


     


</div>
</div>
  );
}


  




export default Body1;
