import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams} from "react-router";
import bgImg from '../../../../assets/ads/hpto/hpto_a5_l/bg.jpg'
import bgCta from '../../../../assets/ads/hpto/hpto_a5_l/cta.png'
import Logo from '../../../../assets/ads/hpto_audi_q/c1.png'
import test from '../../../../assets/ads/hpto_audi_q/c2.png'


function AdBg_R() {


    const {ads}= useParams()
 

    const text1=useRef(null)
    const text2=useRef(null)

   
    


  useEffect(()=>{

 
    setTimeout(() => {
      text1.current.style.opacity=1  
    }, 1000);

    setTimeout(() => {
      text2.current.style.opacity=1  
    }, 3000);

  
},[])




  return (
    <>
  <div className="hpto_ad_AudiQ-side-autoscout">
  <div className='hpto_ad_AudiQ-side-autoscout-div_container'>
  <div className='hpto_ad-AudiQlogo-container'><img src={Logo}/></div>

  

  
  
 
  <div className='hpto_ad-AudiQ_logotext-container'>
  
  <div className='hpto_ad-AudiQlogo-AudiQ_q232_r' ref={text1} style={{opacity:0}}>Der Audi Q3 und weitere Q Modelle* zu attraktiven Konditionen.</div>

  
  </div>

    <div className='hpto_ad-AudiQlogo-AudiQ_cond' ref={text2} style={{opacity:0}}>
    Aktion gültig bis 30.06.2024 für Neufahrzeuge der Modellreihen Audi Q2, Audi Q3, Audi Q5, Audi Q7 und Audi Q8. Ausgeschlossen sind RS-Modelle und vollelektrische Modelle.
    </div>
    {/*<div className='hpto_ad_AudiQ-side-autoscout_foot-text'><span>Audi</span> Vorsprung durch Technik</div>*/}
    
   
    </div>
  
</div>

</>
  );


 
}


  




export default AdBg_R;
