import {useEffect, useState, Component, useRef} from 'react';
import {Link} from "react-router-dom";
import '../../styles/style.scss'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useParams} from "react-router";


function Filter() {

  const [ad, setAd]=useState("")
  const [btndis, setBtndis]=useState(true)
  const [count, setCount]=useState(0)
  const refAd = useRef(null);

  const [marke, setMarke]=useState("")
  const [modell, setModell]=useState("")
  const [listNum, setListNum]=useState(0)

  const {ads}= useParams()

  const categories=[
    {
      marke:'Audi',
      modell:['Beliebig','100','200','80','90','A1','A2','A3','A4','Coupé']
    },
    {
      marke:'BMW',
      modell:['Beliebig','114','116','118','120','123','125','128','130',]
    },
    {
      marke:'Mercedes-Benz',
      modell:['Beliebig','190','200','220','230','240','250','260','270',]
    },
    {
      marke:'Volkswagen',
      modell:['Beliebig','181','Amarok','Arteon','Beetle','Bora','Buggy','Caddy','CC',]
    },

    
  ]
  const categories2=[
  
    {
      marke:'Nissan',
      modell:['Beliebig','Almera','Armada','Cargo','Cube','Bluebird','370Z','Cabstar','100 NX',]
    },
    {
      marke:'Toyota',
      modell:['Beliebig','4-Runner','Alphard','Auris','Avalon','Camry','Carina','Corolla','Crown',]
    },
    {
      marke:'Suzuki',
      modell:['Beliebig','Across','Alto','Baleno','Carry','Grand Vitara','Ignis','Jimmy','SJ Samurai',]
    },
    {
      marke:'Ford',
      modell:['Beliebig','Aerostar','B-Max','Bronco','Capri','C-Max','Edge','Escort','Explorer',]
    },
    
  ]


  useEffect(()=>{
  
    if(ad!=""){
      setBtndis(false)
    
     const Number=Math.round(generateNumber(200000-(listNum*10),300000-(listNum*100)))

     setCount(Number)
        const IntervalCount=setInterval(() => {
         
          if(Number<count){
            setCount(prevCount => {
              const newCount = prevCount - 14;
             
              if(newCount<=Number){
               clearInterval(IntervalCount)
              }
             return newCount;
           });
          }
       
          else{
            setCount(prevCount => {
              const newCount = prevCount + 14;
             
              if(newCount>=Number){
               clearInterval(IntervalCount)
              }
             return newCount;
           });
          }
       
      }, 1);
      
    }
      
  
},[ad,listNum])


function generateNumber(min, max){
  return Math.random()*(max-min)+min
}


const handleSelectMarke = (e) =>{
   
  const selectedMarke=e.target.value
  setAd(e.target.value)
  setMarke(selectedMarke)
  setModell('')

  
}

const handleSelectModel = (e) =>{
  
  const selectedModel=e.target.value
  setModell(selectedModel)
  setListNum(listNum+1)
  

}

const handleSelectYear = (e) =>{
  
  
  setListNum(listNum+1)

}

const handleSelectKm = (e) =>{
 
  
  setListNum(listNum+1)

}

const handleSelectPrice = (e) =>{
  
  
  setListNum(listNum+1)

}



  return (
    <div className='filter-autoscout-container'>
    <div className='filter-autoscout-category'>
    <div className='filter-autoscout-category-item autoscout-picked'><svg viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg"><path d="M12.5 0c.7 0 1.3.4 1.7 1l2.4 4H18c2.2 0 4 1.8 4 4v3c0 1.1-.9 2-2 2h-1.2c-.4 1.2-1.5 2-2.8 2-1.3 0-2.4-.8-2.8-2H8.8c-.4 1.2-1.5 2-2.8 2-1.3 0-2.4-.8-2.8-2H2c-1.1 0-2-.9-2-2V6.3c0-.3.1-.7.3-1L2.9 1c.3-.6 1-1 1.7-1ZM6 12c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1Zm10 0c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1ZM12.5 2H4.6L2.8 5H5c.6 0 1 .4 1 1s-.4 1-1 1H2v5h1.2c.4-1.2 1.5-2 2.8-2 1.3 0 2.4.8 2.8 2h4.4c.4-1.2 1.5-2 2.8-2 1.3 0 2.4.8 2.8 2h1.3V9c0-1.1-.9-2-2-2h-2c-.4 0-.7-.2-.9-.5L12.5 2ZM11 4c.6 0 1 .4 1 1v1c0 .6-.4 1-1 1s-1-.4-1-1V5c0-.6.4-1 1-1Z" fill="#333" fillRule="nonzero"></path></svg></div>
    <div className='filter-autoscout-category-item'><svg viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg"><path d="M15 0c.5 0 .9.3 1 .8L17.8 8h.2c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4c0-1.4.8-2.7 1.9-3.4l-.4-1.7-4.8 4.8c-.2.2-.4.3-.7.3h-.2c-.3-.1-.6-.4-.7-.7L7.3 6H1l-.125-.006C.345 5.942 0 5.557 0 5c0-.6.4-1 1-1h13.7l-.5-2H13l-.125-.006C12.345 1.942 12 1.557 12 1c0-.6.4-1 1-1ZM4 8l.199.005A4.013 4.013 0 0 1 8 12c0 2.2-1.8 4-4 4s-4-1.8-4-4 1.8-4 4-4Zm14 2-.149.006A2.007 2.007 0 0 0 16 12c0 1.1.9 2 2 2l.149-.006A2.007 2.007 0 0 0 20 12c0-1.1-.9-2-2-2ZM4 10l-.149.006A2.007 2.007 0 0 0 2 12c0 1.1.9 2 2 2l.149-.006A2.007 2.007 0 0 0 6 12c0-1.1-.9-2-2-2Zm9.6-4H9.4l1 3.1L13.6 6ZM19 3l.125.006c.53.052.875.437.875.994v1c0 .6-.4 1-1 1s-1-.4-1-1V4c0-.6.4-1 1-1ZM8 1c.6 0 1 .4 1 1s-.4 1-1 1H4c-.6 0-1-.4-1-1s.4-1 1-1Z" fill="#333" fillRule="nonzero"></path></svg></div>
    <div className='filter-autoscout-category-item'><svg viewBox="0 0 22 19" xmlns="http://www.w3.org/2000/svg"><path d="M18 0c2.2 0 4 1.8 4 4v1c0 .4-.2.8-.6.9.4.6.6 1.3.6 2.1v7c0 1.1-.9 2-2 2h-1.2c-.4 1.2-1.5 2-2.8 2-1.3 0-2.4-.8-2.8-2H8.8c-.4 1.2-1.5 2-2.8 2-1.3 0-2.4-.8-2.8-2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0ZM6 15c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1Zm10 0c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1ZM9 2H2v13h1.2c.4-1.2 1.5-2 2.8-2 1.3 0 2.4.8 2.8 2h4.4c.4-1.2 1.5-2 2.8-2 1.3 0 2.4.8 2.8 2H20v-4h-6c-.6 0-1-.4-1-1V5c0-.6.4-1 1-1h6c0-1.1-.9-2-2-2h-7v8c0 .6-.4 1-1 1s-1-.4-1-1V2ZM7 8c.6 0 1 .4 1 1v1c0 .6-.4 1-1 1s-1-.4-1-1V9c0-.6.4-1 1-1Zm11-2h-3v3h5V8c0-1.1-.9-2-2-2Z" fill="#333" fillRule="nonzero"></path></svg></div>
    <div className='filter-autoscout-category-item'><svg viewBox="0 0 22 19" xmlns="http://www.w3.org/2000/svg"><path d="M13 0c1.1 0 2 .9 2 2h3c2.2 0 4 1.8 4 4v9c0 1.1-.9 2-2 2h-1.2c-.4 1.2-1.5 2-2.8 2-1.3 0-2.4-.8-2.8-2H8.8c-.4 1.2-1.5 2-2.8 2-1.3 0-2.4-.8-2.8-2H2l-.149-.006A2.007 2.007 0 0 1 0 15V2C0 .9.9 0 2 0ZM6 15l-.125.006c-.53.052-.875.437-.875.994 0 .6.4 1 1 1l.125-.006c.53-.052.875-.437.875-.994 0-.6-.4-1-1-1Zm10 0-.125.006c-.53.052-.875.437-.875.994 0 .6.4 1 1 1l.125-.006c.53-.052.875-.437.875-.994 0-.6-.4-1-1-1ZM6 13c1.3 0 2.4.8 2.8 2h4.4c.4-1.2 1.5-2 2.8-2 1.3 0 2.4.8 2.8 2H20v-4H2v4h1.2c.4-1.2 1.5-2 2.8-2Zm12-9h-3v5h5V6c0-1.1-.9-2-2-2Zm-5-2H2v7h11V2Z" fill="#333" fillRule="nonzero"></path></svg></div>
    <div className='filter-autoscout-category-item'><svg viewBox="0 0 20 14" xmlns="http://www.w3.org/2000/svg"><path d="M19 10h-3V2c0-1.103-.897-2-2-2H2C.897 0 0 .897 0 2v8c0 1.103.897 2 2 2h1.184A2.996 2.996 0 0 0 6 14a2.996 2.996 0 0 0 2.816-2H19a1 1 0 1 0 0-2ZM2 2h12v8H8.816A2.996 2.996 0 0 0 6 8a2.996 2.996 0 0 0-2.816 2H2V2Zm4 10a1.001 1.001 0 0 1 0-2 1.001 1.001 0 0 1 0 2Z" fill="#333" fillRule="nonzero"></path></svg></div>
  </div>
   <div className='filter-autoscout'>
   

  <div className='filter-autoscout-category2'>
    <div className='filter-autoscout-category-item2 autoscout-item2-picked'>Kaufen</div>
    <div className='filter-autoscout-category-item2'>Leasing</div>
   
  </div>

  <div className='filter-autoscout-dropdown'>


    <div className='filter-autoscout-dropdown-container'>
   <select className='filter-autoscout-select' onChange={handleSelectMarke}>
          <option value="Beliebig">Alle Marken</option>
          <optgroup label="Top-Marken">
      {categories.map((item)=>(
        <option key={item.marke} value={item.marke}>
          {item.marke}
        </option>
      ))}
    
    </optgroup>

    <optgroup label="Andere Marken">
    {categories2.map((item)=>(
        <option key={item.marke} value={item.marke}>
          {item.marke}
        </option>
      ))}
    </optgroup>
          </select>
    </div>


    <div className='filter-autoscout-dropdown-container'>
    <select className='filter-autoscout-select' onChange={handleSelectModel}>
    <option value="Beliebig">Alle Modelle</option>
       {categories.find(item=>item.marke===marke)
    ?.modell.map((item)=>(
     <option key={item} value={item}>
       {item}
     </option>
      
    ))}
       {categories2.find(item=>item.marke===marke)
    ?.modell.map((item)=>(
     <option key={item} value={item}>
       {item}
     </option>
   
    ))}
       
         </select>
    </div>


    <div className='filter-autoscout-dropdown-container'>

    <select className='filter-autoscout-select' onChange={handleSelectPrice}>
        <option>Preis bis (€)</option>
        <option>500 €</option>
        <option>1.000 €</option>
        <option>1.500 €</option>
        <option>2.000 €</option>
        <option>2.500 €</option>
        <option>3.000 €</option>
        <option>3.500 €</option>
        <option>4.000 €</option>
          </select>

    </div>

    <div className='filter-autoscout-dropdown-container'>

      <select className='filter-autoscout-select' onChange={handleSelectYear}>
      <option>Erstzulassung ab</option>
          <option>2023</option>
          <option>2022</option>
          <option>2021</option>
          <option>2020</option>
          <option>2019</option>
          <option>2018</option>
          <option>2017</option>
          <option>2016</option>
      </select>

    </div>
    
    <div className='filter-autoscout-dropdown-container-textfield'>
      <input className='filter-autoscout-select-textfield' type="text" placeholder='Stadt/PLZ'></input>
     
    </div>
    <Link className='filter-autoscout_cta-link' to={"items/"} style={{textDecoration:'none'}} > <button disabled={btndis} className='filter-autoscout-dropdown-searchbtn'>{count} Treffer</button></Link>
  </div>

  <div className='filter-autoscout-dropdown-checkbox-container'>
    <div className='filter-autoscout-dropdown-checkbox-container-section'>
  <div className='filter-autoscout-dropdown-div-contianer'><FormControlLabel control={<Checkbox />} label="Onlinekauf & Lieferung"  sx={{ '& .MuiFormControlLabel-label': { fontSize: '16px' } }}/></div>
  <div className='filter-autoscout-dropdown-div-contianer _detail-search'>Weitere Suchoptionen</div>
  </div>
  </div>

   </div>

   </div>
  );
}


  


export default Filter;
