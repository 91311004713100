import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams} from "react-router";
import AdTopAd3 from './AdTopAd3';
import Items from '../../subpage/Items';
import Filter from '../../subpage/Filter';



function AdTopIndex3() {



 
  
  



  useEffect(()=>{


    
  
},[])




  return (
    <div className='subpage-autoscout_ad_content-container'>
  
    <div className="subpage-autoscout_content-container">
    <AdTopAd3/>
    <div className='subpage-autoscout_container'>
     <div className='subpage-autoscout_filter_btn_container'> 
      <div className='subpage-autoscout_filter-btn_container'><div className='subpage-autoscout_filter-btn'>Suche speichern</div></div>
    <Filter/>
    </div>
  
    <div>
    <Items/>
    </div>
    </div>
    </div>
  
    
     
      </div>
  );


 
}


  




export default AdTopIndex3;
