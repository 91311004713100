import React,{useEffect, useState, Component} from 'react';
import Head from '../Head/Head'
import Close from '../../Images/img/close.svg'
import '../BodyStyle/styles.scss'

function SidePanel() {


  useEffect(()=>{

  
  
},[])

const divsToClone = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
const divsToClone2 = ["Regensensor", "Bluetooth", "CD-Spieler", "Elektr. Sitzeinstellung", "Elektr. Seitenspiegel", "Sitzheizung", "Elektr. Fensterheber", "Freisprecheinrichtung", "Head-Up Display", "Isofix", "Multifunktionslenkrad", "Navigationssystem", "Bordcomputer", "Servolenkung", "Skisack", "Start/Stopp-Automatik", "Schiebedach"];


  return (
    <div className="MobileCarSidePanel">
     <div style={{color:'#e04b00', margin:'20px', fontWeight:'bold', fontSize:'14px'}}>Detailsuche</div>
      <hr className='Line'/>

    <div className='SideMenu1'>
    <div className='NewLabel'><span>NEU</span> mobile.de Online-Kauf</div>
    <ul>
      <li>1 Jahr Garantie</li>
      <li>Kostenlose Lieferung</li>
      <li>21 Tage Rückgaberecht</li>
    </ul>

    <div className='SideMenu1Btn'>7.142 Angebote</div>
   
    </div>
   

    <div className='SideMenu2'>
    
    <div className='SideMenu2Head'>Fahrzeugzustand</div>
    <div className='SideMenu2Text'>Beliebig <span>ändern</span></div>
    <hr className='Line'/>
    <div className='SideMenu2Head'>Marke, Modell, Variante</div>
    <div className='SideMenu2Text'>Beliebig <span>ändern</span></div>
    <hr className='Line'/>
 
    </div>



    <div className='SideMenu1'>
    <div className='NewLabel'><span>NEU</span>Zahlungsart</div>
    
    <div className='SelectorDiv'> <div className='Selector Special'><div className='BuyLeasing' id="buy">Kaufen</div><div className='BuyLeasing' id="lease">Leasing</div></div></div>
    <div className='SideMenu1SelectorDiv'><div className='SideMenu1SelectorDivLabel'>Preis</div><div className='SideMenu1Selectors'><select className='SideMenu1Selector'><option>von</option></select><select className='SideMenu1Selector'><option>bis</option></select></div></div>

    <hr className='Line' style={{marginTop:'30px'}}/>

    <div className='SideMenu1SelectorDiv'><div className='SideMenu1SelectorDivLabel'>Erstzulassung</div><div className='SideMenu1Selectors'><select className='SideMenu1Selector'><option>von</option></select><select className='SideMenu1Selector'><option>bis</option></select></div></div>
    <div className='SideMenu1SelectorDiv'><div className='SideMenu1SelectorDivLabel'>Kilometerstand</div><div className='SideMenu1Selectors'><select className='SideMenu1Selector'><option>von</option></select><select className='SideMenu1Selector'><option>bis</option></select></div></div>


    <div className='SideMenu1Btn2'><svg width="16" height="16" viewBox="0 0 24 24" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M16.215 18.336a9 9 0 112.121-2.121L23.121 21 21 23.121l-4.785-4.785zM11 17.25a6.25 6.25 0 100-12.5 6.25 6.25 0 000 12.5z" fill="currentColor"></path></svg>7.142 Angebote</div>
    <div className='SuchSP'>Suche speichern</div>

    </div>

    <hr className='Line' style={{marginTop:'30px'}}/>
    <div className='SideMenu2Head'>Standort</div>
    <div className='SideMenu2Text'>Beliebig <span>ändern</span></div>
    <hr className='Line'/>

    <div className='SideMenu2Head'>Kraftstoffart</div>
    <div className='SideMenu2Text'>Beliebig <span>ändern</span></div>

    <div className='SideMenu2Head'>Leistung</div>
    <div className='SideMenu2Text'>Beliebig <span>ändern</span></div>

    <div className='SideMenu2Head'>Fahrzeugtyp</div>
    <div className='SideMenu2Text'>Beliebig <span>ändern</span></div>

    <div className='SideMenu2Head'>E-Autos</div>
    <div className='SideMenu2Text'>Beliebig <span>ändern</span></div>

    <div className='SideMenu2Head'>Getriebe</div>
    <div className='SideMenu2Text'>Beliebig <span>ändern</span></div>

    <hr className='Line'/>

    <div className='SideMenu2Head'>Farbe</div>

    <div className='Colors'>
      {divsToClone.map((item,index)=>{
        return(
        <div className={`Color ${'col'+index}`} key={index}></div>
        )
      })}
    
   
    </div>

    <hr className='Line' style={{marginTop:'30px'}}/>

    <div className='SideMenu2Head'>Qualitätssiegel</div>
    <label><input type='checkbox' className='CheckBox'/><span className='CheckBoxLabel'>Inserate mit Qualitätssiegel</span></label>

    <hr className='Line' style={{marginTop:'30px'}}/>

    <div className='SideMenu2Head'>Klimatisierung</div>
    <div className='SideMenuRadio'><label><input type='radio' className='CheckBox'/><span className='CheckBoxLabel'>Beliebig</span></label></div>
    <div className='SideMenuRadio'> <label><input type='radio' className='CheckBox'/><span className='CheckBoxLabel'>Keine Klimaanlage </span></label></div>
    <div className='SideMenuRadio'><label><input type='radio' className='CheckBox'/><span className='CheckBoxLabel'>Klimaanlage oder -automatik </span></label></div>
    <div className='SideMenuRadio'> <label><input type='radio' className='CheckBox'/><span className='CheckBoxLabel'>Klimaautomatik </span></label></div>
    <div className='SideMenuRadio'> <label><input type='radio' className='CheckBox'/><span className='CheckBoxLabel'>2-Zonen-Klimaautomatik </span></label></div>
    <div className='SideMenuRadio'> <label><input type='radio' className='CheckBox'/><span className='CheckBoxLabel'>3-Zonen-Klimaautomatik </span></label></div>
    <div className='SideMenuRadio'> <label><input type='radio' className='CheckBox'/><span className='CheckBoxLabel'>4-Zonen-Klimaautomatik </span></label></div>


    <hr className='Line' style={{marginTop:'30px'}}/>


    <div className='SideMenu2Head'>Ausstattung</div>
{divsToClone2.map((item,index)=>{
        return(
       <div className='SideMenu2Check'><label><input type='checkbox' className='CheckBox'/><span className='CheckBoxLabel'>{item}</span></label></div>
        )
      })}

<hr className='Line' style={{marginTop:'30px'}}/>

<div className='SideMenu2Head'>Anbieter</div>
    <div className='SideMenuRadio'><label><input type='radio' className='CheckBox'/><span className='CheckBoxLabel'>Beliebig</span></label></div>
    <div className='SideMenuRadio'> <label><input type='radio' className='CheckBox'/><span className='CheckBoxLabel'>Händler </span></label></div>
    <div className='SideMenuRadio'><label><input type='radio' className='CheckBox'/><span className='CheckBoxLabel'>Privatanbieter </span></label></div>
    <div className='SideMenuRadio'><label><input type='radio' className='CheckBox'/><span className='CheckBoxLabel'>Firmenfahrzeuge </span></label></div>


    <div style={{color:'#e04b00', marginTop:'50px', marginLeft:'20px', fontWeight:'bold', fontSize:'14px'}}>Detailsuche</div>
     </div>

  );
}


  


export default SidePanel;
