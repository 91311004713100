import React,{useEffect, useState, Component} from 'react';
import Magpic from '../../Images/img/magpic.png'
import Magpic1 from '../../Images/img/magpic1.png'
import Magpic2 from '../../Images/img/magpic2.png'
import Magpic3 from '../../Images/img/magpic3.png'
import '../BodyStyle/styles.scss'

function Magazin() {


  useEffect(()=>{

  
  
},[])




  return (
    <div className="MobileMagazin">
  <div className='MagazinTitle'>mobile.de Magazin</div>
 


<div className='Article1'>
    <div className='Article1Block'>
    <img src={Magpic}/>
    </div>
<div className='ArticleText'>
<div className='BlueText'>Elektromobilität</div>
<div className='MagazinHeadText'>Das ändert sich mit einem E-Auto</div>
<div className='MagazinText'>Die Beschleunigung im E-Auto beschreibt unser Autor mit "Gänsehautmoment mit Suchtpotential". Fahren und Tanken gehören zu den Unterschieden. Was ändert sich noch, wenn man von Verbrenner auf E-Auto umsteigt?</div>
</div>
</div>

<div className='Article1'>
    <div className='Subarticle Subfirst'>
      <div className='SubarticleImg'><img src={Magpic1}/></div>
      <div className='SubarticleText'>
      <div className='BlueText Sub'>Oldtimer</div>
<div className='MagazinHeadText Sub'>Diese Modelle sind jetzt Oldtimer</div>
<div className='MagazinText Sub'>Kaum zu glauben: diese Autos werden 2024 zum historischen Kulturgut.</div>
      </div>
    </div>
 
    <div className='Subarticle'>
      <div className='SubarticleImg'><img src={Magpic2}/></div>
      <div className='SubarticleText'>
      <div className='BlueText Sub'>Aus der Redaktion</div>
<div className='MagazinHeadText Sub'>Das ändert sich für Autofahrer 2024</div>
<div className='MagazinText Sub'>Höhere Kraftstoffpreise, keine Kaufprämie für E-Autos und Förderung für Wallboxen. Mehr lesen.</div>
      </div>
    </div>


    <div className='Subarticle'>
      <div className='SubarticleImg'><img src={Magpic3}/></div>
      <div className='SubarticleText'>
      <div className='BlueText Sub'>Elektromobilität</div>
<div className='MagazinHeadText Sub'>Elektro oder Verbrenner?</div>
<div className='MagazinText Sub'>Retro oder Innovation? Alle Duelle mit Helge Thomsen und Peter Fischer ansehen.</div>
      </div>
    </div>

</div>

     </div>

  );
}


  


export default Magazin;
