import React,{useEffect, useState, Component} from 'react';
import Category from './Category';
import Products8x4 from './Products8x4';
import ProductsAdidas from './ProductsAdidas';
import ProductsAxe from './ProductsAxe';
import ProductsSonst from './ProductsSonst';
import ProductsDove from './ProductsDove';
import ProductsFa from './ProductsFa';
import ProductsNivea from './ProductsNivea';
import ProductsNivea2 from './ProductsNivea2';
import ProductsRexona from './ProductsRexona';
import ProductsRexona2 from './ProductsRexona2';
import ProductsNone from './ProductsNone';
import Sortiment from './Sortiment';
import '../../BodyStyle/styles.scss'
import { PropaneSharp } from '@mui/icons-material';
import Dialog from './Dialog'
import Head from '../../Head/Head'


function Body1() {

  let urlParam=document.location.search;
  let searchParams=new URLSearchParams(urlParam)
  let spon=searchParams.get("spon")
  let mod=searchParams.get("mod")
  let ontouch=false
  let ontouchmove=false
  let prods

  useEffect(()=>{

    let hasTouch='ontouchstart' in window

   
    
    function createSponsor(){
      document.getElementById('1').style.display="block"
      document.getElementById('prod1').style.border="2px solid black"
      document.getElementById('2').style.display="block"
      document.getElementById('prod2').style.border="2px solid black"
      document.getElementById('4').style.display="block"
      document.getElementById('prod4').style.border="2px solid black"
      document.getElementById('5').style.display="block"
      document.getElementById('prod5').style.border="2px solid black"
      document.getElementById('6').style.display="block"
      document.getElementById('prod6').style.border="2px solid black"
      document.getElementById('8').style.display="block"  
      document.getElementById('prod8').style.border="2px solid black"
    }

    if(spon==1){
      createSponsor()
    }
    else{

    }

   prods=document.querySelectorAll('.Buy')

if(hasTouch==true){
   window.addEventListener('touchstart', function() {
      
    prods.forEach(item=>{
      item.style.pointerEvents="none"
    item.setAttribute("data-proceed-questionnaire","false")
   
     
  
    })
    
    
  });
}

  /* 
  window.addEventListener('wheel', function() {
    
    prods.forEach(item=>{
      item.style.pointerEvents="all"
    item.setAttribute("data-proceed-questionnaire","true")
     
  
    })
    
  });
  */

  if(hasTouch==false){

  window.addEventListener('mousemove', function() {
    
    prods.forEach(item=>{
      item.style.pointerEvents="all"
    item.setAttribute("data-proceed-questionnaire","true")
     
  
    })

  
});


window.addEventListener('mousedown', function() {
    
  prods.forEach(item=>{
    item.style.pointerEvents="all"
  item.setAttribute("data-proceed-questionnaire","true")
   

  })


});

  }




   if(document.documentElement.clientWidth>480 && hasTouch==true){

    prods.forEach(item=>{
      item.style.pointerEvents="none"
      item.setAttribute("data-proceed-questionnaire","false")
      
  
    })
   }
   else if(document.documentElement.clientWidth>480 && hasTouch==false){
    prods.forEach(item=>{
    //  item.style.pointerEvents="all"
     // item.setAttribute("data-proceed-questionnaire","true")
     
  
    })
   }
  

    window.addEventListener('scroll',function(){
     rejectForward()
      prods.forEach(item=>{
        item.setAttribute("data-proceed-questionnaire","false")
        item.style.pointerEvents="none"

      })
  
    })

    scrollEnd(() => {
      
      prods.forEach(item=>{

        if(document.documentElement.clientWidth>480 && hasTouch==true){
          item.style.pointerEvents="none"
          item.setAttribute("data-proceed-questionnaire","false")
         
        }
        else if(document.documentElement.clientWidth>480 && hasTouch==false){
          //item.style.pointerEvents="all"
         // item.setAttribute("data-proceed-questionnaire","true")
       
        }
        else{
      // item.style.pointerEvents="none"
       // item.setAttribute("data-proceed-questionnaire","false")
        }

       
      })
      
    }, 300);

    
   




  
},[])





function scrollEnd(callback, timeout) {
  let timer = null;
  window.addEventListener('scroll', function() {
    if(timer) {
     
      clearTimeout(timer);
    }

 
    timer = setTimeout(function() {
     
      callback();
      timer = null;
    
      if(ontouch){
        ontouchmove=true
      }
      else{
        ontouchmove=false
      }

    }, timeout);
  });
}



function On(event){
  ontouch=true
  prods.forEach(item=>{
    item.style.pointerEvents="none"
    item.setAttribute("data-proceed-questionnaire","false")
  })

}

function Move(){
  ontouchmove=true
 
  prods.forEach(item=>{
    item.style.pointerEvents="none"
    item.setAttribute("data-proceed-questionnaire","false")
  })
}

function End(event){
 
  if(!ontouchmove){
 
    prods.forEach(item=>{
      item.style.pointerEvents="all"
      item.setAttribute("data-proceed-questionnaire","true")
     
      setTimeout(()=>{
        item.style.pointerEvents="none"
        item.setAttribute("data-proceed-questionnaire","false")
      },500)
    })
   
  }

  ontouchmove=false
  ontouch=false
  
}

function rejectForward(){
  if(ontouch && ontouchmove){
    

  }
}


  return (
    <div className="Main" id="main">
     
   
    
    <Head/>
    <div className="MainBody">
      <Sortiment/>
      <Dialog/>
     <div className='ProductsandCategory' id="prodandcat" >
     <Category/>
     <div className="Products" id="products" onTouchStart={On} onTouchMove={Move} onTouchEnd={End}>
       
      <ProdType type={mod}/>
      
      </div>
      
     </div>


     


</div>
</div>
  );
}

function ProdType(props){

  const type=props.type
 if(type==1){
  return <Products8x4/>
 }
 else if(type==2){
  return <ProductsAdidas/>
 }
 else if(type==3){
  return <ProductsAxe />
 }
 else if(type==4){
  return <ProductsSonst />
 }
 else if(type==5){
  return <ProductsDove />
 }
 else if(type==6){
  return <ProductsFa />
 }
 else if(type==7){
  return <ProductsNivea />
 }

else if(type==8){
 return <ProductsNivea2 />
}

else if(type==9){
 return <ProductsRexona/>
}
 else if(type==10){
  return <ProductsRexona2/>
 }
 else if(type==11){
  return <ProductsNone />
 }
  }
  




export default Body1;
