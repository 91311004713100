import React,{useEffect, useState, Component, useRef} from 'react';
import MainPic from '../assets/smileMainPic.jpg'
import DataText from '../../../data/adcheck_article/text_smile.json'
import Ads from '../../../data/adcheck_article/ads.json'
import { useParams} from "react-router";
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';


//3 Artikel
import img1 from '../assets/smile/1.png'
import img2 from '../assets/smile/2.png'
import img3 from '../assets/smile/3.png'


//icons
import home_icon from '../assets/icons/home.png'
import mail_icon from '../assets/icons/message.png'
import facebook_icon from '../assets/icons/facebook.png'
import twitter_icon from '../assets/icons/twitter.png'
import whatsapp_icon from '../assets/icons/whatsapp.png'


function BodySmile() {

  let urlParam=document.location.search;
  let searchParams=new URLSearchParams(urlParam)
  let adtype=searchParams.get("t")
  let adpath=searchParams.get("path")

  let k=searchParams.get("kw")

  const {adt}= useParams()
  const [click,setClick]=useState(adtype==='true')


  
  function getDate(){
    const date=new Date()
    const daysOfWeek = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth()+1).padStart(2, '0')
    const year = date.getFullYear()
    const week_day=daysOfWeek[date.getDay()]

    const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${week_day}, ${day}.${month}.${year} | ${hours-1}:${minutes}`
   }

  useEffect(()=>{

    //setClick(adtype)
    
  
},[])




  return (
    <>
  <div className="AdcheckBody">

   <div className='adcheck-main-container'>

   <div className='adcheck-breadcrumb-container'>
<div className='adcheck-breadcrumb_img-container'><img src={home_icon}/></div>
<div className='adcheck-breadcrumb_text-container'>{'> Ratgeber > Sieben Tipps für die richtige Zahnpflege'}</div>
</div>

    <div className='adcheck-container-headText'>
      <div className='adcheck-headText-category'></div>
      <div className='adcheck-headText-category'>Gesundheit</div>
<div className='adcheck-headText-header'>Sieben Tipps für die richtige Zahnpflege</div>
<div className='adcheck-headText-header_date'>{getDate()}</div>
    </div>

   
    <div className='adcheck-container-mainPic'>
     <img src={MainPic}/>
    </div>
  
    <div className='adcheck-container-textcontainer'>
    <div className='adcheck-textcontainer-text'>
    <Text/>
    </div>

    <div className='adcheck-social-links'>
    <div className='adcheck-social-links-icon-container'><img src={mail_icon}/></div>
    <div className='adcheck-social-links-icon-container'><img src={facebook_icon}/></div>
    <div className='adcheck-social-links-icon-container'><img id="adcheck-social-links-icon_twitter" src={twitter_icon}/></div>
    <div className='adcheck-social-links-icon-container'><img src={whatsapp_icon}/></div>
    </div>

    </div>


    <div className='adcheck-main_3artikel'>
      <div className='adcheck-main_3artikel_headText'>Das könnte dich auch interessieren</div>
      <div className='adcheck-main_3artikel-container'>
        <div className='adcheck-main_3artikel_img-container'>
    <img src={img1}/>
        </div>
        <div className='adcheck-main_3artikel_text-container'>
          <div className='adcheck-main_3artikel_text-head'>Schmerzempfindliche Zähne</div>
          <div className='adcheck-main_3artikel_text'>Symptome einer Zahnsensibilität: Was Sie wissen sollten</div>
        </div>
      </div>
      <div className='adcheck-main_3artikel-container'>
        <div className='adcheck-main_3artikel_img-container'>
    <img src={img2}/>
        </div>
        <div className='adcheck-main_3artikel_text-container'>
          <div className='adcheck-main_3artikel_text-head'>Alarm im Mundraum</div>
          <div className='adcheck-main_3artikel_text'>Typische Anzeichen einer Zahnbettentzündung</div>
        </div>
      </div>
      <div className='adcheck-main_3artikel-container'>
        <div className='adcheck-main_3artikel_img-container'>
    <img src={img3}/>
        </div>
        <div className='adcheck-main_3artikel_text-container'>
          <div className='adcheck-main_3artikel_text-head'>Schmerzfreie Lächeln</div>
          <div className='adcheck-main_3artikel_text'>Zahnwurzelreizung Alles, was Sie über Gingivitis wissen müssen</div>
        </div>
      </div>
    </div>


    </div>  
   
  
</div>
<Footer/>
</>
  );


function Text(){
  return DataText.content.map((item,index)=>{
    if(index==3){
      return (
        <div key={index}>
        <div className='adcheck-textcontainer-ad' id={"nativecontainer" + Ads.ads[adt].name}>
          <div className={"ad_klicks nativead"+Ads.ads[adt].type} data-click-tracking={Ads.ads[adt].data}>
            <div className={"nativeadLabel"+Ads.ads[adt].type}>Anzeige</div>
      {click ? <Link to={adpath}><img id={"nativead"+Ads.ads[adt].name} src={Ads.ads[adt].path}/></Link> : <img id="nonklickadsmile" src={Ads.ads[adt].path}/>} 

      <div id={"adcheck-adTextContainer"+Ads.ads[adt].name}>
        <div className='adcheck-adTextContainer-Header'>
        Bessere Flirtchancen mit unserer High-Tech Zahnbürste
        </div>
        <div className='adcheck-adTextContainer-Text'>
        Ein strahlendes Lächeln ist der Schlüssel zum Herzen! Die elektrische Zahnbürste von SmileCraft schenkt dir nicht nur glänzend weiße Zähne, sondern verhilft dir zugleich zu noch verführerischeren Unterhaltungen!
        </div>

      </div>
      </div>
        </div>

        <div className='adcheck-textcontainer-textitem' key={index+10}>
        <div className='adcheck-textitem-head'>{item.head}</div>
        <div className='adcheck-textitem-text'>
       
        {item.text.split('\n').map((line,index)=>{
        
       return(
        <p key={index}>{line}</p>
       )
        })}
          
        </div> 
     
      </div>

        </div>
      )
    }


    if(index==5){
      return (
        <div key={index}>
        <div className='adcheck-textcontainer-ad-konkurenz' id={"nativecontainer" + Ads.konkurenz[k].name}>
          <div className="ad_klicks konkurenzAd" data-click-tracking={Ads.konkurenz[k].data}>
           {/*  <div className={'konkurenzLabel'+Ads.konkurenz[k].name}>Anzeige</div>*/}
              <img src={Ads.konkurenz[k].path}/>

      </div>
        </div>

        <div className='adcheck-textcontainer-textitem' key={index+10}>
        <div className='adcheck-textitem-head'>{item.head}</div>
        <div className='adcheck-textitem-text'>
       
        {item.text.split('\n').map((line,index)=>{
        
       return(
        <p key={index}>{line}</p>
       )
        })}
          
        </div> 
     
      </div>

        </div>
      )
    }

    return (

      <div className='adcheck-textcontainer-textitem' key={index}>
        <div className='adcheck-textitem-head'>{item.head}</div>
        <div className='adcheck-textitem-text'>
       
        {item.text.split('\n').map((line,index)=>{
        
       return(
        <p key={index}>{line}</p>
       )
        })}
          
        </div> 
     
      </div>
    )
  })
}

}


  




export default BodySmile;
