import {React, useEffect, useState, Component} from "react";
import './styles.scss'
import home_icon from '../Components/Content/assets/icons/home.png'
import group_icon from '../Components/Content/assets/icons/group.svg'
import inbox_icon from '../Components/Content/assets/icons/inbox.svg'
import user_icon from '../Components/Content/assets/icons/user.svg'


function TikTokFooter(){


    return(
        <div className="tiktok-footer">
        <div className="tik-tok-footer-container">
        <div className="tik-tok-footer_icon-container"><img  src={home_icon}/><div id="tik-tok-footer_icon-home">Home</div></div>
        <div className="tik-tok-footer_icon-container"><img src={group_icon}/><div>Freund*innen</div></div>
        <div className="tik-tok-footer_icon-container"><div className="tik-tok-footer_add-icon"><div id="tik-tok-footer_plus-icon">+</div></div></div>
        <div className="tik-tok-footer_icon-container"><img src={inbox_icon}/><div>Posteingang</div></div>
        <div className="tik-tok-footer_icon-container"><img src={user_icon}/><div>Profil</div></div>
        </div>
      

        </div>
    )
}

export default TikTokFooter;