import React,{useEffect, useState, Component} from 'react';

import '../BodyStyle/styles.scss'

function Marken() {


  useEffect(()=>{

  
  
},[])




  return (
    <div className="MobileMarken">
  <div className='MagazinTitle Block'>Beliebte Automarken und mehr auf unserem Fahrzeugmarkt</div>

<div className='MarkLists'>

<div className='MarkeBlock'>
  <div className='MarkeHead'>Audi</div>
  <ul>
    <li>Audi A1</li>
    <li>Audi A3</li>
    <li>Audi A4</li>
    <li>Audi A6</li>
    <li>Audi Q5</li>
  </ul>
  
</div>


<div className='MarkeBlock'>
  <div className='MarkeHead'>BMW</div>
  <ul>
    <li>BMW 1er</li>
    <li>BMW 2er</li>
    <li>BMW 3er</li>
    <li>BMW 5er</li>
    <li>BMW X1</li>
  </ul>
  
    
    
</div>


<div className='MarkeBlock'>
  <div className='MarkeHead'>Ford</div>
  <ul>
    <li>Ford Ecosport</li>
    <li>Ford Fiesta</li>
    <li>Ford Focus</li>
    <li>Ford Ka</li>
    <li>Ford Mondeo</li>
  </ul>
  
    
</div>


<div className='MarkeBlock'>
  <div className='MarkeHead'>Mercedes-Benz</div>
  <ul>
    <li>Mercedes B-Klasse</li>
    <li>Mercedes C-Klasse</li>
    <li>Mercedes CLA</li>
    <li>Mercedes E-Klasse</li>
    <li>Mercedes GLA</li>
  </ul>
  
</div>

    

<div className='MarkeBlock'>
  <div className='MarkeHead'>Opel</div>
  <ul>
    <li>Opel Adam</li>
    <li>Opel Astra</li>
    <li>Opel Corsa</li>
    <li>Opel Insignia</li>
    <li>Opel Mokka</li>
  </ul>
  
</div>



<div className='MarkeBlock'>
  <div className='MarkeHead'>VW</div>
  <ul>
    <li>VW Golf</li>
    <li>VW Polo</li>
    <li>VW Passat</li>
    <li>VW Touran</li>
    <li>VW up!</li>
  </ul>
  
</div>



<div className='MarkeBlock'>
  <div className='MarkeHead'>Weitere Automarken</div>
  <ul>
    <li>Citroen</li>
    <li>Fiat</li>
    <li>Peugeot</li>
    <li>Renault</li>
    <li>Skoda</li>
  </ul>
  
</div>




<div className='MarkeBlock'>
  <div className='MarkeHead'>Automarken aus Asien</div>
  <ul>
    <li>Hyundai</li>
    <li>Mazda</li>
    <li>Mitsubishi</li>
    <li>Nissan</li>
    <li>Toyota</li>
  </ul>
  
</div>



<div className='MarkeBlock'>
  <div className='MarkeHead'>Autohäuser in der Nähe</div>
  <ul>
    <li>Autohäuser in Berlin</li>
    <li>Autohäuser in Frankfurt</li>
    <li>Autohäuser in Köln</li>
    <li>Autohäuser in Hamburg</li>
    <li>Autohäuser in München</li>
  </ul>
  
</div>


<div className='MarkeBlock'>
  <div className='MarkeHead'>Beliebt</div>
  <ul>
    <li>Auto online kaufen</li>
    <li>Kaufvertrag für's Auto</li>
    <li>Gebrauchtwagen Leasing</li>
    <li>Audi Leasing</li>
    <li>E-Auto Leasing</li>
  </ul>
  
</div>

</div>
     </div>

  );
}


  


export default Marken;
