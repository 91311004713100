import {React, useEffect, useState, Component, useRef} from "react";
import './headstyle.scss'
import liveIcon from '../pict/logo_live.svg'
import SearchIcon from '@mui/icons-material/Search';




function HeadTikTok(){

   



    return(
        <div className="tiktok_head"> 

        <div className="tiktok_head-container">
        <div className="tiktok_head-icon_live-container"><img src={liveIcon}/></div>
        <div className="tiktok_head-switch-container">
            <div>Folge ich</div>
            <div>Für dich</div>
        </div>
        <div className="tiktok_head-icon_search-container"><SearchIcon sx={{fontSize:30, color:'white', filter:'drop-shadow(0px 0px 3px black)'}}/></div>
        </div>
       
        </div>
    )
}

export default HeadTikTok;