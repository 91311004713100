import {React, useEffect, useState, Component, useRef} from "react";
import '../styles.scss'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import Dummy from '../assets/dummy.png'
import RecommendIcon from '@mui/icons-material/Recommend';
import Data from '../../../../data/facebook/data.json'

function FaceBookContent(){

   



    return(
        <div className="facebook_content">

        <Content/>
     
        </div>
    )


    function Content(){
        return Data.data.map((item, index)=>{
            return(
                <div className="facebook_content_container" key={index}>

        <div className="facebook_content_head">
        <div className="facebook-content_head-post_img"></div>
        <div className="facebook-content_head-post-name_container">
        <div className="facebook-content_head-post-name">{item.acc_name}</div>
        <div className="facebook-content_head-post-publ">{item.date} <SettingsIcon sx={{fontSize:'13px', marginLeft:'10%'}}/></div>
        </div>
        <div className="facebook-content_head-post-func_container">
        <div className="facebook-content_head-post-more"><MoreHorizIcon sx={{color:'#65686D'}}/></div>
        <div className="facebook-content_head-post-close"><CloseIcon  sx={{color:'#65686D'}}/></div>
        </div>
        </div>

        <div className="facebook_content_text_container">
            <div className="facebook_content_text_vert-line"></div>
            <div className="facebook_content_text">{item.text}</div>
        </div>
        <div className="facebook_content_img">
        <img src={item.post_img_url}/>
        </div>

        <div className="facebook_content_foot_container">
        <div className="facebook_content_foot_info">
            <div className="facebook_content_foot_info_likes"><RecommendIcon sx={{color:'#1963F6', fontSize:'19px'}}/>{item.likes}</div>
            <div className="facebook_content_foot_info_rep-com">
                <div className="facebook_content_foot_info_com">{item.com} Kommentar</div>
                <div className="facebook_content_foot_info_rep">Reposts: {item.rep}</div>
            </div>
        </div>

        <div className="facebook_content_foot_btns">
        <div className="facebook_content_foot_btn" style={{marginLeft:'1%'}}><div className="facebook_content_foot_btn-like"></div><div>Gefällt mir</div></div>
        <div className="facebook_content_foot_btn"><div className="facebook_content_foot_btn-com"></div><div>Kommentieren</div></div>
        <div className="facebook_content_foot_btn"><div className="facebook_content_foot_btn-send"></div><div>Senden</div></div>
        <div className="facebook_content_foot_btn" style={{marginRight:'3%'}}><div className="facebook_content_foot_btn-share"></div><div>Teilen</div></div>
     
        </div>

        </div>

        </div>
            )
        })
    }

}

export default FaceBookContent;