import React,{useEffect, useState, Component} from 'react';
import MainPic from '../assets/Advertorial_SmileCraft.jpg'
import DataText from '../../../data/adcheck_article/text_smile_advr.json'

import adminipic from '../assets/advr_tofu.png'
import Footer from '../Footer/Footer';

function BodyAdvrSmile() {

  let urlParam=document.location.search;
  let searchParams=new URLSearchParams(urlParam)
  //let adtype=searchParams.get("adt")
  //let mod=searchParams.get("mod")
 

  function getDate(){
    const date=new Date()
    const daysOfWeek = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth()+1).padStart(2, '0')
    const year = date.getFullYear()
    const week_day=daysOfWeek[date.getDay()]

    const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${week_day}, ${day}.${month}.${year} | ${hours-1}:${minutes}`
   }

  useEffect(()=>{

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });

  
},[])




  return (
    <>
  <div className="AdcheckBody">

   <div className='adcheck-main-container'>

    <div className='adcheck-container-headText'>
      <div className='adcheck-headText-category advr-head'><span>Anzeige</span>SmileCraft {/*<div className='adcheck-advr-head-logo'><img src=""/></div>*/} </div>
      <div className='adcheck-headText-header'><span></span>Bessere Flirtchancen mit High-Tech Zahnbürste</div>
      <div className='adcheck-headText-header_date'>{getDate()}</div>
    </div>

    <div className='adcheck-container-mainPic'>
      <div className='adcheck-maiPic-adLabel'>Anzeige</div>
      <img src={MainPic}/>
    </div>

    <div className='adcheck-container-textcontainer'>
    <div className='adcheck-textcontainer-text'>
    <Text/>

      <div className='adcheck-list-header'>SmileCraft auf einen Blick:</div>
    <ul className='adcheck-textcontainer-list'>
      <li className='adcheck-list-item'>High-Tech Zahnbürste für sichtbar weißere Zähne</li>
      <li className='adcheck-list-item'>Neu und ab sofort online erhältlich</li>
      <li className='adcheck-list-item'>Von Zahnärzt*innen und Zahntechniker*innen entwickelt</li>
      <li className='adcheck-list-item'>Vereint neueste Technologien und steigert durch personalisierte Putzanalysen die dentale Gesundheit</li>
      
      
    </ul>
    </div>
    </div>


    </div>  
   
  
</div>
<Footer/>
</>
  );


function Text(){
  return DataText.content.map((item,index)=>{
    if(index==3){
      return (

        <div className='adcheck-textcontainer-ad' key={index}>
        
        </div>
      )
    }

    return (

      <div className='adcheck-textcontainer-textitem' key={index}>
        <div className='adcheck-textitem-head'>{item.head}</div>
        <div className='adcheck-textitem-text'>
       
        {item.text.split('\n').map((line,index)=>{
        
       return(
        <p key={index}>{line}</p>
       )
        })}
          
        </div> 
     
      </div>
    )
  })
}

}


  




export default BodyAdvrSmile;
