import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams} from "react-router";
import bgImg from '../../../../assets/ads/hpto/hpto_a5_l/bg.jpg'
import bgCta from '../../../../assets/ads/hpto/hpto_a5_l/cta.png'
import Logo from '../../../../assets/ads/hpto_audi_q/c1.png'
import test from '../../../../assets/ads/hpto_audi_q/c2.png'


function AdBg_L() {


    const {ads}= useParams()
 
  
  

    const text1=useRef(null)
    const text2=useRef(null)
    const text3=useRef(null)
    const text4=useRef(null)
    const cta1=useRef(null)
    const cta2=useRef(null)

    let animationInterval

    let animationSwitch=true

    
    const textAnimation=()=>{
      animationInterval=setInterval(()=>{
        if(animationSwitch){
          text1.current.style.opacity=1
          
          text3.current.style.opacity=0  
          text4.current.style.opacity=0  
          animationSwitch=!animationSwitch
        }
        else{
          text1.current.style.opacity=0
          
          text3.current.style.opacity=1  
          text4.current.style.opacity=1  
          animationSwitch=!animationSwitch
        }
      

      },4000)
    }


  useEffect(()=>{



    setTimeout(() => {
      text1.current.style.opacity=1  
    }, 1000);

    setTimeout(() => {
      text1.current.style.opacity=0

      text3.current.style.opacity=1  
      text4.current.style.opacity=1  
      
      
    }, 4000);

    setTimeout(() => {
      cta1.current.style.opacity=1  
      cta1.current.style.transform="scale(1)"  
      textAnimation()
    }, 4500);
    
    setTimeout(() => {
      cta1.current.style.opacity=0
      cta2.current.style.opacity=1  
      
    }, 7000);
    
  
},[])




  return (
    <>
  <div className="hpto_ad_AudiQ-side-autoscout" >
  <div className='hpto_ad_AudiQ-side-autoscout-div_container'>
  <div className='hpto_ad-AudiQlogo-container'><img src={Logo}/></div>

  

  
  
 
  <div className='hpto_ad-AudiQ_logotext-container'>
  <div className='hpto_ad-AudiQ_text-container' ref={text1} style={{opacity:0}}>Bereit für das nächste Abenteuer</div>
 
  <div className='hpto_ad-AudiQlogo-AudiQ_q463' ref={text3} style={{opacity:0}}><span style={{display:'bock', display:'flex', justifyContent:'center', fontSize:'1vw'}}>Überzeugend,</span> schon vor der ersten Fahrt.</div>
  <div className='hpto_ad-AudiQlogo-AudiQ_q232' ref={text4} style={{opacity:0}}>Der Audi Q3 zu attraktiven Konditionen.</div>

  <div className='hpto_ad-AudiQlogo-AudiQ_cta1' ref={cta1} style={{opacity:0}}>Sofort verfügbar</div>
  <div className='hpto_ad-AudiQlogo-AudiQ_cta1 q_cta-AudiQ_cta2' style={{fontSize:'12px'}} ref={cta2}>Jetzt Angebote sichern</div>
  </div>

    <div className='hpto_ad_AudiQ-side-autoscout_cta' id="hpto_ad-side-autoscout_cta-audi-fireplace" ><img /></div>

    {/*<div className='hpto_ad_AudiQ-side-autoscout_foot-text'><span>Audi</span> Vorsprung durch Technik</div>*/}
    
   
    </div>
  
</div>

</>
  );


 
}


  




export default AdBg_L;
