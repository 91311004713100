import React,{useEffect, useState, Component} from 'react';
import Head from '../Head/Head'
import Block1 from '../../Images/img/block.png'
import Block2 from '../../Images/img/block2.png'
import Block3 from '../../Images/img/block3.png'
import Block4 from '../../Images/img/block4.png'
import Block5 from '../../Images/img/block5.png'
import '../BodyStyle/styles.scss'

function Block() {


  useEffect(()=>{

  
  
},[])




  return (
    <div className="MobileBlock">
      <div className='MagazinTitle Block'>Gebrauchtwagen kaufen, verkaufen, finanzieren & leasen? Oder neues Auto? Mobile.de!</div>

    <div className='BlockArticleLarge'>
      <div className='BlockArticleLargeImg'><img src={Block1}/></div>
      <div className='BlockArticleBlockText'>
        <div id="BlockTextDesc">Hier findest Du alles, was mit Fahrzeugen zu tun hat – verschaff Dir ganz einfach einen Überblick über den gesamten Automarkt. Hier warten täglich über 1,2 Millionen Fahrzeuge auf Dich.</div>
        <div className='BlockHead X21'>mobile.de ist Deutschlands größter Fahrzeugmarkt</div>
        <div className='BlockText'>mobile.de ist Deutschlands größter Fahrzeugmarkt, auf dem Du einfach ein Auto kaufen oder verkaufen kannst. Hier gibt es Gebrauchtwagen, Neuwagen, Youngtimer, Oldtimer, Kleinwagen und Kompakte, SUV, luxuriöse Limousinen und günstige Autos. Du kannst Dein gebrauchtes Auto verkaufen, Neuwagen- und Gebrauchtwagenverkäufer kontaktieren. Du kannst mehr über Automarken und Modelle herausfinden, Dich über die Fahrzeugfinanzierung informieren, Finanzierungsangebote und Monatsraten vergleichen, oder Leasing-Angebote finden. Ebenso findest Du Auto-Tipps, Tests, Ratgeber und vieles mehr.</div>
      </div>
    </div>

    <div className='BlockArticleSmall'>

      <div className='BlockAricle'>
        <div className='BlockArticleSmallImg'><img src={Block2}/></div>
        <div className='BlockArticleSmalltext'>
          <div className='BlockHead X20'>Denkst Du Auto verkaufen?</div>
          <div className='BlockText'>Dann kannst Du Dein gebrauchtes Auto hier kostenlos verkaufen. Einfach und bequem. Zum maximalen Preis per Inserat oder schnell per Expressverkauf an einer mobile.de Ankaufsstation.<span>➤ Auto bewerten</span></div>
        </div>
      </div>

      <div className='BlockAricle'>
        <div className='BlockArticleSmallImg'><img src={Block3}/></div>
        <div className='BlockArticleSmalltext'>
          <div className='BlockHead X20'>Denkst Du Gebrauchtwagen kaufen?</div>
          <div className='BlockText'>Dann kannst Du hier zahlreiche Angebote vergleichen und Verkäufer direkt kontaktieren. Professionelle Autohändler oder Privatverkäufer, Firmenfahrzeuge von gewerblichen Anbietern oder Jahreswagen. Auch mit Gebrauchtwagen-Garantie oder Qualitätssiegel.<span>➤ Gebrauchtwagen kaufen</span></div>
        </div>
      </div>


      <div className='BlockAricle'>
        <div className='BlockArticleSmallImg'><img src={Block4}/></div>
        <div className='BlockArticleSmalltext'>
          <div className='BlockHead X20'>Denkst Du Neuwagen kaufen?</div>
          <div className='BlockText'>Dann findest Du hier eine riesige Auswahl an Neuwagen: ob Autos mit Tageszulassung, Vorführfahrzeuge oder EU-Neuwagen.<span>➤ Neuwagen kaufen</span></div>
        </div>
      </div>


      <div className='BlockAricle'>
        <div className='BlockArticleSmallImg'><img src={Block5}/></div>
        <div className='BlockArticleSmalltext'>
          <div className='BlockHead X20'>Denkst Du Leasing?</div>
          <div className='BlockText'>Ob kaufen oder leasen, bei uns wirst Du fündig. Such Dir deinen Traumwagen und finde passende Leasing-Angebote von unseren Händlern in Deiner Nähe.<span>➤ Leasing-Angebote finden</span></div>
        </div>
      </div>


    </div>


    <div className='BlockEndText '>Was immer Du denkst, mit uns bleibst Du mobil.</div>
    <div className='BlockEndText X19'>Denk nicht nur Auto.</div>
    <div className='BlockEndText' id="blockEndtextlast">Denk mobile.de</div>

   

     </div>

  );
}


  


export default Block;
