import {React, useEffect, useState, Component, useRef, Fragment} from "react";

import { register } from 'swiper/element/bundle';
import '../../styles.scss'

import { Link, useNavigate, redirect } from "react-router-dom";

import TikTokIcon from '../assets/icons/tik-tok.png'
import Data from '../../../../../data/tiktok/ads.json'

// Icons

import FavoriteIcon from '@mui/icons-material/Favorite';
import Comments from '../assets/icons/content_icons/comment.svg'
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplyIcon from '@mui/icons-material/Reply';
import TextsmsIcon from '@mui/icons-material/Textsms';

import dummy from '../assets/icons/noimage.png'

// Loader

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';



register();
function TopViewAd({videoRef, headRef, footRef, btnsRef, videoID, skipAdClick}){

  const navigate=useNavigate()

    const timeLine=useRef(0)
 
  const topViewAd=useRef(null)


   
    function contentTimeUpdate(item){
       let time=0;
       let videoDuration=0
     
       const videoTime=timeLine.current
       
       
       item.addEventListener('timeupdate', function(){
       
         videoDuration=Math.round(item.duration)
         time=Math.round(item.currentTime)
       
      
         videoTime.style.width=(time*100)/videoDuration + "%"
         if(time>=videoDuration){
           item.currentTime=0
           videoTime.style.width="0%"
           //item.play()
          
         }
        })
    }

    const skipAd=()=>{

      skipAdClick()
    }


     useEffect(()=>{
      
        const swiperEl = document.querySelector('swiper-container');
        document.body.style.overflow = 'hidden';

        swiperEl.swiper.on('reachEnd',()=>{
          swiperEl.swiper.allowSlideNext=false
        })
    
        swiperEl.swiper.on('fromEdge', () => {
         
          swiperEl.swiper.allowSlideNext = true; 
        });

        
           
     },[])

    const launch=()=>{
      
        document.getElementById("tiktok-playContent-Dialog").style.display="none"
      document.body.style.overflow = 'auto';
      
    }

    return(
      <>
        <div className="tiktok-top_view">
        <div className="tiktok-top_view-contaniner">
        <div className="tiktok-top_view-head" ref={headRef}>
            <div className="tiktok-top_view-icon_container"><div className="tiktok-top_view-icon"><img src={TikTokIcon}/></div><div className="tiktok-top_view-logo_name">TikTok</div></div>
            <div className="tiktok-top_view_skip-btn" onClick={skipAd} onTouchEnd={skipAd}>Skip ad</div>
        </div>
        <div className="tiktok-top_view_video-container">
          <video className="tiktok_contentVideo" ref={videoRef} src={Data.ads[videoID].url} playsInline/>
        </div>
        <div className="tiktok_contentVideo_btns-container" ref={btnsRef}>
            <div className="tiktok_contentVideo_btn"><div className="tiktok_contentVideo_circle"><img src={Data.ads[videoID].icons}/><div className="tiktok_contentVideo_circle-plus">+</div></div></div>
            <div className="tiktok_contentVideo_btn"><FavoriteIcon sx={{fontSize:30, color:'white', opacity:1}}/><div>{Data.ads[0].likes}</div></div>
            <div className="tiktok_contentVideo_btn"><TextsmsIcon sx={{fontSize:30, color:'white', opacity:1}}/>{Data.ads[0].com}</div>
            <div className="tiktok_contentVideo_btn"><BookmarkIcon sx={{fontSize:30, color:'white', opacity:1}}/>{Data.ads[0].book}</div>
            <div className="tiktok_contentVideo_btn"><ReplyIcon sx={{fontSize:30, color:'white', opacity:1, transform:'scaleX(-1)'}}/>{Data.ads[0].rep}</div>
            <div className="tiktok_contentVideo_btn"><div className="tiktok_contentVideo_circle" id="tiktok_contentVideo_circle_spin"><img src={Data.ads[videoID].icons}/></div></div>

            </div>
        <div className="tiktok-top_view_footer" ref={footRef}>
          <div className="tiktok-top_view_footer-text">Swipen um zu überspringen</div>
        </div>
        </div>
        
          
        </div>
       
        </>
    )


}

export default TopViewAd;
