import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams} from "react-router";
import AdTop from './AdTop';
import AdSide_L from './AdSide_L';
import AdSide_R from './AdSide_R';
import Items from '../subpage/Items';
import Filter from '../subpage/Filter';



function AdFireplace() {



 
  
  



  useEffect(()=>{


    
  
},[])




  return (
    <div className='subpage-autoscout_ad_content-container'>
    <AdSide_L/>
    <div className="subpage-autoscout_content-container">
    <AdTop/>
    <div className='subpage-autoscout_container'>
     <div className='subpage-autoscout_filter_btn_container'> 
      <div className='subpage-autoscout_filter-btn_container'><div className='subpage-autoscout_filter-btn'>Suche speichern</div></div>
    <Filter/>
    </div>
  
    <div>
    <Items/>
    </div>
    </div>
    </div>
  
    
     <AdSide_R/>
      </div>
  );


 
}


  




export default AdFireplace;
