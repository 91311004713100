import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams} from "react-router";
import bgImg from '../../../../assets/ads/hpto_audi_etron/2210_Audi_Q6_E-tron_3067501_300x600/img.jpg'
import bgCta from '../../../../assets/ads/hpto/hpto_a5_l/cta.png'
import bgLogo from '../../../../assets/ads/hpto/hpto_a5_l/legal.png'


function AdHptoEtron_L() {


    const {ads}= useParams()
 
  
  
    const textblock=useRef(null)
    const imgg=useRef(null)
    const ctaBtn=useRef(null)

    const text1=useRef(null)
    const text2=useRef(null)

   
    const imgContainer=useRef(null)

 

    let animationSwitch=true



    const textAnimation = () => {
      const animateOpacity = () => {
        if (animationSwitch) {
         
          text1.current.style.opacity = 0;
          text2.current.style.opacity = 0;
    
          setTimeout(() => {
            animationSwitch = !animationSwitch;
            animateOpacity(); 
          }, 1500);
          
        } else {
   
          text1.current.style.opacity = 1;

          setTimeout(() => {
            text2.current.style.opacity = 1;
          }, 3000);
         
    
          setTimeout(() => {
            animationSwitch = !animationSwitch;
            animateOpacity();
          }, 7000);
        }
      }
      animateOpacity()
    }


  useEffect(()=>{


    setTimeout(() => {
      textblock.current.style.opacity=1  
      text1.current.style.opacity=1  
    }, 1000);

    setTimeout(() => {
      ctaBtn.current.style.opacity=1  
      ctaBtn.current.style.transform="scale(1)"  
    }, 1500);

    
    setTimeout(() => {
      
      text2.current.style.opacity=1 
     
      ctaBtn.current.style.transform="scale(1)"  

    
    }, 4000);

    setTimeout(() => {
      textAnimation()
    }, 7000);
    
  
},[])




  return (
    <>
  <div className="hpto_ad_a5-side-autoscout" ref={imgContainer} style={{display:'flex', justifyContent:'end'}}>
  <div>
    <div className='hpto_ad_a5-side-autoscout-logo-container'><img src={bgLogo}/> </div>
    
   
    
  </div>
  <div className='hpto_ad_etron-side-autoscout_img-container'><img ref={imgg} src={bgImg}/></div>

  <div className='hpto_ad_etron_l_hpto-side-autoscout_text_btn-container' id="hpto_ad-side-autoscout_fireplace-text_btn-container" ref={textblock}>
    <div className='hpto_ad_etron-side-autoscout_text' id="pto_ad-side-autoscout_text-fireplace-left" ref={text1} style={{opacity:0}}>Die eigenen Erwartungen überholen.</div>
    <div className='hpto_ad_etron-side-autoscout_text2' ref={text2} style={{opacity:0}}>Der neue, rein elektrische Audi Q6 e-tron.</div>

    <div className='hpto_ad_a5-side-autoscout_cta' id="hpto_ad-side-autoscout_cta-audi-fireplace" ref={ctaBtn}><img src={bgCta}/></div>

    <div className='hpto_ad_a5-side-autoscout_foot-text'><span>Audi </span> Vorsprung durch Technik</div>
    
    </div>
  
</div>

</>
  );


 
}


  




export default AdHptoEtron_L;
