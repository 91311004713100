import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams} from "react-router";
import BgImg from '../../../../assets/ads/hpto_audi_q/41057574_audi_ae_sprint_2_q_offensive_pageskin_bg_rev1.jpg'

import Logo from '../../../../assets/ads/hpto_audi_q/c1.png'

import texthead1 from '../../../../assets/ads/hpto_audi_q/c2.png'


function AdBg() {


    const {ads}= useParams()
 
  
  



  useEffect(()=>{


    
  
},[])




  return (
    <>
  <div className="hpto_ad-AudiQ">
   
   
    <div className='hpto_ad-AudiQ_img-container'>
      <div><img id="hpto_ad-AudiQ_adBg" src={BgImg}/>
    
      </div>
     
     

    </div>
    
    
   
  
</div>

</>
  );


 
}


  




export default AdBg;
