import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams} from "react-router";
import bgImg from '../../../../assets/ads/top/bg1.jpg'
import bgImg2 from '../../../../assets/ads/top/bg2.jpg'
import bgImg3 from '../../../../assets/ads/top/bg3.jpg'
import Logo from '../../../../assets/ads/top/c1.png'
import img1texthead from '../../../../assets/ads/top/c2.png'
import img1text1 from '../../../../assets/ads/top/c3.png'


import img1text2 from '../../../../assets/ads/top/c4.png'


import img1text3 from '../../../../assets/ads/top/c5.png'

import cta from '../../../../assets/ads/top/cta_text.png'
import { Opacity } from '@mui/icons-material';




function AdTopAd() {


    const {ads}= useParams()

    const  adTopad_img1= useState()
    const adTopad_img2= useState()
    const adTopad_img3= useState()
    const adTopad_cta= useState()

    const adTopad_textt1= useState()
    const adTopad_textt2= useState()
 
  
  const AnimStart=()=>{
    setTimeout(() => {
      adTopad_textt1.current.style.opacity=1
      adTopad_textt2.current.style.opacity=1
      adTopad_cta.current.style.opacity=1
      adTopad_cta.current.style.transform='scale(1)'
    
    }, 2500);

    setTimeout(() => {
      adTopad_img1.current.style.opacity=0
      adTopad_img2.current.style.opacity=1
    }, 6500);

    setTimeout(() => {
      adTopad_img2.current.style.opacity=0
      adTopad_img3.current.style.opacity=1
    }, 13000);
  }



  useEffect(()=>{


    AnimStart()
  
},[])




  return (
    <>
  <div className="hpto_ad-top-autoscout">
    <div className='hpto_ad-top-autoscout_img-container_adTopad'>
     <div className='hpto_ad-top-autoscout_logo-container_adTopad'><img src={Logo}/></div> 
      <div className='hpto_ad-top-autoscout_img-adTopad' ref={adTopad_img1}><img src={bgImg}/>
      <div className='hpto_ad-top-autoscout_img-adTopad_img1_text' style={{opacity:0}} ref={adTopad_textt1} ><img src={img1texthead}/></div>
      <div className='hpto_ad-top-autoscout_img-adTopad_img1_text'style={{opacity:0}} ref={adTopad_textt2}><img src={img1text1}/></div>
      </div>

      <div className='hpto_ad-top-autoscout_img-adTopad' style={{opacity:0}} ref={adTopad_img2}><img src={bgImg2}/>
      <div className='hpto_ad-top-autoscout_img-adTopad_img1_text'><img src={img1text2}/></div>
  
      </div>

      <div className='hpto_ad-top-autoscout_img-adTopad' style={{opacity:0}} ref={adTopad_img3}><img src={bgImg3}/>
      <div className='hpto_ad-top-autoscout_img-adTopad_img1_text'><img src={img1text3}/></div>
   
      </div>

      <div className='hpto_ad-top-autoscout-adTopad-cta' ref={adTopad_cta}>Mehr erfahren</div>
      {/* 
      <div className='hpto_ad-top-autoscout_img-adTopad' ref={adTopad_img2}><img src={bgImg2}/></div>
      <div className='hpto_ad-top-autoscout_img-adTopad' ref={adTopad_img3}><img src={bgImg3}/></div>*/}
      
    </div>
    
    
   
  
</div>

</>
  );


 
}


  




export default AdTopAd;
