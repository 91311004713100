import React,{useEffect, useState, Component} from 'react';
import data from '../../../../data/sonst.json'
import './BodyStyle/styles.scss';
import Filter from '../../../../Images/img/filter.png'
import Percent from '../../../../Images/img/percent.svg'
import Bio from '../../../../Images/img/bio.svg'
import Vegan from '../../../../Images/img/vegan.svg'
import Neu from '../../../../Images/img/neu.svg'


function Sortiment() {

  let amount=data.products.length



  return (

<div>

<div className="Sortiment">
   
<div className="SortimentName">
       
       <div className="BackText">◄ Zurück <span>Drogerie & Kosmetik</span></div>
       <div id="headtext" style={{fontSize:'1em', fontWeight:'bold'}}>Drogerie & Kosmetik</div>

       
      
   </div>

 

   <div className="SortimentPage">
       <div className="ProductCount">Artikel pro Seite<div id="count">{amount}</div>Sortieren<div id="sort">Relevanz</div></div>
   </div>


</div>

<div className='DivElement'>

<div  className='Element'>
  <div style={{fontSize:13, color:'#d1d1d1', marginLeft:5}}>Kategorie</div>
  <div id="element1">Drogerie & Kosmetik</div>
</div>

<div id="filter" className='Element'>
<div style={{marginLeft:5}}>Filter</div>
<img id="filterImg" src={Filter}/>
</div>

</div>

<div className='ScrollBtns'>

<div className='Btn'><img src={Percent}/><div>Angebote</div> </div>
<div id="btnbio" className='Btn'><img src={Bio}/><div>Bio</div></div>
<div className='Btn'><img src={Vegan}/><div>Vegan</div></div>
<div id="btnneu" className='Btn'><img src={Neu}/><div>Neu</div></div>

</div>

</div>


  );
}

export default Sortiment;
