import React,{useEffect, useState, Component, useRef} from 'react';
import '../../styles/style.scss'
import { register } from 'swiper/element/bundle';
import img1 from '../../assets/body/2/1.webp'
import img2 from '../../assets/body/2/2.webp'
import img3 from '../../assets/body/2/3.webp'
import img4 from '../../assets/body/2/4.webp'
import img5 from '../../assets/body/2/5.webp'
import img6 from '../../assets/body/2/6.webp'



register();


function WhiteBlock() {



useEffect(()=>{

  
},[])




  return (

<>
<div className='autoscout-white_block'>
<div className='autoscout-white_block-text_head'>Finde dein perfektes Auto mit AutoScout24, dem europaweit größten Online-Automarkt.</div>

<div className='autoscout-white_block-container'>
   <div className='autoscout-white_block-content_section'>
      <div className='autoscout-white_block-img_container'><img src={img1}/></div>
      <div className='autoscout-white_block-text_container'><div className='autoscout-white_block-text_container-head'>Bist du auf Gebrauchtwagen-Suche?</div><div className='autoscout-white_block-text_container-text'>AutoScout24 ist der perfekte Online-Marktplatz, um deine Fahrzeugsuche zu starten. Finde unter Hunderttausenden von Angeboten den richtigen Neu- oder Gebrauchtwagen für dich. Ganz gleich, ob du einen sparsamen Kleinwagen, ein großes Familien-SUV oder ein sportliches Coupé suchst – bei AutoScout24 wirst du fündig. Starte jetzt deine Neu- oder Gebrauchtwagensuche bei AutoScout24</div></div>
   </div>
   <div className='autoscout-white_block-content_section white_block-content_section_img-right'>
      <div className='autoscout-white_block-img_container'><img src={img2}/></div>
      <div className='autoscout-white_block-text_container'><div className='autoscout-white_block-text_container-head'>Du willst dein Auto verkaufen?</div><div className='autoscout-white_block-text_container-text'>AutoScout24 ist Europas größter Online-Automarktplatz und hilft dir dabei, den besten Preis für dein Auto zu erzielen. Mit Hilfe des AutoScout24-Marktplatzes erreichst du Millionen von potenziellen Interessenten oder erzielst über den Direkt-Verkauf einen Bestpreis über einen seriösen Händler in deiner Nähe. Mit über 40 Millionen Nutzern monatlich findest du sicher schnell und einfach einen Käufer für dein Auto. Stelle jetzt dein Fahrzeug bei AutoScout24 ein.</div></div>
   </div>
   <div className='autoscout-white_block-content_section '>
      <div className='autoscout-white_block-img_container'><img src={img3}/></div>
      <div className='autoscout-white_block-text_container'><div className='autoscout-white_block-text_container-head'>Auto-Leasing gefällig?</div><div className='autoscout-white_block-text_container-text'>AutoScout24 ist die perfekte Plattform, um ein Auto zu leasen. Wir arbeiten mit führenden Leasinggesellschaften zusammen, um dir die besten Angebote für die neuesten Modelle zu machen. Egal ob du ein Familien-SUV oder ein Elektroauto suchst, wir helfen dir dabei, das perfekte Leasingangebot zu finden. Lease jetzt dein Fahrzeug bei AutoScout24.</div></div>
   </div>
   <div className='autoscout-white_block-content_section white_block-content_section_img-right'>
      <div className='autoscout-white_block-img_container'><img src={img4}/></div>
      <div className='autoscout-white_block-text_container'><div className='autoscout-white_block-text_container-head'>Du willst ein Auto-Abo abschließen?</div><div className='autoscout-white_block-text_container-text'>Ein Auto-Abo ist einfach und bequem für alle, die Autos lieben, aber kein eigenes Auto besitzen möchten. Mit dem AutoScout24 Auto-Abo wählst du aus einer großen Auswahl an Angeboten und bleibst durch kurze Vertragslaufzeiten dauerhaft flexibel. Während du das Fahren genießt, kümmern sich unsere ausgewählten Partner um Wartung, Verschleiß, Versicherung und Kfz-Steuer. Du musst nur noch tanken. Abonniere jetzt dein neues Auto bei AutoScout24.</div></div>
   </div>
   <div className='autoscout-white_block-content_section '>
      <div className='autoscout-white_block-img_container'><img src={img5}/></div>
      <div className='autoscout-white_block-text_container'><div className='autoscout-white_block-text_container-head'>Du bist Fan von Online-Autokauf?</div><div className='autoscout-white_block-text_container-text'>Stöbere online durch ein riesiges Fahrzeugangebot und lass dir dein Traumauto nach Hause liefern. Mit AutoScout24 smyle findest du ein hochwertiges, technisch geprüftes Auto zum fairen Preis. Wir arbeiten mit seriösen Autohändlern in ganz Deutschland zusammen und bieten dir die passende Finanzierung, ein 14-tägiges Rückgaberecht und optional erweiterte Garantieleistungen. Im Handumdrehen gelangst du so zu deinem perfekten Neu- oder Gebrauchtwagen. Starte jetzt deine Suche nach deinem Traumauto mit AutoScout24 smyle.</div></div>
   </div>
   <div className='autoscout-white_block-content_section white_block-content_section_img-right'>
      <div className='autoscout-white_block-img_container'><img src={img6}/></div>
      <div className='autoscout-white_block-text_container'><div className='autoscout-white_block-text_container-head'>Du suchst Informationen zur Entscheidungsfindung beim Autokauf?</div><div className='autoscout-white_block-text_container-text'>Ein Auto zu kaufen, ist eine große Entscheidung und gleichzeitig Vertrauenssache. Die Wahl des richtigen Automodells, der passenden Finanzierung und günstigsten Versicherung spielen eine wichtige Rolle. Unser Ratgeber hilft dir, dein passendes Wunschauto zu finden. Informiere dich jetzt im AutoScout24 Magazin - die erste Anlaufstelle und zuverlässig, wenn es um Informationen rund um den Autokauf- und verkauf geht. Dort findest du neben aktuellen Auto-Testberichten auch eine breite Palette an Ratgeber-Themen: Von Technik-Tipps und Zubehörlisten bis hin zu Pflege-Empfehlungen und Auto-Vergleichen. AutoScout24 unterstützt dich von A bis Z zum Thema Auto.</div></div>
   </div>
  

</div>

</div>


</>

  );


}



export default WhiteBlock;
