import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams} from "react-router";
//import AdTop from './AdTop';
import Items from '../../subpage/Items';
import Filter from '../../subpage/Filter';
import AdBg from './AdBg'
import AdBg_L from './AdBg_L';
import AdBg_R from './AdBg_R';


function AdHptoQ() {



 
  
  



  useEffect(()=>{


    
  
},[])




  return (
    
    
    <div className='subpage-autoscout_ad_content-container_adbg'>
     
     <AdBg_L/>
    <div className="subpage-autoscout_content-container_adbg">
    <AdBg/>
    <div className='subpage-autoscout_container'>
     <div className='subpage-autoscout_filter_btn_container'> 
      <div className='subpage-autoscout_filter-btn_container'><div className='subpage-autoscout_filter-btn'>Suche speichern</div></div>
    <Filter/>
    </div>
  
    <div>
    <Items/>
    </div>
    </div>
    </div>
  
    
    <AdBg_R/>
      </div>
    
  );


 
}


  




export default AdHptoQ;
