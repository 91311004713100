import React, { useEffect, useState, Component } from 'react';
import '../BodyStyle/style.scss';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Loading() {

 
 
  useEffect(()=>{

  },[])


 return(

<div className='context-loadingScreen'>
<Box sx={{ display: 'flex', justifyContent:'center', height:'100vh', alignItems:'center'}}>
      <CircularProgress sx={{color:'blue'}} size={100}/>
      <CircularProgress sx={{color:'blue', position:'absolute', animationDuration: '2000ms'}} size={50} thickness={10}/>
      <CircularProgress sx={{color:'black', position:'absolute', animationDuration: '500ms'}} size={20}/>
      
    </Box>

</div>
     
    
    )
  
  
}

export default Loading