import React,{useEffect, useState, Component} from 'react';
import './styles.scss'
import { useParams } from "react-router";
import Pb from './Images/pb.png'
import Worker from './Images/1_worker.png'
import Veg from './Images/2_veg.png'
import Box from './Images/2_pack.png'



function Container3() {




useEffect(()=>{

  
},[])




  return (
    <div className='reweka-thrirdContainer'>

    <div className='reweka-container-RKJZS'>
      <div className='reweka-thrirdContainer-text'>Schau dich auch hier um</div>
      <div className='reweka-thrirdContainer-icons'>
        <div className='reweka-thrirdContainer-icon'><img src="https://eu-images.contentstack.com/v3/assets/blt76860e5619b27a3a/bltc07b3363cce78c4d/6011600bb2ffda0ce5472914/colored-icons-icon-present-48.svg"/><span>Aktionen & Gewinnspiele</span></div>
        <div className='reweka-thrirdContainer-icon'><img src="https://eu-images.contentstack.com/v3/assets/blt76860e5619b27a3a/blt2a8b5c843476eabe/6011601bb2ffda0ce5472916/colored-icons-icon-rewe-deine-kueche-48.svg"/><span>Rezepte & Ernährung</span></div>
        <div className='reweka-thrirdContainer-icon'><img src="https://eu-images.contentstack.com/v3/assets/blt76860e5619b27a3a/blt055bc2ec6fd3fbee/6011601328e9280c8a0a6e92/colored-icons-icon-percentage-48.svg"/><span>Gutscheine & Coupons</span></div>
      </div>
      
      </div>
    </div>
  );
}

  




export default Container3;
